import React, { useState } from "react";
import { clsx } from "clsx";
import { GearSix, SignOut } from "@phosphor-icons/react";
import { Link } from "react-router-dom";

import useCustomer from "common/datahooks/useCustomer";
import Avatar from "common/components/ui/Avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";

import LogOutModal from "../../LogOutModal";

export default function ProfileButton({ isExpanded }: { isExpanded: boolean }) {
  const [isLogOutModalOpen, setIsLogOutModalOpen] = useState(false);

  const { customerInfo } = useCustomer();
  const { first_name: firstName, last_name: lastName, email } = customerInfo;

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger
          className={clsx(
            "mt-auto flex items-center justify-start gap-x-3 rounded-2xl transition-[border,padding] duration-200",
            isExpanded && "border border-black-700 px-3 py-2.5",
          )}
          data-cy="profile-button"
        >
          <Avatar>
            {firstName[0]}
            {lastName[0]}
          </Avatar>
          <div
            className={clsx(
              "flex flex-col items-start gap-y-1 overflow-hidden transition-opacity duration-300",
              isExpanded ? "opacity-100" : "opacity-0",
            )}
          >
            <span className="w-full truncate text-left text-button-14 text-white">
              {firstName} {lastName}
            </span>
            <span className="w-full truncate text-caption-10-semibold text-black-500">
              {email}
            </span>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="z-tooltip" side="right" align="end">
          <DropdownMenuItem asChild>
            <Link to="/profile-settings" state={{ canGoBack: true }}>
              <GearSix size={20} />
              Profile settings
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={() => setIsLogOutModalOpen(true)}
            data-cy="log-out-button"
          >
            <SignOut size={20} />
            Log Out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <LogOutModal
        isOpen={isLogOutModalOpen}
        setIsOpen={setIsLogOutModalOpen}
      />
    </>
  );
}
