import React, { useState } from "react";
import { clsx } from "clsx";

import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "common/components/ui/Dialog";
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Button } from "common/components/ui/Button";
import { Promotion as PromotionType } from "common/types";
import Promotion from "common/components/Promotion";

import ResetSeats from "./ResetSeats";

export default function ManagePromotion({
  promotion,
}: {
  promotion: PromotionType;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const isTabletOrDesktop = useTwBreakpoint("md");
  const [stepId, setStepId] = useState<"assign" | "reset">("assign");

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;
  const Trigger = isTabletOrDesktop ? DialogTrigger : DrawerTrigger;

  const stepsComponents = {
    assign: (
      <Promotion
        promotion={promotion}
        onReset={() => setStepId("reset")}
        onClose={() => setIsOpen(false)}
      />
    ),
    reset: (
      <ResetSeats goBack={() => setStepId("assign")} source={promotion.type} />
    ),
  };

  return (
    <Component
      open={isOpen}
      onOpenChange={setIsOpen}
      onAfterClose={() => setStepId("assign")}
    >
      <Trigger asChild>
        <Button
          variant={
            promotion.type === "appsumo" ? "secondary-black" : "primary-black"
          }
          className={clsx(
            "max-lg:flex-1",
            promotion.type === "appsumo" && "bg-appsumoYellow",
          )}
        >
          Manage {promotion.type === "appsumo" ? "AppSumo" : "lifetime deal"}
        </Button>
      </Trigger>
      <Content>{stepsComponents[stepId]}</Content>
    </Component>
  );
}
