/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { CaretRight } from "@phosphor-icons/react";

import RenderIf from "common/components/RenderIf";
import DataTable from "common/components/DataTable";
import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import { Webhook } from "../../types";
import WebhookActionsDropdown from "./WebhookActionsDropdown";
import WebhookMobilePreview from "./WebhookMobilePreview";
import WebhookDialog from "./WebhookDialog";

interface TableProps {
  webhooks: Webhook[];
}

type Action = "edit" | "delete";

export default function WebhooksTable({ webhooks }: TableProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState<Action>(null);
  const [selectedWebhookId, setSelectedWebhookId] = useState<string>(null);

  const selectedWebhook = webhooks.find(({ id }) => id === selectedWebhookId);

  function openActionDialog(modalAction: Action) {
    setAction(modalAction);
    setIsOpen(true);
  }

  function onRowClick(webhook: Webhook) {
    setSelectedWebhookId(webhook.id);

    if (isTabletOrDesktop) {
      setAction("edit");
    }

    setIsOpen(true);
  }

  const columns = useMemo(() => {
    const templateColumns: ColumnDef<Webhook>[] = [
      {
        accessorKey: "name",
        header: "Name",
        cell: ({ row: { original } }) => (
          <span className="line-clamp-1 break-all text-body-16-bold">
            {original.name}
          </span>
        ),
      },
    ];

    if (isTabletOrDesktop) {
      templateColumns.push({
        accessorKey: "url",
        header: "URL",
        cell: ({ row: { original } }) => original.url,
      });
    }

    templateColumns.push({
      accessorKey: "events",
      header: "Events",
      cell: ({
        row: {
          original: { events },
        },
      }) => (
        <span className="line-clamp-1">
          {events.length === 1
            ? events[0]
            : `${events[0]} (+ ${events.length - 1} more)`}
        </span>
      ),
    });

    if (isTabletOrDesktop) {
      templateColumns.push({
        id: "actions",
        cell: ({ row: { original } }) => (
          <WebhookActionsDropdown
            openActionModal={(newAction) => {
              setSelectedWebhookId(original.id);
              openActionDialog(newAction);
            }}
          />
        ),
      });
    } else {
      templateColumns.push({
        id: "actions",
        cell: () => (
          <Button intent="labelIcon" size="sm" variant="quaternary-black">
            <CaretRight />
          </Button>
        ),
      });
    }

    return templateColumns;
  }, [isTabletOrDesktop]);

  return (
    <>
      <DataTable columns={columns} data={webhooks} onRowClick={onRowClick} />

      <RenderIf condition={!!selectedWebhook}>
        {isTabletOrDesktop ? (
          <WebhookDialog
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            action={action}
            webhook={selectedWebhook}
          />
        ) : (
          <WebhookMobilePreview
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            webhook={selectedWebhook}
          />
        )}
      </RenderIf>
    </>
  );
}
