import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { CheckCircle, CopySimple, Info } from "@phosphor-icons/react";
import { toast } from "react-toastify";

import { ApiKeyPermission } from "common/types";
import { Button } from "common/components/ui/Button";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { Input } from "common/components/ui/Input";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { typedObjectEntries } from "common/helpers/utils";
import Label from "common/components/ui/Label";

import PermissionOption from "./PermissionOption";
import useApiTokens from "../../datahooks/useApiTokens";
import { apiKeyPermissions } from "../../constants";
import { ApiKeyNameSchema } from "../../schema";

interface CreateTokenDialogProps {
  isOpen: boolean;
  tokensLength: number;
  onOpenChange: (open: boolean) => void;
}

export default function CreateTokenDialog({
  isOpen,
  tokensLength,
  onOpenChange,
}: CreateTokenDialogProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const defaultName = `API Key #${tokensLength + 1}`;

  const {
    register,
    reset: resetForm,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    name: string;
  }>({
    resolver: valibotResolver(ApiKeyNameSchema),
    defaultValues: {
      name: defaultName,
    },
  });

  const { createApiToken, isCreatingToken } = useApiTokens({
    disableQuery: true,
  });

  const [selectedPermission, setSelectedPermission] =
    useState<ApiKeyPermission>("all");

  const [isCopied, setIsCopied] = useState(false);
  const [apiToken, setApiToken] = useState<string>(null);

  const permissionOptions = typedObjectEntries(apiKeyPermissions).map(
    ([role, { label, description }]) => ({
      label,
      value: role,
      description,
    }),
  );

  function resetStates(): void {
    resetForm({
      name: defaultName,
    });

    setSelectedPermission("all");
    setTimeout(() => setApiToken(null), 200);
  }

  function handleOpenChange(open: boolean): void {
    if (open) {
      resetStates();
    }
    onOpenChange(open);
  }

  function copyApiKey(): void {
    const apiKey = document.getElementById("api-key")?.textContent;

    if (apiKey) {
      navigator.clipboard
        .writeText(apiKey)
        .then(() => {
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 1000);
        })
        .catch((err) => {
          toast.error("Failed to copy API key: ", err);
        });
    }
  }

  function onSubmit({ name }: { name: string }): void {
    const isWritable = selectedPermission === "all";

    createApiToken({
      name,
      write: isWritable,
    }).then((response) => {
      setApiToken(response.token);
    });
  }

  function closeDialog(): void {
    onOpenChange(false);
    resetStates();
  }

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  const content = apiToken ? (
    <div className="flex flex-col items-center justify-center gap-6">
      <div className="flex flex-col items-center gap-2">
        <h2 className="text-headline-xl-bold">Save your API key</h2>
      </div>

      <div className="flex w-full flex-col gap-2">
        <Label className="text-button-14" htmlFor="name">
          Key
        </Label>

        <div className="flex flex-col gap-1">
          <button
            type="button"
            className="flex flex-row items-center justify-between rounded-xl bg-whiteGray p-3 transition-colors duration-200 hover:bg-black-100"
            onClick={copyApiKey}
          >
            <span
              id="api-key"
              className="line-clamp-1 text-start text-button-16"
            >
              {apiToken}
            </span>

            {isCopied ? (
              <CheckCircle size={20} weight="fill" className="text-green-500" />
            ) : (
              <CopySimple size={20} />
            )}
          </button>

          <span className="flex flex-row items-center gap-1 text-caption-12-regular text-black-700">
            <Info weight="fill" size={16} />
            Keep your API key safe and do not share it with third parties
          </span>
        </div>
      </div>

      <div className="h-px w-full bg-black-100" />

      <div className="flex w-full flex-col gap-y-0.5">
        <span className="text-button-16">Permissions</span>

        <p className="text-black-500">
          {selectedPermission === "all" ? "Read and write" : "Read"} API
          resources
        </p>
      </div>

      <Button className="ml-auto w-fit" type="submit" onClick={closeDialog}>
        Finish
      </Button>
    </div>
  ) : (
    <div className="flex flex-col items-center justify-center gap-6">
      <h2 className="text-center text-headline-xl-bold">Create API key</h2>

      <form
        className="flex w-full flex-col gap-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col gap-2">
          <Label className="text-button-14" htmlFor="name">
            Key name
          </Label>

          <Input
            {...register("name")}
            error={errors.name?.message}
            placeholder="Enter name"
            aria-label="Enter key name"
            className="w-full"
            variant="lg"
            id="name"
          />
        </div>

        <div className="h-px w-full bg-black-100" />

        <div className="flex flex-col gap-2">
          <span className="text-button-14">Choose permissions:</span>

          {permissionOptions.map((option) => {
            const { label, value, description } = option;

            return (
              <PermissionOption
                key={value}
                option={value}
                selectedPermission={selectedPermission}
                onPermissionChange={setSelectedPermission}
                label={label}
                description={description}
              />
            );
          })}
        </div>

        <Button
          isLoading={isCreatingToken}
          className="ml-auto w-fit"
          type="submit"
        >
          Next
        </Button>
      </form>
    </div>
  );

  return (
    <Component
      open={isOpen}
      onOpenChange={handleOpenChange}
      onAfterClose={resetStates}
    >
      <Content className="md:px-6">{content}</Content>
    </Component>
  );
}
