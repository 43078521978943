import React from "react";
import { Files, Image, PencilSimple } from "@phosphor-icons/react";
import { useTranslation } from "react-i18next";

import { CampaignType, ProfileSearchParams } from "common/types";
import { Tag } from "common/components/ui/Tag";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "common/components/ui/Dialog";
import { Button } from "common/components/ui/Button";
import RenderIf from "common/components/RenderIf";

import SearchTargetingLabels from "./SearchTargetingLabels";
import EventTargetingLabel from "./EventTargetingLabel";
import GroupTargetingLabel from "./GroupTargetingLabel";
import { EventTargeting, GroupTargeting } from "../../types";
import useTargeting from "../../datahooks/useTargeting";

interface TargetingPreviewProps {
  campaignType: CampaignType;
  shouldShowEditButton?: boolean;
  isEditDisabled?: boolean;
}

export default function TargetingPreview({
  campaignType,
  shouldShowEditButton = false,
  isEditDisabled = false,
}: TargetingPreviewProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { t } = useTranslation("campaigns");
  const {
    targeting,
    isLoadingTargeting,
    removeTargeting,
    isRemovingTargeting,
  } = useTargeting();

  const targetingTagOptions: Record<CampaignType, React.ReactNode> = {
    search: (
      <SearchTargetingLabels
        searchParams={targeting as ProfileSearchParams}
        isLoading={isLoadingTargeting}
      />
    ),
    event: (
      <EventTargetingLabel
        eventId={(targeting as EventTargeting)?.event_id}
        isLoading={isLoadingTargeting}
      />
    ),
    group: (
      <GroupTargetingLabel
        groupId={(targeting as GroupTargeting)?.group_id}
        isLoading={isLoadingTargeting}
      />
    ),
    post: (
      <Tag size="lg" variant="primary-black" leftIcon={<Image weight="fill" />}>
        Post Campaign
      </Tag>
    ),
    list: (
      <Tag size="lg" variant="primary-black" leftIcon={<Files weight="fill" />}>
        List Campaign
      </Tag>
    ),
    ai: null,
  };

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Trigger = isTabletOrDesktop ? DialogTrigger : DrawerTrigger;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;
  const Close = isTabletOrDesktop ? DialogClose : DrawerClose;

  return (
    <section className="mb-4 flex flex-col items-start rounded-2xl border border-black-200 px-3 py-4 md:px-4">
      <div className="mb-2 flex w-full justify-between border-b border-black-200 pb-2">
        <div>
          <h3 className="text-button-16">Targeting</h3>
          <p className="text-caption-12-regular text-black-500">
            Your targeting configuration for this campaign
          </p>
        </div>

        <RenderIf condition={shouldShowEditButton}>
          <Component>
            <Trigger disabled={isEditDisabled} asChild>
              <Button variant="secondary-black" intent="iconOnly">
                <PencilSimple />
              </Button>
            </Trigger>
            <Content className="md:w-[448px]">
              <h5 className="mb-2 text-center text-headline-2xl-bold md:text-headline-xl-bold">
                {t("targetingPreviewTitle")}
              </h5>
              <p className="px-2 text-center text-black-500">
                {t("targetingPreviewSubtitle")}
              </p>
              <div className="mt-8 flex flex-col gap-y-2 md:flex-row-reverse md:gap-x-4">
                <Button
                  className="flex-1"
                  size={isTabletOrDesktop ? "md" : "lg"}
                  onClick={() => removeTargeting()}
                  isLoading={isRemovingTargeting}
                >
                  Yes
                </Button>
                <Close asChild>
                  <Button
                    variant="tertiary-black"
                    className="flex-1"
                    size={isTabletOrDesktop ? "md" : "lg"}
                  >
                    No
                  </Button>
                </Close>
              </div>
            </Content>
          </Component>
        </RenderIf>
      </div>

      {targetingTagOptions[campaignType]}
    </section>
  );
}
