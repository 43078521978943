import React from "react";
import { AreaClosed, LinePath } from "@visx/shape";
import { type ScaleLinear } from "@visx/vendor/d3-scale";
import { LinearGradient } from "@visx/gradient";

import { Metrics } from "common/types";
import { allMetrics } from "common/constants";

export default function Line({
  metrics,
  selectedMetric,
  xScale,
  yScale,
}: {
  metrics: (Metrics & { timestamp: number })[];
  selectedMetric: keyof Metrics;
  xScale: ScaleLinear<number, number>;
  yScale: ScaleLinear<number, number>;
}) {
  const { gradientColor, className } = allMetrics[selectedMetric];

  return (
    <>
      <LinePath<Metrics & { timestamp: number }>
        className={className.stroke}
        data={metrics}
        x={(value) => xScale(value.timestamp)}
        y={(value) => yScale(value[selectedMetric])}
        strokeWidth={1}
      />
      <AreaClosed<Metrics & { timestamp: number }>
        yScale={yScale}
        data={metrics}
        x={(value) => xScale(value.timestamp)}
        y={(value) => yScale(value[selectedMetric])}
        fill="url(#area-gradient)"
      />
      <LinearGradient
        id="area-gradient"
        from={gradientColor}
        to={gradientColor}
        fromOpacity={0.15}
        toOpacity={0}
      />
    </>
  );
}
