import React, { useState } from "react";
import { Plus } from "@phosphor-icons/react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";

import { Note as NoteType } from "common/types";
import { Button } from "common/components/ui/Button";
import { NoteSchema, NoteSchemaType } from "common/schemas";
import useLeadNotes from "common/datahooks/useLeadNotes";
import DeleteNoteDialog from "common/components/LeadNotes/DeleteNoteDialog";

import Note from "./Note";
import TextArea from "../ui/TextArea";

interface LeadNotesProps {
  notes: NoteType[];
  profileId: string;
}

function LeadNotes({ notes, profileId }: LeadNotesProps) {
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [deleteNoteId, setDeleteNoteId] = useState(null);
  const { addLeadNote, isAddingLeadNote } = useLeadNotes();
  const { register, handleSubmit, reset } = useForm<NoteSchemaType>({
    resolver: valibotResolver(NoteSchema),
  });

  function onSubmit({ text }: NoteSchemaType) {
    addLeadNote({ profileId, text }).then(() => {
      setIsAddingNote(false);
      reset({ text: "" });
    });
  }

  function onKeyDown(e: React.KeyboardEvent) {
    if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
      handleSubmit(onSubmit)();
    }
  }

  return (
    <div className="-mx-1 -mt-1 flex flex-col gap-y-2 overflow-y-hidden">
      <div className="flex flex-col gap-y-2 overflow-y-auto px-1 pt-1 scrollbar-thin">
        {notes.map((note) => (
          <Note
            key={note.id}
            profileId={profileId}
            note={note}
            onDelete={() => setDeleteNoteId(note.id)}
          />
        ))}
      </div>

      {isAddingNote ? (
        <div className="flex flex-col gap-y-2 px-1">
          <TextArea
            {...register("text")}
            autoFocus
            minRows={4}
            maxRows={6}
            placeholder="Write something..."
            onKeyDown={onKeyDown}
          />

          <div className="flex justify-end gap-x-2">
            <Button
              variant="tertiary-black"
              onClick={() => setIsAddingNote(false)}
              disabled={isAddingLeadNote}
            >
              Discard
            </Button>

            <Button
              variant="secondary-purple"
              onClick={handleSubmit(onSubmit)}
              isLoading={isAddingLeadNote}
            >
              Save
            </Button>
          </div>
        </div>
      ) : (
        <button
          type="button"
          onClick={() => setIsAddingNote(true)}
          className="flex items-center gap-x-2 rounded-2xl border border-dashed border-black-300 bg-white px-3.5 py-3 text-button-14 transition-[background] hover:bg-black-100"
        >
          <Button intent="labelIcon" size="xs">
            <Plus />
          </Button>
          Add note
        </button>
      )}

      <DeleteNoteDialog
        profileId={profileId}
        noteId={deleteNoteId}
        onClose={() => setDeleteNoteId(null)}
      />
    </div>
  );
}
LeadNotes.displayName = "LeadNotes";

export default LeadNotes;
