import React, { useState } from "react";

import SettingsItemContainer from "../SettingsItemContainer";
import UpdatePassword from "./UpdatePassword";

export default function Password() {
  const [isEditingPassword, setIsEditingPassword] = useState(false);

  return (
    <SettingsItemContainer
      label="Password"
      isEditing={isEditingPassword}
      onEditClick={() => {
        setIsEditingPassword(true);
      }}
      editingComponent={
        <UpdatePassword
          isEditing={isEditingPassword}
          onClose={() => setIsEditingPassword(false)}
        />
      }
    >
      <span className="text-body-14-bold">●●●●●●●●●●●</span>
    </SettingsItemContainer>
  );
}
