import React from "react";

import { typedObjectKeys } from "common/helpers/utils";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "common/components/ui/Tabs";
import { Tag } from "common/components/ui/Tag";

import SectionContainer from "./SectionContainer";

export default function Skills({
  skills,
}: {
  skills: { category: string; name: string; endorsements: number }[];
}) {
  const groupedCategories = skills.reduce(
    (accumulator, currentValue) => {
      const { category } = currentValue;
      if (accumulator[category]) {
        accumulator[category].push(currentValue);
      } else {
        accumulator[category] = [currentValue];
      }
      return accumulator;
    },
    { All: skills } as Record<string, { name: string; endorsements: number }[]>,
  );
  const categoryList = typedObjectKeys(groupedCategories);

  return (
    <SectionContainer
      title="Skills"
      extraComponent={
        skills.length && <Tag variant="primary-black">{skills.length}</Tag>
      }
    >
      <Tabs defaultValue="All">
        <TabsList className="mb-3.5 pb-2" size="sm">
          {categoryList.map((category) => (
            <TabsTrigger key={category} value={category}>
              {category}
            </TabsTrigger>
          ))}
        </TabsList>
        {Object.entries(groupedCategories).map(([key, groupedSkills]) => (
          <TabsContent
            className="grid grid-cols-1 gap-2.5 lg:grid-cols-3"
            value={key}
            key={key}
          >
            {groupedSkills.map(({ name, endorsements }) => (
              <div
                key={name}
                className="flex h-10 items-center justify-between rounded-2xl border border-black-200 pl-4 pr-2"
              >
                <span className="text-black-600">{name}</span>
                {endorsements && <Tag variant="quaternary">{endorsements}</Tag>}
              </div>
            ))}
          </TabsContent>
        ))}
      </Tabs>
    </SectionContainer>
  );
}
