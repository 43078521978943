import React, { useMemo } from "react";
import {
  DotsThreeVertical,
  PencilSimpleLine,
  Trash,
} from "@phosphor-icons/react";

import {
  Table,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "common/components/ui/Table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";
import { Button } from "common/components/ui/Button";
import Skeleton from "common/components/ui/Skeleton";

import { formatBillingDate } from "../../utils";
import { ApiToken } from "../../types";

interface DesktopTableProps {
  tokens: ApiToken[];
  isLoading: boolean;
  onEdit: (apiToken: ApiToken) => void;
  onDelete: (apiToken: ApiToken) => void;
}

export default function DesktopTable({
  tokens,
  isLoading,
  onEdit,
  onDelete,
}: DesktopTableProps) {
  const tableData = useMemo(
    () => (isLoading ? new Array(3).fill({}) : tokens),
    [tokens, isLoading],
  );

  return (
    <Table variant="list">
      {/* Table header component */}
      <TableHeader>
        <tr>
          <TableHead>Key name</TableHead>
          <TableHead>Permissions</TableHead>
          <TableHead>Key</TableHead>
          <TableHead>Created at</TableHead>
          <TableHead />
        </tr>
      </TableHeader>

      {/* Table body */}
      <tbody>
        {tableData.map((token, index) => {
          const {
            id,
            name,
            token: tokenId,
            created_at: createdAt,
            write: canWrite,
            read: canRead,
          } = token;

          const permissions = canWrite && canRead ? "All" : "Read only";

          return (
            <TableRow
              key={id || `loading-${index}`}
              onClick={() => onEdit(token)}
            >
              {/* Token name */}
              <TableCell>
                {isLoading ? (
                  <Skeleton className="h-4 w-24" />
                ) : (
                  <span className="line-clamp-1 max-w-[20ch] break-words text-headline-lg-bold">
                    {name}
                  </span>
                )}
              </TableCell>

              {/* Token permission */}
              <TableCell>
                {isLoading ? (
                  <Skeleton className="mx-auto h-4 w-24" />
                ) : (
                  <span className="text-body-700 capitalize">
                    {permissions}
                  </span>
                )}
              </TableCell>

              {/* Token ID / Key */}
              <TableCell>
                {isLoading ? (
                  <Skeleton className="mx-auto h-4 w-36" />
                ) : (
                  <span className="text-body-700">{`${tokenId.slice(0, 13)}...${tokenId.slice(-2)}`}</span>
                )}
              </TableCell>

              {/* Token created at date */}
              <TableCell>
                {isLoading ? (
                  <Skeleton className="mx-auto h-4 w-36" />
                ) : (
                  <span className="text-body-700">
                    {formatBillingDate(createdAt)}
                  </span>
                )}
              </TableCell>

              {/* Actions buttons for editing & deleting tokens */}
              <TableCell>
                <DropdownMenu>
                  <DropdownMenuTrigger disabled={isLoading} asChild>
                    <Button intent="iconOnly" variant="secondary-black">
                      <DotsThreeVertical />
                    </Button>
                  </DropdownMenuTrigger>

                  <DropdownMenuContent side="left">
                    <DropdownMenuItem onClick={() => onEdit(token)}>
                      <PencilSimpleLine size={20} /> Edit
                    </DropdownMenuItem>

                    <DropdownMenuItem
                      className="text-red-500"
                      onClick={(e) => {
                        e.stopPropagation();
                        onDelete(token);
                      }}
                    >
                      <Trash size={20} />
                      Delete
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </TableCell>
            </TableRow>
          );
        })}
      </tbody>
    </Table>
  );
}
