import React, { useState } from "react";

import RestrictedComponent from "common/components/RestrictedComponent";
import ErrorPlaceholder from "common/components/ErrorPlaceholder";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import SearchInput from "common/components/SearchInput";
import RenderIf from "common/components/RenderIf";
import Loader from "common/components/Loader";
import { Button } from "common/components/ui/Button";

import useBlacklist from "../../datahooks/useBlacklist";
import AddAccountDialog from "./AddAccountDialog";
import BlacklistAccountItem from "./BlacklistAccountItem";
import useBlacklistMutations from "../../datahooks/useBlacklistMutations";

import EmptyStateImage from "assets/images/empty-placeholders/blacklist.png";
import NoResultsImage from "assets/images/empty-placeholders/result-not-found.png";

export default function Blacklist() {
  const [searchTerm, setSearchTerm] = useState("");

  const { removeFromBlacklist } = useBlacklistMutations();
  const { blacklist, isLoadingBlacklist, blacklistError, refetchBlacklist } =
    useBlacklist();

  if (blacklistError) {
    return (
      <ErrorPlaceholder
        onRetry={refetchBlacklist}
        errorMessage="Could not display Blacklist"
      />
    );
  }

  if (isLoadingBlacklist) return <Loader />;

  if (!blacklist || !blacklist.length) {
    return (
      <EmptyPlaceholder
        imageSrc={EmptyStateImage}
        title="No blacklisted accounts"
        subtitle="Search accounts you want to add to your blacklist. These accounts will never be enrolled in an Aimfox campaign"
        actionComponent={
          <RestrictedComponent
            hiddenForRoles={["member"]}
            disabledForRoles={[]}
          >
            <AddAccountDialog />
          </RestrictedComponent>
        }
      />
    );
  }

  const filteredBlacklist = blacklist.filter((profile) =>
    profile.full_name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  function clearSearch() {
    setSearchTerm("");
  }

  return (
    <>
      <section className="mb-4 flex flex-col-reverse justify-between gap-y-3 lg:flex-row">
        <SearchInput
          className="w-full lg:w-80"
          placeholder="Search accounts"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClear={clearSearch}
        />
        <RenderIf condition={!isLoadingBlacklist}>
          <RestrictedComponent
            hiddenForRoles={["member"]}
            disabledForRoles={[]}
          >
            <AddAccountDialog />
          </RestrictedComponent>
        </RenderIf>
      </section>

      {filteredBlacklist.length === 0 ? (
        <EmptyPlaceholder
          imageSrc={NoResultsImage}
          title="No results found"
          subtitle={`No results found for "${searchTerm}"`}
          actionComponent={<Button onClick={clearSearch}>Clear search</Button>}
        />
      ) : (
        <section className="grid grid-cols-1 grid-rows-1 gap-3 lg:grid-cols-2">
          {filteredBlacklist.map((profile) => (
            <BlacklistAccountItem
              key={profile.id}
              profile={profile}
              onRemove={() => removeFromBlacklist({ targetUrn: profile.urn })}
            />
          ))}
        </section>
      )}
    </>
  );
}
