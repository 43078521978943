import React, { useState } from "react";
import { PlusCircle } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "common/components/ui/Dialog";
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";

import LabelForm from "./LabelForm";

export default function NewLabel() {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const [isOpen, setIsOpen] = useState(false);

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Trigger = isTabletOrDesktop ? DialogTrigger : DrawerTrigger;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  return (
    <Component open={isOpen} onOpenChange={setIsOpen}>
      <Trigger asChild>
        <Button
          leftIcon={<PlusCircle weight="fill" />}
          data-cy="create-label-button"
        >
          Create label
        </Button>
      </Trigger>
      <Content className="md:w-[448px]">
        <LabelForm onBack={() => setIsOpen(false)} />
      </Content>
    </Component>
  );
}
