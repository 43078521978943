import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { post, del } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { Label } from "common/types";

import useUpdateLead from "./useUpdateLead";

async function addLabel(workspaceId: string, profileId: string, label: Label) {
  await post(`workspaces/${workspaceId}/leads/${profileId}/labels`, {
    labels: [label.id],
  });
}
async function removeLabel(
  workspaceId: string,
  profileId: string,
  label: Label,
) {
  await del(`workspaces/${workspaceId}/leads/${profileId}/labels/${label.id}`);
}

export default function useLeadLabels() {
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const updateLead = useUpdateLead();

  const { mutateAsync: addLeadLabel, isPending: isAddingLeadLabel } =
    useMutation({
      mutationFn: ({ profileId, label }: { profileId: string; label: Label }) =>
        addLabel(workspaceId, profileId, label),
      onMutate: async ({ profileId, label }) => {
        await updateLead(profileId, (lead) => {
          lead.labels.push(label);
        });
      },
      onError: async (error, { profileId, label }) => {
        await updateLead(profileId, (draftLead) => {
          draftLead.labels = draftLead.labels.filter(
            ({ id }) => label.id !== id,
          );
        });
        toast.error("Error adding label");
      },
    });
  const { mutateAsync: removeLeadLabel, isPending: isRemovingLeadLabel } =
    useMutation({
      mutationFn: ({ profileId, label }: { profileId: string; label: Label }) =>
        removeLabel(workspaceId, profileId, label),
      onMutate: async ({ profileId, label }) => {
        await updateLead(profileId, (draftLead) => {
          draftLead.labels = draftLead.labels.filter(
            ({ id }) => id !== label.id,
          );
        });
        return label;
      },
      onError: async (error, { profileId, label }) => {
        await updateLead(profileId, (lead) => {
          lead.labels.push(label);
        });
        toast.error("Error removing label");
      },
    });
  return {
    addLeadLabel,
    isAddingLeadLabel,
    removeLeadLabel,
    isRemovingLeadLabel,
  };
}
