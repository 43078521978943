import React, { useState } from "react";
import {
  DotsThreeVertical,
  PencilSimpleLine,
  Trash,
} from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { CreatedTemplate } from "common/types";
import TemplateDetailsForm from "common/components/TemplateDetailsForm";

import useTemplate from "../../datahooks/useTemplate";

export default function TemplateActions({
  template,
}: {
  template: CreatedTemplate;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState<"edit" | "delete">();
  const { deleteTemplate, isDeletingTemplate } = useTemplate();

  function removeTemplate() {
    deleteTemplate({ templateId: template.id }).then(() => setIsOpen(false));
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger data-cy="template-actions-dropdown" asChild>
          <Button intent="iconOnly" variant="secondary-black">
            <DotsThreeVertical />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem
            data-cy="edit-template-menu-item"
            onClick={() => {
              setIsOpen(true);
              setType("edit");
            }}
          >
            <PencilSimpleLine size={20} />
            <span>Edit</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            data-cy="delete-template-menu-item"
            className="text-red-500"
            onClick={() => {
              setIsOpen(true);
              setType("delete");
            }}
          >
            <Trash size={20} />
            <span>Delete template</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className={type === "delete" && "md:w-[448px]"}>
          {type === "delete" ? (
            <>
              <h5 className="mb-2 text-center text-headline-2xl-bold md:text-headline-xl-bold">
                Delete template
              </h5>
              <p className="mb-12 text-center text-black-500">
                Are you sure you want to delete &quot;{template.name}
                &quot; Template?
              </p>

              <div className="flex flex-col gap-y-2 md:flex-row-reverse md:gap-x-4">
                <Button
                  data-cy="delete-template-button"
                  variant="tertiary-danger"
                  className="flex-1"
                  onClick={removeTemplate}
                  isLoading={isDeletingTemplate}
                >
                  Yes
                </Button>
                <Button
                  variant="secondary-black"
                  className="flex-1"
                  onClick={() => setIsOpen(false)}
                  disabled={isDeletingTemplate}
                >
                  Back
                </Button>
              </div>
            </>
          ) : (
            <TemplateDetailsForm
              template={template}
              goBack={() => setIsOpen(false)}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
