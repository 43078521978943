import React from "react";
import { useTranslation } from "react-i18next";

import useMetrics from "common/datahooks/useMetrics";
import useSelectedMetric from "common/hooks/useSelectedMetric";
import MetricSelector from "common/components/MetricSelector";
import TimeframeSelector from "common/components/TimeframeSelector";
import StatisticsGraph from "common/components/StatisticsGraph";
import RecentLeads from "common/components/RecentLeads";

import FlowsOverview from "./FlowsOverview";
import CampaignStats from "./CampaignStats";
import { CampaignFlow, DetailedCampaign } from "../../../types";
import { campaignOutreachOptions } from "../../../constants";

interface CampaignOverviewProps {
  flows: CampaignFlow[];
  campaign: DetailedCampaign;
  goToFlowTab: (flowId: string) => void;
}

export default function CampaignOverview({
  flows,
  campaign,
  goToFlowTab,
}: CampaignOverviewProps) {
  const { t } = useTranslation("campaigns");
  const {
    setSelectedMetric,
    selectedMetric,
    selectedMetricParams,
    setSelectedMetricParams,
  } = useSelectedMetric();
  const {
    metrics,
    metricsSum,
    refetchMetrics,
    metricsError,
    isLoadingMetrics,
  } = useMetrics({
    campaignId: campaign.id,
    parameters: selectedMetricParams,
    accountIds: [],
  });

  const { hiddenMetricsColumns } =
    campaignOutreachOptions[campaign.outreach_type];

  // Hide metrics column based on InMail optimization
  if (!campaign.inmail_optimization) {
    hiddenMetricsColumns.push("sent_inmails");
  }

  return (
    <>
      <section className="mb-4 flex flex-col items-end justify-between gap-4 md:flex-row md:items-start">
        <div className="flex w-full flex-col gap-y-1">
          <h2 className="text-headline-xl-bold">
            {t("campaignDetails.analyticsTitle")}
          </h2>
          <p className="text-black-500">
            {t("campaignDetails.analyticsSubtitle")}
          </p>
        </div>
        <TimeframeSelector
          selectedParams={selectedMetricParams}
          setSelectedParams={setSelectedMetricParams}
        />
      </section>

      <CampaignStats campaign={campaign} />

      <MetricSelector
        metricsSum={metricsSum}
        selectedMetric={selectedMetric}
        setSelectedMetric={setSelectedMetric}
        totalMetrics={campaign.metrics}
        isLoading={isLoadingMetrics}
        hiddenColumns={hiddenMetricsColumns}
      />

      <div className="relative my-6 flex h-[420px] md:h-[480px]">
        <StatisticsGraph
          selectedMetric={selectedMetric}
          metrics={metrics}
          metricsSum={metricsSum}
          requestError={metricsError}
          isLoading={isLoadingMetrics}
          isHourly={selectedMetricParams.bucketSize === "1 hour"}
          refetchMetrics={refetchMetrics}
        />
      </div>

      <FlowsOverview
        flows={flows}
        metricsParams={selectedMetricParams}
        goToFlowTab={goToFlowTab}
      />

      <RecentLeads
        campaignId={campaign.id}
        showFlowOrigin
        accountIds={[campaign.owner]}
      />
    </>
  );
}
