import React, { useState } from "react";
import { CaretDown } from "@phosphor-icons/react";
import clsx from "clsx";

import Checkbox from "common/components/ui/Checkbox";
import SearchInput from "common/components/SearchInput";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";

import useWebhookEvents from "../../../datahooks/useWebhookEvents";

interface WebhookEventsSelectProps {
  selectedEvents: string[];
  setSelectedEvents: (newEvents: string[]) => void;
}

export default function WebhookEventsSelect({
  selectedEvents,
  setSelectedEvents,
}: WebhookEventsSelectProps) {
  const [inputValue, setInputValue] = useState("");
  const { webhookEvents, isLoadingWebhookEvents } = useWebhookEvents();

  const triggerText = selectedEvents.join(", ");

  const filteredEvents = webhookEvents
    ? webhookEvents?.filter(({ type }) =>
        type.includes(inputValue.trim().toLowerCase()),
      )
    : [];

  return (
    <Popover modal>
      <PopoverTrigger
        className={clsx(
          "group flex h-12 w-full shrink-0 items-center justify-between gap-x-4 rounded-xl bg-whiteGray px-4 text-left text-button-16",
          !selectedEvents.length && "text-black-400",
        )}
        disabled={isLoadingWebhookEvents}
      >
        {selectedEvents.length ? (
          <span className="line-clamp-1 break-all">{triggerText}</span>
        ) : (
          "Select events"
        )}
        <CaretDown
          size={20}
          className="fill-black-950 transition-transform group-data-[state=open]:rotate-180"
        />
      </PopoverTrigger>
      <PopoverContent className="z-aboveDialog w-[--radix-popper-anchor-width] py-2">
        <SearchInput
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onClear={() => setInputValue("")}
          className="mx-4 mb-1"
          placeholder="Search events"
        />

        <div className="flex max-h-44 flex-col overflow-y-auto scrollbar-thin">
          {filteredEvents.map(({ type, description }) => {
            const isChecked = !!selectedEvents.find(
              (eventType) => eventType === type,
            );

            function onClick() {
              if (isChecked) {
                setSelectedEvents(
                  selectedEvents.filter((eventType) => eventType !== type),
                );
              } else {
                setSelectedEvents([...selectedEvents, type]);
              }
            }

            return (
              <button
                type="button"
                className="my-1 inline-flex gap-x-1 px-4 py-2 text-button-14 transition-colors hover:bg-black-100"
                key={type}
                onClick={onClick}
              >
                <Checkbox
                  checked={isChecked}
                  isStyleOnly
                  className="m-[3.5px]"
                />
                <div className="flex flex-col gap-0.5">
                  <h5 className="text-left text-button-14">{type}</h5>
                  <p className="text-left text-caption-12-regular">
                    {description}
                  </p>
                </div>
              </button>
            );
          })}
        </div>
      </PopoverContent>
    </Popover>
  );
}
