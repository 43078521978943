import { useState } from "react";

const linkedInUrlRegex =
  /linkedin\.com\/in\/[a-zA-Z0-9\-_%’\u0080-\u0F2F\p{Emoji_Presentation}]+\/?$/u;
export default function useLinkedInUrls() {
  const [linkedInUrls, setLinkedInUrls] = useState<string[]>([]);

  const {
    validUrls,
    invalidLines,
  }: {
    validUrls: string[];
    invalidLines: number[];
  } = linkedInUrls.reduce(
    (accumulator, currentValue, currentIndex) => {
      if (currentValue && linkedInUrlRegex.test(currentValue)) {
        accumulator.validUrls.push(currentValue);
      } else {
        accumulator.invalidLines.push(currentIndex + 1);
      }
      return accumulator;
    },
    { validUrls: [], invalidLines: [] },
  );

  return { linkedInUrls, setLinkedInUrls, validUrls, invalidLines };
}
