import React, { useEffect, useState } from "react";

import { Button } from "common/components/ui/Button";

import useAccountLimits from "../../datahooks/useAccountLimits";
import DynamicSlider from "./DynamicSlider";

const DEFAULT_VALUE = 100;

export default function AccountDynamics({ accountId }: { accountId: string }) {
  const {
    accountLimits,
    isLoadingAccountLimits,
    setAccountLimits,
    isSettingAccountLimits,
  } = useAccountLimits(accountId);

  // State for slider values
  const [sliderValues, setSliderValues] = useState(() => ({
    connectionRequests: DEFAULT_VALUE,
    messageRequests: DEFAULT_VALUE,
    inMails: DEFAULT_VALUE,
  }));

  useEffect(() => {
    if (accountLimits) {
      const {
        connect: connectionRequests,
        message_request: messageRequests,
        inmail: inMails,
      } = accountLimits;

      setSliderValues({
        connectionRequests,
        messageRequests,
        inMails,
      });
    }
  }, [accountLimits]);

  // Constant object for slider configurations
  const sliders = [
    {
      label: "Connection requests",
      value: sliderValues.connectionRequests,
      setValue: (connections: number) =>
        setSliderValues((prev) => ({
          ...prev,
          connectionRequests: connections,
        })),
    },
    {
      label: "Messages",
      value: sliderValues.messageRequests,
      setValue: (messages: number) =>
        setSliderValues((prev) => ({ ...prev, messageRequests: messages })),
    },
    {
      label: "InMails",
      value: sliderValues.inMails,
      setValue: (mails: number) =>
        setSliderValues((prev) => ({ ...prev, inMails: mails })),
    },
  ];

  function handleSave() {
    const { connectionRequests, messageRequests, inMails } = sliderValues;

    setAccountLimits({
      connect: connectionRequests,
      message_request: messageRequests,
      inmail: inMails,
    });
  }

  return (
    <div className="flex flex-col gap-4 rounded-2xl bg-white py-4 md:px-4">
      <h3 className="text-headline-xl-bold">Interaction dynamics</h3>
      <p className="text-button-14">Set up weekly limits for</p>

      {/* Map over the sliders array to render DynamicSliders */}
      {sliders.map(({ label, value, setValue }) => (
        <DynamicSlider
          key={label}
          label={label}
          value={value}
          setValue={setValue}
          isLoading={isLoadingAccountLimits}
        />
      ))}

      <div className="flex justify-between gap-2">
        <Button
          size="sm"
          variant="tertiary-black"
          disabled={isSettingAccountLimits || isLoadingAccountLimits}
          onClick={() =>
            setSliderValues({
              connectionRequests: DEFAULT_VALUE,
              messageRequests: DEFAULT_VALUE,
              inMails: DEFAULT_VALUE,
            })
          }
        >
          Reset to default
        </Button>

        <Button
          size="sm"
          variant="secondary-purple"
          onClick={handleSave}
          isLoading={isSettingAccountLimits}
          disabled={isLoadingAccountLimits}
        >
          Save
        </Button>
      </div>
    </div>
  );
}
