import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { CaretRight } from "@phosphor-icons/react";

import { templateTypes } from "common/constants";
import { typedObjectEntries } from "common/helpers/utils";
import { TemplateType } from "common/types";
import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

interface TypeSelectionProps {
  goToNextStep: (type: TemplateType) => void;
}

export default function TypeSelection({ goToNextStep }: TypeSelectionProps) {
  const isDesktopOrTablet = useTwBreakpoint("md");
  const [type, setType] = useState<TemplateType>();
  const { t } = useTranslation("enums");

  return (
    <>
      <h5 className="mb-8 text-center text-headline-2xl-bold md:text-headline-xl-bold">
        Choose template type
      </h5>

      {typedObjectEntries(templateTypes).map(([key, { icon: Icon }]) => (
        <button
          className={clsx(
            "mb-5 flex items-center gap-3 rounded-2xl border border-black-200 px-3 py-4 transition-all",
            type === key ? "bg-purple-50" : "bg-white hover:shadow-md",
          )}
          type="button"
          key={key}
          onClick={() => setType(key)}
          data-cy={key}
        >
          <span className="inline-flex size-12 shrink-0 items-center justify-center rounded-2xl border border-black-200 bg-white">
            <Icon size={24} />
          </span>
          <div>
            <h5 className="mb-0.5 text-left text-button-16">
              {t(key)} Template
            </h5>
            <p className="text-left text-caption-12-regular text-black-500">
              {t(`templateDescription.${key}`, {
                ns: "templates",
                templateType: key,
              })}
            </p>
          </div>
        </button>
      ))}

      <div className="-mx-4 mb-6 h-px bg-black-200 px-4 max-md:hidden" />

      <Button
        rightIcon={<CaretRight />}
        onClick={() => goToNextStep(type)}
        className="md:ml-auto"
        size={isDesktopOrTablet ? "md" : "lg"}
        disabled={!type}
        data-cy="template-type-next-button"
      >
        Next
      </Button>
    </>
  );
}
