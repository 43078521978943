import { useQuery } from "@tanstack/react-query";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import getQueryKeys from "common/datahooks/getQueryKeys";
import { post } from "common/helpers/HTTP";
import { useCampaignContext } from "common/helpers/CampaignContext";
import { LinkedInProfile, ProfileSearchParams } from "common/types";

async function searchProfilesRequest(
  workspaceId: string,
  campaignId: string,
  searchParams: object,
): Promise<{
  audience: LinkedInProfile[];
  totalCount: number;
  isLimitExceeded: boolean;
}> {
  const queryParams = new URLSearchParams();
  queryParams.set("count", "20");

  const {
    targets: audience,
    total_count: totalCount,
    limit_exceeded: isLimitExceeded,
  } = await post(
    `workspaces/${workspaceId}/campaigns/${campaignId}/search?${queryParams.toString()}`,
    searchParams,
  );

  return { audience, totalCount, isLimitExceeded };
}

export default function useProfilesPreview({
  searchParams,
}: {
  searchParams: Partial<ProfileSearchParams>;
}) {
  const { campaignId } = useCampaignContext();
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { campaignsKeys } = getQueryKeys(workspaceId);

  const { data: searchResults, isLoading: isLoadingProfiles } = useQuery({
    queryKey: campaignsKeys.profilesPreview(campaignId, searchParams),
    queryFn: () => searchProfilesRequest(workspaceId, campaignId, searchParams),
    enabled: Object.values(searchParams).some((param) =>
      Array.isArray(param) ? param.length : param,
    ),
  });

  return {
    profiles: searchResults?.audience,
    totalCount: searchResults?.totalCount,
    isLimitExceeded: searchResults?.isLimitExceeded,
    isLoadingProfiles,
  };
}
