import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";

import { WebhookEvent } from "../types";

async function getWebhookEventsRequest(): Promise<WebhookEvent[]> {
  return (await get("webhooks/events")).events;
}

export default function useWebhookEvents() {
  const { data: webhookEvents, isLoading: isLoadingWebhookEvents } = useQuery({
    queryKey: ["webhookEvents"],
    queryFn: getWebhookEventsRequest,
    staleTime: Infinity,
  });

  return { webhookEvents, isLoadingWebhookEvents };
}
