import React, { ReactNode } from "react";
import { Check, X } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";

interface EditContainerProps {
  isEditing: boolean;
  onSubmit: () => void;
  isLoading: boolean;
  onClose: () => void;
  children: ReactNode;
}
export default function EditContainer({
  isEditing,
  onSubmit,
  isLoading,
  onClose,
  children,
}: EditContainerProps) {
  const isDesktop = useTwBreakpoint("lg");

  if (!isDesktop) {
    return (
      <Drawer open={isEditing} onOpenChange={onClose}>
        <DrawerContent>
          {children}
          <div className="mt-8 flex flex-col gap-y-2">
            <Button size="lg" onClick={onSubmit} disabled={isLoading}>
              Save
            </Button>
            <Button
              variant="secondary-black"
              size="lg"
              onClick={onClose}
              disabled={isLoading}
            >
              Cancel
            </Button>
          </div>
        </DrawerContent>
      </Drawer>
    );
  }
  if (isEditing) {
    return (
      <form
        className="flex w-full flex-col gap-4 lg:flex-row lg:items-end"
        onSubmit={onSubmit}
      >
        {children}
        <div className="flex flex-row gap-2">
          <Button
            variant="tertiary-black"
            intent="iconOnly"
            onClick={onClose}
            disabled={isLoading}
          >
            <X />
          </Button>
          <Button
            variant="secondary-purple"
            intent="iconOnly"
            type="submit"
            disabled={isLoading}
          >
            <Check />
          </Button>
        </div>
      </form>
    );
  }
}
