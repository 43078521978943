import { clsx } from "clsx";
import React from "react";
import { X } from "@phosphor-icons/react";

import { labelColors } from "common/constants";
import { Tag } from "common/components/ui/Tag";
import { Label } from "common/types";

type LabelTagProps = { label: Label; size?: "md" | "lg" } & (
  | { onClick?: () => void; onRemove?: never }
  | { onClick?: never; onRemove?: () => void }
);
export default function LabelTag({
  label,
  size = "md",
  onClick,
  onRemove,
}: LabelTagProps) {
  const innerContent = (
    <>
      <div
        className={clsx(
          "-ml-1.5 size-2.5 shrink-0 rounded",
          labelColors[label.color].bgClassName,
        )}
      />
      <span className="overflow-hidden truncate">{label.name}</span>
    </>
  );
  return (
    <Tag
      key={label.id}
      variant="tertiary"
      className="min-w-16 max-w-full shrink"
      size={size}
      asChild={!!onClick}
      rightIcon={
        onRemove && (
          <button
            aria-label={`remove label parameter ${label.name}`}
            type="button"
            onClick={onRemove}
          >
            <X />
          </button>
        )
      }
    >
      {onClick ? (
        <button
          type="button"
          className="transition-[background] hover:bg-black-100"
          onClick={onClick}
        >
          {innerContent}
        </button>
      ) : (
        innerContent
      )}
    </Tag>
  );
}
