import React from "react";
import { ClockCounterClockwise } from "@phosphor-icons/react";
import clsx from "clsx";

import RenderIf from "common/components/RenderIf";
import { Member } from "common/types";
import {
  Table,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "common/components/ui/Table";
import { Tag } from "common/components/ui/Tag";
import { userRoles } from "common/constants";
import Avatar from "common/components/ui/Avatar";
import useCustomer from "common/datahooks/useCustomer";

import { INVITATION_STATUSES } from "../../constants";
import MemberActions from "./MemberActions";
import { formatBillingDate } from "../../utils";

export default function DesktopTable({ members }: { members: Member[] }) {
  const {
    customerInfo: { email: customerEmail },
  } = useCustomer();

  const matchingMember = members.find(
    (member) => member.email === customerEmail,
  );
  const customerRole = matchingMember
    ? userRoles[matchingMember.role].label
    : null;

  return (
    <Table variant="list">
      <TableHeader>
        <tr>
          <TableHead>Name</TableHead>
          <TableHead>Role</TableHead>
          <TableHead>Date Added</TableHead>
          <TableHead>Status</TableHead>
          <TableHead />
        </tr>
      </TableHeader>

      <tbody>
        {members.map((member: Member) => {
          const {
            token,
            first_name: firstName = "New",
            last_name: lastName = "Member",
            role,
            customer_id: memberId,
            email,
            status,
            created_at: createdAt,
          } = member;

          const roleData = userRoles[role];
          const { label: roleLabel, tagVariant: roleTagVariant } = roleData;

          const hasMemberActions =
            (customerRole !== "Member" || customerEmail === email) &&
            role !== "owner";

          const renderStatusTag = () => {
            if (role === "owner") {
              return "-";
            }

            const { label } = INVITATION_STATUSES[status];

            return (
              <div
                className={clsx([
                  "flex flex-row items-center justify-center gap-1",
                  status === "pending" && "text-black-500",
                ])}
              >
                <span className="text-caption-12-regular ">{label}</span>

                <RenderIf condition={status === "pending"}>
                  <ClockCounterClockwise />
                </RenderIf>
              </div>
            );
          };

          function getNameInitials() {
            return `${firstName[0]}${lastName[0]}`;
          }

          return (
            <TableRow
              key={token}
              className="hover:shadow-none hover:cursor-auto"
            >
              <TableCell className="flex items-center gap-2">
                <Avatar
                  variant={status === "pending" ? "quaternary" : "primary"}
                >
                  {getNameInitials()}
                </Avatar>

                <div className="flex flex-col items-start gap-1">
                  <span
                    className={clsx(
                      "text-body-14-bold",
                      memberId ? "text-black-700" : "text-black-300",
                    )}
                  >
                    {memberId ? `${firstName} ${lastName}` : "New Member"}
                  </span>

                  <span
                    className={clsx(
                      "inline-block max-w-[40ch] overflow-hidden text-ellipsis",
                      memberId ? "text-black-700" : "text-black-300",
                    )}
                  >
                    {email}
                  </span>
                </div>
              </TableCell>

              <TableCell>
                <Tag variant={roleTagVariant} className="capitalize" size="md">
                  {roleLabel}
                </Tag>
              </TableCell>

              <TableCell
                className={clsx(
                  "text-caption-12-regular",
                  memberId ? "text-black-700" : "text-black-300",
                )}
              >
                {role === "owner" ? "-" : formatBillingDate(createdAt)}
              </TableCell>

              <TableCell>{renderStatusTag()}</TableCell>

              <TableCell>
                <RenderIf condition={hasMemberActions}>
                  <MemberActions member={member} />
                </RenderIf>
              </TableCell>
            </TableRow>
          );
        })}
      </tbody>
    </Table>
  );
}
