import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { get, post, del, patch } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { ApiToken } from "../types";

async function getApiTokens(workspaceId: string): Promise<ApiToken[]> {
  return (await get(`workspaces/${workspaceId}/tokens`)).tokens;
}

async function createApiTokenRequest(
  workspaceId: string,
  name: string,
  write: boolean,
): Promise<ApiToken> {
  return (
    await post(`workspaces/${workspaceId}/tokens`, {
      name: name.trim(),
      write,
    })
  ).token;
}

async function deleteApiTokenRequest(
  workspaceId: string,
  tokenId: string,
): Promise<void> {
  await del(`workspaces/${workspaceId}/tokens/${tokenId}`);
}

async function updateApiTokenRequest(
  workspaceId: string,
  tokenId: string,
  updates: Partial<{ name: string; write: boolean }>,
): Promise<ApiToken> {
  return (await patch(`workspaces/${workspaceId}/tokens/${tokenId}`, updates))
    .token;
}

export default function useApiTokens({
  disableQuery,
}: { disableQuery?: boolean } = {}) {
  const queryClient = useQueryClient();
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const {
    data: tokens,
    isLoading: isLoadingTokens,
    error: tokensError,
    refetch: refetchTokens,
  } = useQuery<ApiToken[]>({
    queryKey: ["apiTokens"],
    queryFn: () => getApiTokens(workspaceId),
    enabled: !!disableQuery,
  });

  const { mutateAsync: createApiToken, isPending: isCreatingToken } =
    useMutation({
      mutationFn: ({ name, write }: { name: string; write: boolean }) =>
        createApiTokenRequest(workspaceId, name, write),
      onSuccess: (newToken) => {
        queryClient.setQueryData<ApiToken[]>(
          ["apiTokens"],
          (prevTokens = []) => [...prevTokens, newToken].sort((a, b) => a.created_at - b.created_at)
        );
        toast.success("API token created successfully");
      },
      onError: () => {
        toast.error("Failed to create API token");
      },
    });

  const { mutateAsync: deleteApiToken, isPending: isDeletingToken } =
    useMutation({
      mutationFn: (tokenId: string) =>
        deleteApiTokenRequest(workspaceId, tokenId),
      onSuccess: (_, tokenId) => {
        queryClient.setQueryData<ApiToken[]>(["apiTokens"], (prevTokens = []) =>
          prevTokens.filter((token) => token.id !== tokenId),
        );
        toast.success("API token deleted successfully");
      },
      onError: () => {
        toast.error("Failed to delete API token");
      },
    });

  const { mutateAsync: updateApiToken, isPending: isUpdatingToken } =
    useMutation({
      mutationFn: ({
        tokenId,
        updates,
      }: {
        tokenId: string;
        updates: Partial<{ name: string; write: boolean }>;
      }) => updateApiTokenRequest(workspaceId, tokenId, updates),
      onSuccess: (updatedToken) => {
        queryClient.setQueryData<ApiToken[]>(["apiTokens"], (prevTokens = []) =>
          prevTokens
            .map((token) => (token.id === updatedToken.id ? updatedToken : token))
            .sort((a, b) => a.created_at - b.created_at)
        );
        toast.success("API token updated successfully");
      },
      onError: () => {
        toast.error("Failed to update API token");
      },
    });

  return {
    tokens,
    isLoadingTokens,
    tokensError,
    refetchTokens,
    createApiToken,
    isCreatingToken,
    deleteApiToken,
    isDeletingToken,
    updateApiToken,
    isUpdatingToken,
  };
}
