import React, { ReactNode } from "react";
import { PencilSimpleLine } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import RenderIf from "common/components/RenderIf";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

interface BaseProfileSettingsItemProps {
  label: string;
  children: ReactNode;
}
type ProfileSettingsItemProps = BaseProfileSettingsItemProps &
  (
    | {
        isEditing: boolean;
        onEditClick: () => void;
        editingComponent: ReactNode;
      }
    | {
        isEditing?: never;
        onEditClick?: never;
        editingComponent?: never;
      }
  );

export default function SettingsItemContainer({
  label,
  isEditing,
  onEditClick,
  children,
  editingComponent,
}: ProfileSettingsItemProps) {
  const isDesktop = useTwBreakpoint("lg");

  return (
    <>
      <div className="flex w-full items-center justify-between border-b border-black-200 py-4 last:border-none md:py-6">
        <div className="flex w-full flex-col items-start justify-start gap-2 md:flex-row md:gap-0">
          <span className="w-full max-w-[224px] text-black-600">{label}</span>
          {isDesktop && isEditing ? editingComponent : children}
        </div>

        <RenderIf condition={(isDesktop ? !isEditing : true) && !!onEditClick}>
          <Button
            className="mt-2 md:mt-0"
            intent="iconOnly"
            variant="quaternary-black"
            onClick={onEditClick}
          >
            <PencilSimpleLine />
          </Button>
        </RenderIf>
      </div>
      <RenderIf condition={!isDesktop}>{editingComponent}</RenderIf>
    </>
  );
}
