import React from "react";

import { Button } from "common/components/ui/Button";
import useLicenseMutations from "common/datahooks/useLicenseMutations";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

interface RemoveCancellationProps {
  accountId: string;
  goBack: () => void;
  onSuccess: () => void;
}

export default function RemoveCancellation({
  accountId,
  goBack,
  onSuccess,
}: RemoveCancellationProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const { removeLicenseCancellation, isRemovingLicenseCancellation } =
    useLicenseMutations();

  function onClick() {
    removeLicenseCancellation({ accountId }).then(onSuccess);
  }

  return (
    <>
      <h3 className="mb-2 text-center text-headline-2xl-bold md:text-headline-xl-bold">
        Remove scheduled cancellation?
      </h3>
      <p className="mb-12 text-center text-black-500">
        The seat for this account will renew on your next billing date. You can
        review your subscription at any time in your subscription settings.
      </p>
      <div className="flex flex-col-reverse gap-1.5 md:flex-row md:gap-4">
        <Button
          onClick={goBack}
          variant="secondary-black"
          disabled={isRemovingLicenseCancellation}
          className="flex-1"
          size={isTabletOrDesktop ? "md" : "lg"}
        >
          Cancel
        </Button>
        <Button
          isLoading={isRemovingLicenseCancellation}
          onClick={onClick}
          variant="tertiary-danger"
          size={isTabletOrDesktop ? "md" : "lg"}
          className="flex-1"
        >
          Remove cancellation
        </Button>
      </div>
    </>
  );
}
