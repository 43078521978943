import React from "react";
import { CaretDown } from "@phosphor-icons/react";
import { clsx } from "clsx";

import { Button } from "common/components/ui/Button";
import {
  DropdownMenuTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from "common/components/ui/DropdownMenu";
import Checkbox from "common/components/ui/Checkbox";
import useWorkspaceLabels from "common/datahooks/useWorkspaceLabels";
import { labelColors } from "common/constants";

interface LabelSelectorProps {
  selectedLabelIds: string[];
  setSelectedLabelIds: (selectedLabelIds: string[]) => void;
  className?: string;
}

export default function LabelSelector({
  selectedLabelIds,
  setSelectedLabelIds,
  className = "",
}: LabelSelectorProps) {
  const { workspaceLabels } = useWorkspaceLabels();
  const hasLabels = workspaceLabels && workspaceLabels.length > 0;

  function removeLabelId(labelId: string): void {
    setSelectedLabelIds(selectedLabelIds.filter((id) => id !== labelId));
  }

  function addLabelId(labelId: string): void {
    setSelectedLabelIds([...selectedLabelIds, labelId]);
  }

  let triggerLabel = "All labels";

  if (hasLabels && selectedLabelIds.length > 0) {
    triggerLabel =
      selectedLabelIds.length > 1
        ? `${selectedLabelIds.length} labels selected`
        : workspaceLabels.find(({ id }) => id === selectedLabelIds[0])?.name;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild disabled={!hasLabels}>
        <Button
          className={clsx("group", className)}
          variant="tertiary-black"
          rightIcon={
            <CaretDown className="transition-transform group-data-[state=open]:rotate-180" />
          }
        >
          <span className="mr-auto truncate">
            {hasLabels ? triggerLabel : "No labels available"}
          </span>
        </Button>
      </DropdownMenuTrigger>

      {hasLabels && (
        <DropdownMenuContent
          className="z-aboveDialog max-h-[325px] w-full min-w-[--radix-dropdown-menu-trigger-width] max-w-[406px] overflow-y-auto scrollbar-thin md:max-h-[250px] md:max-w-[508px]"
          side="bottom"
        >
          <DropdownMenuItem
            className="gap-x-1"
            onSelect={() => {
              setSelectedLabelIds([]);
            }}
          >
            All labels
          </DropdownMenuItem>

          {workspaceLabels?.map(({ id, name, color }) => {
            const isLabelIdIncluded = selectedLabelIds.includes(id);

            return (
              <DropdownMenuItem
                key={id}
                className="gap-x-1"
                onSelect={() => {
                  setSelectedLabelIds([id]);
                }}
              >
                <Checkbox
                  checked={isLabelIdIncluded}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={() => {
                    if (isLabelIdIncluded) {
                      removeLabelId(id);
                      return;
                    }

                    addLabelId(id);
                  }}
                  size="sm"
                />

                <div
                  className={clsx(
                    "ml-1 size-3.5 shrink-0 rounded",
                    labelColors[color].bgClassName,
                  )}
                />
                <span className="ml-1 truncate">{name}</span>
              </DropdownMenuItem>
            );
          })}
        </DropdownMenuContent>
      )}
    </DropdownMenu>
  );
}
