import React, { Dispatch, SetStateAction } from "react";

import { Button } from "common/components/ui/Button";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import { Campaign } from "common/types";
import MaxLengthInput from "common/components/MaxLengthInput";
import { getSchemaMaxLength } from "common/helpers/utils";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { DetailedCampaign } from "../../types";
import { CampaignNameSchema } from "../../schemas";
import useCampaignActions from "../../hooks/useCampaignActions";

interface CampaignActionsDialogProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  type: "start" | "pause" | "delete" | "editName";
  campaign: DetailedCampaign | Campaign;
}

export default function CampaignActionsDialog({
  isOpen,
  setIsOpen,
  type,
  campaign,
}: CampaignActionsDialogProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const { accounts } = useSelectedWorkspaceContext();
  const account = accounts.find(({ id }) => id === campaign.owner);
  const { license } = account;

  function onClose() {
    setIsOpen(false);
  }

  const {
    removeCampaign,
    updateCampaignState,
    handleEditName,
    assignLicenseToOwner,
    isAssigningLicense,
    isDeletingCampaign,
    isUpdatingCampaign,
    // Form return
    register,
    watch,
    errors,
    reset,
  } = useCampaignActions(campaign, onClose);

  let mainContent;
  if (type === "editName") {
    mainContent = (
      <>
        <h5 className="mb-8 text-center text-headline-2xl-bold capitalize md:text-headline-xl-bold">
          Change campaign name
        </h5>
        <MaxLengthInput
          variant="lg"
          error={errors?.name?.message}
          characterLength={watch("name").length}
          maxLength={getSchemaMaxLength(CampaignNameSchema.entries.name)}
          className="mb-12"
          {...register("name")}
        />
        <div className="flex flex-col gap-y-2 md:flex-row-reverse md:gap-x-4">
          <Button
            isLoading={isUpdatingCampaign}
            onClick={handleEditName}
            className="flex-1"
            size={isTabletOrDesktop ? "md" : "lg"}
          >
            Save
          </Button>
          <Button
            variant="secondary-black"
            className="flex-1"
            size={isTabletOrDesktop ? "md" : "lg"}
            disabled={isUpdatingCampaign}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </>
    );
  } else if (type === "start" && !license) {
    mainContent = (
      <>
        <h5 className="mb-2 text-center text-headline-2xl-bold capitalize md:text-headline-xl-bold">
          Assign seat to {account.full_name}?
        </h5>
        <p className="mb-12 text-center text-black-500">
          You will get full access to LinkedIn Outreach and campaigns
        </p>
        <div className="flex flex-col gap-y-2 md:flex-row-reverse md:gap-x-4">
          <Button
            size={isTabletOrDesktop ? "md" : "lg"}
            className="flex-1"
            variant="gradient"
            onClick={assignLicenseToOwner}
            isLoading={isAssigningLicense}
          >
            Assign
          </Button>
          <Button
            variant="secondary-black"
            size={isTabletOrDesktop ? "md" : "lg"}
            className="flex-1"
            disabled={isAssigningLicense}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </>
    );
  } else {
    mainContent = (
      <>
        <h5 className="mb-2 text-center text-headline-2xl-bold capitalize md:text-headline-xl-bold">
          {type} Campaign
        </h5>
        <p className="mb-12 break-words text-center text-black-500">
          Are you sure you want to {type} campaign &quot;
          {campaign.name}
          &quot;?
        </p>
        <div className="flex flex-col gap-y-2 md:flex-row-reverse md:gap-x-4">
          <Button
            variant={type === "delete" ? "tertiary-danger" : "primary-black"}
            size={isTabletOrDesktop ? "md" : "lg"}
            className="flex-1"
            onClick={type === "delete" ? removeCampaign : updateCampaignState}
            isLoading={isDeletingCampaign || isUpdatingCampaign}
          >
            {type === "delete" ? "Delete" : "Yes"}
          </Button>
          <Button
            variant="secondary-black"
            onClick={onClose}
            className="flex-1"
            size={isTabletOrDesktop ? "md" : "lg"}
            disabled={isUpdatingCampaign}
          >
            {type === "delete" ? "Cancel" : "No"}
          </Button>
        </div>
      </>
    );
  }

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;
  return (
    <Component
      open={isOpen}
      onOpenChange={setIsOpen}
      onAfterClose={() => reset()}
    >
      <Content className="md:w-[448px]">{mainContent}</Content>
    </Component>
  );
}
