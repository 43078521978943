import React, { useState } from "react";
import { EnvelopeSimple } from "@phosphor-icons/react";

import useCustomer from "common/datahooks/useCustomer";

import SettingsItemContainer from "../SettingsItemContainer";
import UpdateEmail from "./UpdateEmail";

export default function Email() {
  const [isEditingEmail, setIsEditingEmail] = useState(false);

  const { customerInfo } = useCustomer();

  return (
    <SettingsItemContainer
      label="Email"
      isEditing={isEditingEmail}
      onEditClick={() => {
        setIsEditingEmail(true);
      }}
      editingComponent={
        <UpdateEmail
          isEditing={isEditingEmail}
          onClose={() => setIsEditingEmail(false)}
        />
      }
    >
      <div className="flex flex-row items-center gap-2 text-black-400">
        <EnvelopeSimple size={20} />

        <span className="max-w-[256px] overflow-hidden truncate text-body-14-bold ">
          {customerInfo.email}
        </span>
      </div>
    </SettingsItemContainer>
  );
}
