import React from "react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { CircleNotch } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import {
  Dialog,
  DialogClose,
  DialogContent,
} from "common/components/ui/Dialog";
import { Input } from "common/components/ui/Input";
import Label from "common/components/ui/Label";
import { WorkspaceNameSchema } from "common/schemas";
import useWorkspaceMutations from "common/datahooks/useWorkspaceMutations";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
} from "common/components/ui/Drawer";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

interface EditNameDialogProps {
  workspaceId: string;
  workspaceName: string;
  isEditingName: boolean;
  setIsEditingName: (isEditing: boolean) => void;
}

export default function EditNameDialog({
  workspaceId,
  workspaceName,
  isEditingName,
  setIsEditingName,
}: EditNameDialogProps) {
  const isDesktop = useTwBreakpoint("lg");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ name: string }>({
    resolver: valibotResolver(WorkspaceNameSchema),
    defaultValues: { name: workspaceName },
  });

  const { editWorkspaceName, isEditingWorkspaceName } = useWorkspaceMutations();

  function onSubmit({ name }: { name: string }) {
    editWorkspaceName({ id: workspaceId, name }).then(() => {
      setIsEditingName(false);
    });
  }

  const Component = isDesktop ? Dialog : Drawer;
  const Content = isDesktop ? DialogContent : DrawerContent;
  const Close = isDesktop ? DialogClose : DrawerClose;

  return (
    <Component open={isEditingName} onOpenChange={setIsEditingName}>
      <Content className="lg:w-[448px]">
        <h3 className="mb-8 text-center text-headline-2xl-bold lg:text-headline-xl-bold">
          Edit workspace name
        </h3>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Label htmlFor="name" className="text-button-12 text-black-700">
            Workspace name
          </Label>

          <Input
            id="name"
            placeholder="Workspace name"
            className="mt-1"
            variant="lg"
            error={errors.name?.message}
            {...register("name")}
            rightComponent={
              isEditingWorkspaceName && (
                <CircleNotch className="animate-spin text-black-500" />
              )
            }
          />

          <div className="mt-8 flex flex-col-reverse gap-4 lg:flex-row">
            <Close asChild>
              <Button
                size={isDesktop ? "md" : "lg"}
                disabled={isEditingWorkspaceName}
                variant="secondary-black"
                className="flex-1"
              >
                Cancel
              </Button>
            </Close>

            <Button
              size={isDesktop ? "md" : "lg"}
              className="flex-1"
              disabled={isEditingWorkspaceName}
              type="submit"
            >
              Save
            </Button>
          </div>
        </form>
      </Content>
    </Component>
  );
}
