import { ApiKeyPermission, InvitationStatus } from "common/types";
import { TagVariantType } from "common/components/ui/Tag";

import { InvoiceStatus } from "./types";

import ZapierIcon from "assets/icons/integrations/zapier.svg?react";
import HubspotIcon from "assets/icons/integrations/hubspot.svg?react";
import SlackIcon from "assets/icons/integrations/slack.svg?react";

export const BILLING_STATUSES: Record<
  InvoiceStatus,
  { label: string; bgClassname: string }
> = {
  paid: { label: "Paid", bgClassname: "bg-green-400" },
  posted: { label: "Posted", bgClassname: "bg-black-400" },
  pending: { label: "Invite sent", bgClassname: "bg-black-400" },
  not_paid: { label: "Not paid", bgClassname: "bg-red-500" },
  payment_due: { label: "Payment due", bgClassname: "bg-yellow-400" },
  voided: { label: "Voided", bgClassname: "bg-black-400" },
} as const;

export const INVITATION_STATUSES: Record<
  InvitationStatus,
  {
    label: string;
  }
> = {
  pending: { label: "Invite sent" },
  accepted: { label: "Accepted" },
  rejected: { label: "Declined" },
} as const;

export const apiKeyPermissions: Record<
  ApiKeyPermission,
  {
    label: string;
    tagVariant: TagVariantType;
    textColor: string;
    description?: string;
  }
> = {
  all: {
    label: "All",
    tagVariant: "primary",
    textColor: "text-purple-600",
    description:
      "“All” permission level allows full access to Aimfox resources. This key will allow any API call from our documentation",
  },
  "read-only": {
    label: "Read Only",
    tagVariant: "secondary",
    textColor: "text-purple-400",
    description:
      "“Read only” permission level allows read only access to Aimfox resources. This key will only allow access to GET API calls from our documentation",
  },
} as const;

export const integrationsList = [
  {
    title: "Zapier",
    description:
      "Integrate Aimfox with over 6000 other apps by using our official Zapier integration",
    icon: ZapierIcon,
    redirectUrl: "https://zapier.com/apps/aimfox/integrations",
  },
  {
    title: "Hubspot",
    description:
      "Integrate Aimfox with Hubspot, the world's leading CRM and marketing software",
    icon: HubspotIcon,
  },
  {
    title: "Slack",
    description:
      "Get real time updates for your Aimfox campaigns, events and notifications directly in Slack",
    icon: SlackIcon,
  },
];
