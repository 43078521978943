import React from "react";
import { User } from "@phosphor-icons/react";

import { Lead } from "common/types";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { getProfileSubtitle } from "common/helpers/utils";
import { Button } from "common/components/ui/Button";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import ProfileImage from "common/components/ui/ProfileImage";
import LeadNotes from "common/components/LeadNotes";

export default function NotesDialog({
  lead,
  isOpen,
  onClose,
}: {
  lead: Lead;
  isOpen: boolean;
  onClose: () => void;
}) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  return (
    <Component
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <Content className="max-h-[90%]">
        <span className="mb-8 text-center text-headline-xl-bold">Notes</span>
        <div className="mb-3.5 flex gap-x-2 rounded-2xl bg-black-50 p-4">
          <ProfileImage src={lead.picture_url} />
          <div className="flex flex-col gap-y-0.5 overflow-x-hidden">
            <span className="text-body-14-bold">{lead.full_name}</span>
            <span className="truncate text-caption-12-regular text-black-500">
              {getProfileSubtitle(lead)}
            </span>
          </div>
          <Button
            variant="secondary-black"
            intent="labelIcon"
            className="ml-auto"
          >
            <User />
          </Button>
        </div>
        <LeadNotes notes={lead.notes} profileId={lead.id} />
      </Content>
    </Component>
  );
}
