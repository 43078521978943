import React from "react";

import { Button } from "common/components/ui/Button";
import useLicenseMutations from "common/datahooks/useLicenseMutations";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

interface ModifyLicenseProps {
  type: "assign" | "unassign";
  accountId: string;
  accountFullname: string;
  goBack: () => void;
  onSuccess: () => void;
}

export default function ModifyLicense({
  type,
  accountId,
  accountFullname,
  goBack,
  onSuccess,
}: ModifyLicenseProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const {
    removeLicense,
    assignLicense,
    isAssigningLicense,
    isRemovingLicense,
  } = useLicenseMutations();

  function onModify() {
    if (type === "assign") {
      assignLicense({ accountId }).then(onSuccess);
    } else {
      removeLicense({ accountId }).then(onSuccess);
    }
  }

  return (
    <>
      <h3 className="mb-2 text-center text-headline-2xl-bold md:text-headline-xl-bold">
        {type === "assign"
          ? `Assign seat to "${accountFullname}"?`
          : `Unassign seat from "${accountFullname}"?`}
      </h3>
      <p className="mb-12 text-center text-black-500">
        {type === "assign"
          ? "You will get full access to LinkedIn Outreach and campaigns"
          : "This will stop all campaigns on this LinkedIn account. You can re-assign this seat at any time"}
      </p>
      <div className="flex flex-col gap-2 md:flex-row-reverse md:gap-4">
        <Button
          isLoading={isAssigningLicense || isRemovingLicense}
          onClick={onModify}
          variant={type === "assign" ? "gradient" : "tertiary-danger"}
          size={isTabletOrDesktop ? "md" : "lg"}
          className="flex-1"
        >
          {type === "assign" ? "Assign" : "Unassign"}
        </Button>
        <Button
          onClick={goBack}
          variant="secondary-black"
          disabled={isAssigningLicense || isRemovingLicense}
          className="flex-1"
          size={isTabletOrDesktop ? "md" : "lg"}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}
