import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import getQueryKeys from "common/datahooks/getQueryKeys";
import { useCampaignContext } from "common/helpers/CampaignContext";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import useUpdateCampaign from "../../../datahooks/useUpdateCampaign";
import useAudienceToken from "../../../datahooks/useAudienceToken";
import { AudienceProfile } from "../../../types";

const wsBaseUrl = import.meta.env.VITE_CORE_WS_URL;

export default function useAudienceProgress() {
  const [socket, setSocket] = useState(null);
  const { audienceToken } = useAudienceToken();
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { campaignId } = useCampaignContext();

  const queryClient = useQueryClient();
  const { campaignsKeys } = getQueryKeys(workspaceId);
  const { updateCachedCampaign } = useUpdateCampaign();

  const url = `${wsBaseUrl}campaigns/${campaignId}/audience?authorization=${audienceToken}`;

  const connect = () => {
    const websocket = new WebSocket(url);

    websocket.onmessage = (event) => {
      const message = JSON.parse(event.data);

      switch (message.type) {
        case "people":
          if (queryClient.getQueryData(campaignsKeys.audience(campaignId))) {
            queryClient.setQueryData(
              campaignsKeys.audience(campaignId),
              (prevAudience: AudienceProfile[]) => {
                const urnSet = new Set(prevAudience.map(({ urn }) => urn));
                const newData = message.data.filter(
                  (newTarget: AudienceProfile) => !urnSet.has(newTarget.urn),
                );
                const newTargets = [...prevAudience, ...newData];

                updateCachedCampaign(campaignId, (draftCampaign) => {
                  draftCampaign.target_count = newTargets.length;
                });

                return newTargets;
              },
            );
          }
          break;
        case "status":
          queryClient.setQueryData(campaignsKeys.audienceStats(campaignId), {
            progress: message.progress,
            blocked: message.blocked,
            connectOptimization: message.connectOptimization,
            headless: message.headless,
            inmailOptimization: message.inmailOptimization,
            locked: message.locked,
            valid: message.valid,
          });
          break;
        default:
          break;
      }
    };

    setSocket(websocket);
  };

  const reconnectSocket = () => {
    if (socket) {
      socket.close();
    }
    connect();
  };

  useEffect(() => {
    if (audienceToken) {
      connect();
    }

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [audienceToken]);

  return { reconnectSocket };
}
