import React from "react";
import {
  Check,
  EnvelopeSimpleOpen,
  UserPlus,
  UsersThree,
  type Icon,
} from "@phosphor-icons/react";

import useMetrics from "common/datahooks/useMetrics";
import { Metrics, MetricsParameters } from "common/types";
import { Button } from "common/components/ui/Button";
import Skeleton from "common/components/ui/Skeleton";

import { CampaignFlow, CampaignFlowType } from "../../../../types";

function getMetricsList(flowType: CampaignFlowType) {
  const itemsSentTitle: Record<
    CampaignFlowType,
    {
      sentTitle: string;
      successTitle?: string;
    }
  > = {
    CONNECT_OPTIMIZATION: { sentTitle: "Invites", successTitle: "Acceptance" },
    INMAIL_OPTIMIZATION: { sentTitle: "InMails", successTitle: "Reply" },
    PRIMARY_CONNECT: { sentTitle: "Invites", successTitle: "Acceptance" },
    PRIMARY_MESSAGE: { sentTitle: "Message requests", successTitle: "Reply" },
  };

  const metricsList: {
    icon: Icon;
    label: string;
    getValue: (metricsSum: Metrics) => string | number;
  }[] = [
    {
      icon: UsersThree,
      label: "Leads gained",
      getValue: (metricsSum) => {
        if (
          flowType === "INMAIL_OPTIMIZATION" ||
          flowType === "PRIMARY_MESSAGE"
        ) {
          return metricsSum.replies;
        }

        return metricsSum.accepted_connections;
      },
    },
    {
      icon: flowType === "PRIMARY_CONNECT" ? UserPlus : EnvelopeSimpleOpen,
      label: `${itemsSentTitle[flowType].sentTitle} sent`,
      getValue: (metricsSum) => {
        if (flowType === "INMAIL_OPTIMIZATION") {
          return metricsSum.sent_inmails;
        }

        if (flowType === "PRIMARY_MESSAGE") {
          return metricsSum.message_requests;
        }

        return metricsSum.sent_connections;
      },
    },

    {
      icon: Check,
      label: `${itemsSentTitle[flowType].successTitle} rate`,
      getValue: (metricsSum: Metrics) => {
        let actionsSent = 0;
        let successCount = 0;

        if (flowType === "INMAIL_OPTIMIZATION") {
          actionsSent = metricsSum.sent_inmails;
          successCount = metricsSum.replies;
        } else if (flowType === "PRIMARY_MESSAGE") {
          actionsSent = metricsSum.message_requests;
          successCount = metricsSum.replies;
        } else {
          actionsSent = metricsSum.sent_connections;
          successCount = metricsSum.accepted_connections;
        }

        return `${successCount} (${
          actionsSent ? Math.round((successCount / actionsSent) * 100) : 0
        }%)`;
      },
    },
  ];

  return metricsList;
}

interface OverviewCardProps {
  flow: CampaignFlow;
  metricsParams: MetricsParameters;
}

export default function OverviewCard({
  flow,
  metricsParams,
}: OverviewCardProps) {
  const { metricsSum, isLoadingMetrics } = useMetrics({
    parameters: metricsParams,
    flowId: flow.id,
    accountIds: [],
  });

  return getMetricsList(flow.type).map(({ icon: Icon, label, getValue }) => (
    <div
      key={label}
      className="flex items-center gap-4 border-b border-black-200 py-4"
    >
      <Button intent="labelIcon" variant="secondary-black">
        <Icon />
      </Button>

      <div className="flex flex-col gap-0.5">
        {isLoadingMetrics ? (
          <>
            <Skeleton className="h-4 w-24" />
            <Skeleton className="h-7 w-12" />
          </>
        ) : (
          <>
            <h5 className="text-caption-12-regular">{label}</h5>
            <span className="text-headline-xl-bold">
              {getValue(metricsSum)}
            </span>
          </>
        )}
      </div>

      {/* Total value */}
      <p className="ml-auto text-blue-500">Total {getValue(flow.metrics)}</p>
    </div>
  ));
}
