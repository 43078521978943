import React, { useState } from "react";
import { PlusCircle } from "@phosphor-icons/react";

import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Button } from "common/components/ui/Button";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "common/components/ui/Tabs";
import { LinkedInProfile } from "common/types";

import AccountsSearch from "./AccountsSearch";
import useBlacklistMutations from "../../datahooks/useBlacklistMutations";
import CsvUpload from "./CsvUpload";
import BlacklistAccountItem from "./BlacklistAccountItem";

import EmptyStateImage from "assets/images/empty-placeholders/blacklist.png";

export default function AddAccountDialog() {
  const isDesktop = useTwBreakpoint("lg");
  const { addToBlacklist, isAddingToBlacklist } = useBlacklistMutations();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("0");
  const [failedUrls, setFailedUrls] = useState(null);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  function onGetProfiles(profiles: LinkedInProfile[], failed: string[]) {
    setSelectedAccounts(profiles);
    setFailedUrls(
      failed.map((identifier) => `https://www.linkedin.com/in/${identifier}`),
    );
    setSelectedTab("0");
  }
  function addAccounts() {
    addToBlacklist({ profiles: selectedAccounts }).then(() => {
      setSelectedAccounts([]);
      setIsOpen(false);
    });
  }
  function removeAccount(accountId: string) {
    const filteredAccounts = selectedAccounts.filter(
      ({ id }) => id !== accountId,
    );
    setSelectedAccounts(filteredAccounts);
    if (!filteredAccounts.length) {
      setFailedUrls(null);
    }
  }
  function onBack() {
    setSelectedAccounts([]);
    setFailedUrls(null);
    setSelectedTab("1");
  }
  const profiles = (
    <div className="grid grid-cols-1 gap-4 overflow-y-auto max-lg:mb-4 lg:grid-cols-2">
      {selectedAccounts.map((account) => (
        <BlacklistAccountItem
          key={account.id}
          profile={account}
          onRemove={() => removeAccount(account.id)}
          isLoading={false}
        />
      ))}
    </div>
  );

  const Component = isDesktop ? Dialog : Drawer;
  const Content = isDesktop ? DialogContent : DrawerContent;

  return (
    <>
      <Button
        leftIcon={<PlusCircle weight="fill" />}
        onClick={() => setIsOpen(true)}
        data-cy="blacklist-button"
      >
        Add account
      </Button>
      <Component
        open={isOpen}
        onOpenChange={(open) => {
          if (!open) {
            setSelectedAccounts([]);
            setFailedUrls(null);
            setSelectedTab("0");
            setIsOpen(false);
          }
        }}
      >
        <Content className="lg:h-[566px] lg:w-[1000px] lg:p-0">
          <h3 className="mb-4 overflow-hidden text-headline-2xl-bold max-lg:text-center lg:mx-6 lg:my-4">
            {failedUrls ? "Review blacklist upload" : "Blacklist accounts"}
          </h3>
          <div className="flex flex-1 flex-col overflow-hidden border-t-black-200 lg:border-t lg:px-6 lg:py-4">
            <Tabs
              value={selectedTab}
              onValueChange={setSelectedTab}
              className="-m-0.5 flex flex-1 flex-col overflow-hidden p-0.5"
            >
              <TabsList className="mb-4 pb-2">
                <TabsTrigger value="0" data-cy="tab-search">
                  {failedUrls
                    ? `Success (${selectedAccounts.length})`
                    : "Search"}
                </TabsTrigger>
                {(!failedUrls || !!failedUrls.length) && (
                  <TabsTrigger value="1" data-cy="tab-csv">
                    {failedUrls
                      ? `Failed (${failedUrls.length})`
                      : "Upload .csv file"}
                  </TabsTrigger>
                )}
              </TabsList>
              <TabsContent
                value="0"
                className="-m-0.5 flex flex-col overflow-y-hidden p-0.5 data-[state=active]:flex-1"
              >
                {!failedUrls && (
                  <AccountsSearch
                    selectedAccounts={selectedAccounts}
                    setSelectedAccounts={setSelectedAccounts}
                  />
                )}
                {selectedAccounts.length ? (
                  profiles
                ) : (
                  <div className="mx-auto flex max-w-72 grow flex-col items-center justify-center gap-4 max-lg:my-4 lg:max-w-96">
                    <img alt="" src={EmptyStateImage} width={200} />
                    <h5 className="text-center text-headline-2xl-bold">
                      No accounts to blacklist
                    </h5>
                    <p className="-mt-2 text-center text-black-500">
                      Search accounts you want to add to your blacklist. Once
                      you are satisfied with your selection, click &quot;Add to
                      blacklist&quot; to blacklist them
                    </p>
                  </div>
                )}
              </TabsContent>
              <TabsContent
                value="1"
                className="flex flex-col data-[state=active]:flex-1"
              >
                <CsvUpload
                  failedUrls={failedUrls}
                  onGetProfiles={onGetProfiles}
                />
              </TabsContent>
            </Tabs>
          </div>
          {(selectedTab === "0" ||
            !!selectedAccounts.length ||
            !!failedUrls) && (
            <div className="flex border-t border-t-black-200 px-6 pt-4 lg:py-4">
              {failedUrls && (
                <Button
                  onClick={onBack}
                  size={isDesktop ? "md" : "lg"}
                  variant="secondary-black"
                  data-cy="csv-next-button"
                >
                  Back
                </Button>
              )}
              <Button
                className="ml-auto"
                size={isDesktop ? "md" : "lg"}
                disabled={!selectedAccounts.length}
                onClick={addAccounts}
                isLoading={isAddingToBlacklist}
                data-cy="add-to-blacklist-button"
              >
                Add to blacklist
              </Button>
            </div>
          )}
        </Content>
      </Component>
    </>
  );
}
