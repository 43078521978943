import React, { useState } from "react";
import { CreditCard, LinkedinLogo } from "@phosphor-icons/react";
import { Link, useLocation } from "react-router-dom";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import usePrimaryPaymentSource from "common/datahooks/usePrimaryPaymentSource";
import { Button } from "common/components/ui/Button";
import useBannerVisibility from "common/hooks/useBannerVisibility";
import Licenses from "common/components/Licenses";
import { PaymentMethodModal } from "common/components/PaymentMethod";
import { isLifetimeDeal } from "common/helpers/utils";
import { LinkedInLoginModal } from "common/components/LinkedInLogin";

import AlertBanner from "./AlertBanner";

function StartTrialBanner() {
  const { isVisible, setIsVisible } = useBannerVisibility("startTrialBanner");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  if (isVisible) {
    return (
      <>
        <AlertBanner
          variant="trial"
          title="Try LinkedIn Outreach"
          subtitle="Start your 14-day free trial to unlock campaigns and grow your LinkedIn on autopilot"
          icon={<CreditCard />}
          buttonComponent={
            <Button onClick={() => setIsDialogOpen(true)}>
              Start free trial
            </Button>
          }
          onClose={() => setIsVisible(false)}
        />
        <Licenses isOpen={isDialogOpen} setIsOpen={setIsDialogOpen} />
      </>
    );
  }
}
function ConnectAccountBanner() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <AlertBanner
        variant="default"
        title="Connect a LinkedIn account"
        subtitle="Add your LinkedIn account to start using Aimfox"
        icon={<LinkedinLogo />}
        buttonComponent={
          <Button onClick={() => setIsDialogOpen(true)}>
            Add LinkedIn account
          </Button>
        }
      />
      <LinkedInLoginModal
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </>
  );
}
function AccountDisconnectedBanner() {
  const { isVisible, setIsVisible } = useBannerVisibility(
    "accountDisconnectedBanner",
  );

  if (!isVisible) {
    return;
  }

  return (
    <AlertBanner
      variant="danger"
      title="Account disconnected"
      subtitle="Reconnect your LinkedIn account to continue using Aimfox"
      icon={<LinkedinLogo weight="fill" />}
      onClose={() => setIsVisible(false)}
      buttonComponent={
        <Button asChild>
          <Link to="./accounts">Go to accounts</Link>
        </Button>
      }
    />
  );
}
function SubscriptionExpiredBanner() {
  const { isVisible, setIsVisible } = useBannerVisibility(
    "subscriptionExpiredBanner",
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  if (isVisible) {
    return (
      <>
        <AlertBanner
          title="Subscription Expired"
          subtitle="You are currently on the CRM free plan. Upgrade to unlock Outreach and unlimited campaigns"
          variant="danger"
          icon={<CreditCard />}
          onClose={() => setIsVisible(false)}
          buttonComponent={
            <Button onClick={() => setIsDialogOpen(true)}>
              Reactivate subscription
            </Button>
          }
        />
        <Licenses isOpen={isDialogOpen} setIsOpen={setIsDialogOpen} />
      </>
    );
  }
}
function PaymentMethodBanner() {
  const { isVisible, setIsVisible } = useBannerVisibility(
    "paymentMethodBanner",
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  if (isVisible) {
    return (
      <>
        <AlertBanner
          variant="default"
          title="No payment method"
          subtitle="Add a payment method to avoid subscription downtime on your next billing date"
          icon={<CreditCard />}
          buttonComponent={
            <Button onClick={() => setIsDialogOpen(true)}>
              Update payment method
            </Button>
          }
          onClose={() => setIsVisible(false)}
        />
        <PaymentMethodModal
          isOpen={isDialogOpen}
          setIsOpen={setIsDialogOpen}
          onBack={() => setIsDialogOpen(false)}
          paymentSource={null} // This banner is displayed only when the users don't have primary payment source
        />
      </>
    );
  }
}
export default function Banners() {
  const { pathname } = useLocation();
  const { subscription, accounts, licenses } = useSelectedWorkspaceContext();
  const { primaryPaymentSource, isLoadingPrimaryPaymentSource } =
    usePrimaryPaymentSource();

  const isAccountsPage = pathname.endsWith("accounts");

  if (!subscription && !isLifetimeDeal(licenses)) {
    return <StartTrialBanner />;
  }
  if (!accounts.length) {
    return <ConnectAccountBanner />;
  }
  if (accounts.some(({ state }) => state !== "LoggedIn") && !isAccountsPage) {
    return <AccountDisconnectedBanner />;
  }
  if (subscription?.status === "cancelled" && !isLifetimeDeal(licenses)) {
    return <SubscriptionExpiredBanner />;
  }
  if (
    !isLoadingPrimaryPaymentSource &&
    !primaryPaymentSource &&
    !isLifetimeDeal(licenses)
  ) {
    return <PaymentMethodBanner />;
  }
}
