import React from "react";

import { Button } from "common/components/ui/Button";
import { HTTPError } from "common/helpers/HTTP";

import AppSumoErrorImage from "assets/images/appsumo-error.png";

interface AppSumoErrorProps {
  error: Error;
  onClose: () => void;
}

export default function AppSumoError({ error, onClose }: AppSumoErrorProps) {
  const isLicenseAlreadyUsed =
    error instanceof HTTPError && error.type === "LicenseAlreadyUsed";

  const isLicenseDeactivated =
    error instanceof HTTPError &&
    error.type === "CustomerHasDeactivatedLicense";

  let errorMessage: React.ReactNode =
    "We were not able to activate this plan. This plan may already be redeemed on another Aimfox account. Please contact support if you think this is a mistake";
  if (isLicenseAlreadyUsed) {
    errorMessage = (
      <>
        This AppSumo license has already been redeemed by{" "}
        <span className="text-body-14-bold text-black-950">
          {error.serverMessage}
        </span>
        . Please contact support if you think this is a mistake
      </>
    );
  } else if (isLicenseDeactivated) {
    errorMessage =
      "This Aimfox account has already activated an AppSumo plan, and you cannot activate a new one. You can either reactivate your old AppSumo plan, or create a new Aimfox account to use your new AppSumo plan";
  }

  return (
    <>
      <div className="-mx-4 -mt-14 mb-4">
        <img src={AppSumoErrorImage} alt="" height={308} width={540} />
      </div>

      <h3 className="mb-2 text-center text-headline-2xl-bold">
        Failed to activate AppSumo plan
      </h3>
      <p className="mb-12 text-center text-black-500">{errorMessage}</p>

      <Button size="lg" className="md:ml-auto" onClick={onClose}>
        Close
      </Button>
    </>
  );
}
