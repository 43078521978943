import { getMonthName, typedObjectEntries } from "common/helpers/utils";

import { LeadsSearchParams, SelectedFacetParam } from "./types";

export function formatMonthAndYear(data: { month: number; year: number }) {
  const { month, year } = data;
  const monthName = getMonthName(month - 1);
  if (monthName) {
    return `${monthName} ${year}`;
  }
  return year.toString();
}
export function formatRange(from: string, to: string) {
  return [from, to].filter((value) => !!value).join(" - ");
}
// returns parsed search params for server requests
export function parseSearchParams(
  searchParams: LeadsSearchParams<SelectedFacetParam>,
) {
  return typedObjectEntries(searchParams).reduce(
    (accumulator, [key, value]) => {
      if (key === "keywords") {
        accumulator[key] = value;
      } else if (key === "favorite") {
        accumulator[key] = value;
      } else {
        accumulator[key] = value.map(({ id }) => id).flat();
      }
      return accumulator;
    },
    {} as LeadsSearchParams<string>,
  );
}
