import React, { useState } from "react";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { Button } from "common/components/ui/Button";
import ProfileImage from "common/components/ui/ProfileImage";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import useWorkspaceNavigate from "common/hooks/useWorkspaceNavigate";
import { Lead } from "common/types";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import RadioButton from "common/components/ui/RadioButton";

export default function MessageLeadDialog({
  lead,
  isOpen,
  onClose,
}: {
  lead: Lead;
  isOpen: boolean;
  onClose: () => void;
}) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { accounts } = useSelectedWorkspaceContext();
  const { relativeNavigate } = useWorkspaceNavigate();
  const [selectedAccountId, setSelectedAccountId] = useState<string>(null);

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  return (
    <Component
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <Content className="md:max-h-[80%]">
        <h3 className="mb-2 text-center text-headline-2xl-bold md:text-headline-xl-bold">
          Choose sender
        </h3>

        <p className="mb-8 text-center text-black-500">
          Choose from which account you want to message this lead
        </p>

        <div className="mb-4 flex flex-col gap-y-2.5 overflow-y-auto md:gap-y-4">
          {lead.lead_of.map((leadOfId) => {
            const {
              id,
              picture_url: pictureUrl,
              full_name: fullName,
              occupation,
            } = accounts.find((account) => account.id === leadOfId);

            return (
              <button
                key={id}
                className="flex items-center gap-x-2 rounded-2xl border border-black-200 p-3"
                type="button"
                onClick={() => setSelectedAccountId(id)}
              >
                <ProfileImage src={pictureUrl} size="lg" />

                <div className="flex flex-col gap-y-1 text-left">
                  <span className="text-body-16-bold">{fullName}</span>
                  <span className="line-clamp-1 text-caption-12-regular text-black-500">
                    {occupation}
                  </span>
                </div>

                <RadioButton
                  checked={selectedAccountId === id}
                  isStyleOnly
                  className="ml-auto"
                />
              </button>
            );
          })}
        </div>

        <div className="flex gap-x-4">
          <Button
            variant="secondary-black"
            className="flex-1"
            size={isTabletOrDesktop ? "md" : "lg"}
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            className="flex-1"
            variant="primary-black"
            size={isTabletOrDesktop ? "md" : "lg"}
            disabled={!selectedAccountId}
            onClick={() =>
              relativeNavigate("inbox", { state: { lead, selectedAccountId } })
            }
          >
            Go to chat
          </Button>
        </div>
      </Content>
    </Component>
  );
}
