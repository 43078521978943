import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import { useCampaignContext } from "common/helpers/CampaignContext";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { LinkedinPost } from "../types";

async function getPostDetails(
  workspaceId: string,
  campaignId: string,
  postId: string,
): Promise<LinkedinPost> {
  return (
    await get(
      `workspaces/${workspaceId}/campaigns/${campaignId}/posts/${postId}`,
    )
  ).post;
}

export default function useLinkedinPost({ postId }: { postId: string }) {
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { campaignId } = useCampaignContext();

  const {
    data: linkedinPost,
    isLoading: isLoadingLinkedinPost,
    isError: isLinkedinPostError,
  } = useQuery({
    queryKey: ["posts", postId],
    queryFn: () => getPostDetails(workspaceId, campaignId, postId),
  });

  return {
    linkedinPost,
    isLinkedinPostError,
    isLoadingLinkedinPost,
  };
}
