import React, { JSX, ReactNode } from "react";
import { clsx } from "clsx";

import useAppStore from "common/hooks/useAppStore";
import { NavItemType } from "common/types";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import TrialBanner from "common/layouts/WorkspaceLayout/TrialBanner";

import MobileHeader from "./MobileHeader";

interface MobileLayoutProps {
  navItems: NavItemType[];
  children: ReactNode;
  headerTitle: string;
  bannerComponent: JSX.Element;
}
export default function MobileLayout({
  navItems,
  children,
  headerTitle,
  bannerComponent,
}: MobileLayoutProps) {
  const { subscription } = useSelectedWorkspaceContext();
  const isPageHeightLimited = useAppStore((state) => state.isPageHeightLimited);
  const headerContent = useAppStore((state) => state.headerContent);

  return (
    <div
      className={clsx(
        "flex min-h-screen flex-col",
        isPageHeightLimited && "100vh",
      )}
    >
      <MobileHeader navItems={navItems} />
      <div className="flex grow flex-col p-4">
        {subscription && subscription.status === "in_trial" && <TrialBanner />}
        {bannerComponent}
        <div className="mb-4 flex items-center justify-between">
          <div className="flex flex-col gap-y-1">
            <h1 className="text-headline-xl-bold">{headerTitle}</h1>
            {headerContent?.subtitle && (
              <p className="text-caption-12-regular">
                {headerContent.subtitle}
              </p>
            )}
          </div>
          {headerContent?.actionComponent}
        </div>
        {children}
      </div>
    </div>
  );
}
