import React, { useState } from "react";

import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import { Button } from "common/components/ui/Button";

import { Webhook } from "../../types";
import DeleteWebhook from "./DeleteWebhook";
import WebhookForm from "./WebhookForm";

type Action = "preview" | "edit" | "delete";

interface WebhookMobilePreviewProps {
  webhook: Webhook;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

export default function WebhookMobilePreview({
  webhook,
  isOpen,
  setIsOpen,
}: WebhookMobilePreviewProps) {
  const [action, setAction] = useState<Action>("preview");

  let mainComponent;

  if (action === "preview") {
    mainComponent = (
      <>
        <h2 className="mb-8 break-words text-center text-headline-2xl-bold">
          {webhook.name}
        </h2>
        <section className="mb-8">
          <div className="grid grid-cols-2 gap-x-4 border-b border-b-black-200 pb-4">
            <h5 className="text-black-500">Link</h5>
            <p className="text-button-14">{webhook.url}</p>
          </div>

          <div className="grid grid-cols-2 gap-x-4 pt-4">
            <h5 className="text-black-500">Events</h5>
            <div>
              {webhook.events.map((eventType) => (
                <p className="mb-4 text-button-14 last:mb-0" key={eventType}>
                  {eventType}
                </p>
              ))}
            </div>
          </div>
        </section>

        <Button className="mb-2" onClick={() => setAction("edit")} size="lg">
          Edit
        </Button>
        <Button
          variant="tertiary-danger"
          onClick={() => setAction("delete")}
          size="lg"
        >
          Delete
        </Button>
      </>
    );
  } else if (action === "delete") {
    mainComponent = (
      <DeleteWebhook
        webhook={webhook}
        onBack={() => setAction("preview")}
        onSuccess={() => setIsOpen(false)}
      />
    );
  } else if (action === "edit") {
    mainComponent = (
      <WebhookForm webhook={webhook} onBack={() => setAction("preview")} />
    );
  }

  return (
    <Drawer
      open={isOpen}
      onOpenChange={setIsOpen}
      onAfterClose={() => setAction("preview")}
    >
      <DrawerContent>{mainComponent}</DrawerContent>
    </Drawer>
  );
}
