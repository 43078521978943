import React, { forwardRef } from "react";
import { MagnifyingGlass, X, CircleNotch } from "@phosphor-icons/react";

import { Input, type InputProps } from "common/components/ui/Input";

import RenderIf from "../RenderIf";

interface SearchInputProps extends Omit<InputProps, "leftComponent"> {
  onClear: () => void;
  isLoading?: boolean;
}

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ onClear, rightComponent, value, isLoading = false, ...rest }, ref) => (
    <Input
      {...rest}
      value={value}
      ref={ref}
      leftComponent={<MagnifyingGlass className="fill-black-400" />}
      rightComponent={
        <>
          {rightComponent}

          <RenderIf condition={isLoading}>
            <CircleNotch className="animate-spin fill-black-400" />
          </RenderIf>

          <RenderIf condition={!isLoading && !!value}>
            <button
              type="button"
              aria-label="Clear input"
              onClick={onClear}
              className="text-black-400 transition-colors hover:text-black-950"
            >
              <X />
            </button>
          </RenderIf>
        </>
      }
    />
  ),
);
SearchInput.displayName = "SearchInput";

SearchInput.defaultProps = {
  isLoading: false,
};

export default SearchInput;
