import React from "react";
import { Outlet } from "react-router-dom";

import ToastContainer from "common/components/ui/ToastContainer";

import Header from "./Header";

export default function BaseLayout() {
  return (
    <>
      <Header />
      <main className="mx-auto flex min-h-[calc(100dvh_-_80px)] w-full max-w-[1920px] grow flex-col overflow-x-hidden px-4 py-6 lg:min-h-[calc(100dvh_-_88px)] lg:px-20 lg:py-12">
        <ToastContainer />
        <Outlet />
      </main>
    </>
  );
}
