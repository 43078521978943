import React, { useState } from "react";

import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import { Button } from "common/components/ui/Button";
import { Label } from "common/types";

import DeleteLabel from "./DeleteLabel";
import LabelForm from "./LabelForm";

interface LabelDrawerProps {
  label?: Label;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}
export default function LabelDrawer({
  label = null,
  isOpen,
  setIsOpen,
}: LabelDrawerProps) {
  const [isDeleting, setIsDeleting] = useState(false);

  return (
    <Drawer open={isOpen} onOpenChange={setIsOpen}>
      <DrawerContent>
        {isDeleting ? (
          <DeleteLabel labelId={label.id} onBack={() => setIsDeleting(false)} />
        ) : (
          <>
            <LabelForm label={label} onBack={() => setIsOpen(false)} />

            {label && (
              <Button
                size="lg"
                variant="tertiary-danger"
                className="mt-2"
                onClick={() => setIsDeleting(true)}
              >
                Delete
              </Button>
            )}
          </>
        )}
      </DrawerContent>
    </Drawer>
  );
}
