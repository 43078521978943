import React from "react";

import { Button } from "common/components/ui/Button";
import useWorkspaceAccountMutations from "common/datahooks/useWorkspaceAccountMutations";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

interface DeleteAccountProps {
  accountId: string;
  accountFullname: string;
  goBack: () => void;
  onSuccess: () => void;
}

export default function DeleteAccount({
  accountId,
  accountFullname,
  goBack,
  onSuccess,
}: DeleteAccountProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const { deleteAccount, isDeletingAccount } = useWorkspaceAccountMutations();

  function onClick() {
    deleteAccount({ accountId }).then(onSuccess);
  }

  return (
    <>
      <h3 className="mb-2 text-center text-headline-2xl-bold md:text-headline-xl-bold">
        {`Delete "${accountFullname}"?`}
      </h3>
      <p className="mb-12 text-center text-black-500">
        This will permanently remove this LinkedIn account from your workspace,
        and you will lose access to all of their leads, campaigns and
        conversations
      </p>
      <div className="flex flex-col-reverse gap-2 md:flex-row md:gap-4">
        <Button
          onClick={goBack}
          variant="secondary-black"
          disabled={isDeletingAccount}
          className="flex-1"
          size={isTabletOrDesktop ? "md" : "lg"}
        >
          Cancel
        </Button>
        <Button
          isLoading={isDeletingAccount}
          onClick={onClick}
          variant="tertiary-danger"
          size={isTabletOrDesktop ? "md" : "lg"}
          className="flex-1"
        >
          Delete account
        </Button>
      </div>
    </>
  );
}
