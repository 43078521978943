import React from "react";
import { ArrowsClockwise } from "@phosphor-icons/react";

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import ProfileImage from "common/components/ui/ProfileImage";

export default function SyncingBadge({ accountIds }: { accountIds: string[] }) {
  const { accounts } = useSelectedWorkspaceContext();
  const filteredAccounts = accounts.filter(({ id }) => accountIds.includes(id));

  return (
    <Tooltip>
      <TooltipTrigger className="rounded-full bg-purple-600 p-0.5">
        <ArrowsClockwise
          size={14}
          className="animate-spin fill-white"
          weight="bold"
        />
      </TooltipTrigger>
      <TooltipContent align="start" className="flex flex-col gap-y-1.5">
        <p>
          Your leads are currently synchronizing with LinkedIn, for the
          following accounts:
        </p>
        {filteredAccounts.map(({ id, full_name, picture_url }) => (
          <div key={id} className="flex items-center gap-x-1">
            <ProfileImage
              src={picture_url}
              size="3xs"
              className="rounded-full"
            />
            <span className="text-caption-10-semibold">{full_name}</span>
          </div>
        ))}
      </TooltipContent>
    </Tooltip>
  );
}
