import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { Button } from "common/components/ui/Button";
import usePromotionsMutations from "common/datahooks/usePromotionsMutations";
import useWorkspaceNavigate from "common/hooks/useWorkspaceNavigate";
import Promotion from "common/components/Promotion";
import usePromotions from "common/datahooks/usePromotions";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import AppSumoError from "./AppSumoError";

import AppSumoImage from "assets/images/appsumo.png";

export default function AppSumoDialog() {
  const [stepId, setStepId] = useState<"default" | "manage">("default");
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { relativeNavigate } = useWorkspaceNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get("code");

  const { appSumoPromotion } = usePromotions();

  const {
    isCreatingAppSumoPromotion,
    createAppSumoPromotion,
    createAppSumoPromotionError,
  } = usePromotionsMutations();

  useEffect(() => {
    if (code) {
      createAppSumoPromotion({ code });
    }
  }, [code]);

  function closeDialog() {
    setSearchParams((prevParams) => {
      const params = new URLSearchParams(prevParams);
      params.delete("code");
      return params;
    });
  }

  let mainContent;
  if (createAppSumoPromotionError) {
    mainContent = (
      <AppSumoError error={createAppSumoPromotionError} onClose={closeDialog} />
    );
  } else if (appSumoPromotion && stepId === "manage") {
    mainContent = (
      <Promotion
        promotion={appSumoPromotion}
        onClose={closeDialog}
        workspaceId={workspaceId}
        closeText="Later"
      />
    );
  } else {
    mainContent = (
      <>
        <div className="-mx-4 -mt-14 mb-4">
          <img src={AppSumoImage} alt="" height={308} width={540} />
        </div>

        <h3 className="mb-2 text-center text-headline-2xl-bold">
          {isCreatingAppSumoPromotion
            ? "Activating your AppSumo plan"
            : "Welcome to Aimfox, Sumo-ling! 👋"}
        </h3>
        <p className="text-center text-black-500">
          {isCreatingAppSumoPromotion ? (
            "We are activating your lifetime seats and preparing the dashboard. This may take a second"
          ) : (
            <>
              As an AppSumo user, you have been granted access to a number of{" "}
              <span className="text-body-14-bold text-black-950">
                Lifetime Seats
              </span>
              . These seats are now active on your Aimfox account, and must be
              added to a workspace before you can use them
            </>
          )}
        </p>

        {isCreatingAppSumoPromotion ? (
          <Button
            size="lg"
            className="mt-12 md:ml-auto"
            onClick={closeDialog}
            isLoading
          >
            Activating
          </Button>
        ) : (
          <>
            <p className="mb-2 mt-4 text-center text-black-500">
              How to use your Lifetime Deal:
            </p>

            <ol className="mb-12 list-inside list-decimal text-black-500">
              <li>
                Add seats to your workspace in Workspace{" "}
                <span className="text-body-14-bold text-black-950">
                  Settings {">"} Overview
                </span>
              </li>
              <li>Seats can be added to any of your owned workspaces</li>
              <li>
                After adding seats to your workspace, you can assign them to
                your LinkedIn accounts to run campaigns
              </li>
              <li>
                You can reset your seats in the{" "}
                <span className="text-body-14-bold text-black-950">
                  “All Workspaces”
                </span>{" "}
                Page
              </li>
            </ol>

            <div className="flex flex-col gap-2 md:flex-row-reverse md:gap-4">
              <Button
                className="flex-1"
                size="lg"
                onClick={() => {
                  setStepId("manage");
                  relativeNavigate(`settings?code=${code}`);
                }}
              >
                Add seats
              </Button>
              <Button
                className="flex-1"
                size="lg"
                variant="secondary-black"
                onClick={closeDialog}
              >
                I&apos;ll do it later
              </Button>
            </div>
          </>
        )}
      </>
    );
  }

  return (
    <Dialog open={!!code}>
      <DialogContent className="max-h-[90dvh] max-w-[540px] overflow-y-auto scrollbar-thin [&>#close-button]:hidden">
        {mainContent}
      </DialogContent>
    </Dialog>
  );
}
