import React from "react";
import { clsx } from "clsx";
import { Link } from "react-router-dom";

import { NavItemType } from "common/types";

import NavItem from "../../NavItem";
import ExpandCollapseIcon from "../../ExpandCollapseIcon";
import ProfileButton from "./ProfileButton";

import AimfoxLogoOrange from "assets/aimfoxLogos/aimfox-logo-orange.svg?react";

interface DesktopNavProps {
  navItems: NavItemType[];
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}

export default function DesktopNav({
  navItems,
  isExpanded,
  setIsExpanded,
}: DesktopNavProps) {
  return (
    <nav
      className={clsx(
        "fixed z-nav flex h-full flex-col rounded-tr-[32px] bg-black-950 px-4 py-6 transition-[width] duration-500",
        isExpanded ? "w-[266px]" : "w-[72px]",
      )}
    >
      <Link
        to="home"
        className={clsx(
          "mb-8 flex items-end self-start overflow-hidden",
          !isExpanded && "w-10",
        )}
      >
        <AimfoxLogoOrange className="w-10" />
        <span
          className={clsx(
            "mb-0.5 text-headline-2xl-bold text-white transition-opacity duration-500",
            isExpanded ? "opacity-100" : "opacity-0",
          )}
        >
          Aimfox
        </span>
      </Link>
      <div className="flex flex-col gap-y-3">
        {navItems.map((navItem) => (
          <NavItem key={navItem.path} isExpanded={isExpanded} {...navItem} />
        ))}
      </div>
      <div className="mt-auto flex h-[122px] flex-col justify-start">
        <NavItem
          label={isExpanded ? "Collapse" : "Expand"}
          isExpanded={isExpanded}
          renderIcon={() => <ExpandCollapseIcon isExpanded={isExpanded} />}
          onClick={() => setIsExpanded(!isExpanded)}
        />
        <ProfileButton isExpanded={isExpanded} />
      </div>
    </nav>
  );
}
