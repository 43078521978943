import React from "react";
import clsx from "clsx";
import { GearSix } from "@phosphor-icons/react";

import { Promotion } from "common/types";

interface PromotionButtonProps {
  type: Promotion["type"];
  onClick: () => void;
}

export default function PromotionButton({
  type,
  onClick,
}: PromotionButtonProps) {
  return (
    <button
      type="button"
      className={clsx(
        "flex w-full items-center gap-x-4 rounded-2xl p-4 lg:w-48",
        type === "appsumo" ? "bg-appsumoYellow" : "bg-black-950 text-white",
      )}
      onClick={onClick}
    >
      <div className="flex flex-col gap-y-0.5">
        <span className="text-left">
          {type === "appsumo" ? "AppSumo" : "Lifetime deal"}
        </span>
        <p className="text-left text-body-16-bold">Manage Seats</p>
      </div>
      <GearSix size={20} className="ml-auto" />
    </button>
  );
}
