import React from "react";

import useCommunicationSettings from "common/datahooks/useCommunicationSettings";
import Alert from "common/components/ui/Alert";
import { CommunicationSettings } from "common/types";
import usePushService from "common/hooks/usePushService";

import SettingsItemContainer from "../SettingsItemContainer";
import CommunicationItem from "./CommunicationItem";
import CommunicationItemLoader from "./CommunicationItemLoader";

export default function Communication() {
  const {
    communicationSettings,
    isLoadingCommunicationSettings,
    updateCommunicationSettings,
  } = useCommunicationSettings();
  const { updateLocalStorage, unsubscribeWebPush } = usePushService();

  function showCommunicationSettings(): boolean {
    return communicationSettings && !isLoadingCommunicationSettings;
  }

  const handleToggle = async (key: keyof CommunicationSettings) => {
    const isSettingEnabled = !communicationSettings[key];
    try {
      // Update settings
      await updateCommunicationSettings({
        ...communicationSettings,
        [key]: isSettingEnabled,
      });

      // Additional handling for Web Push setting
      if (key === "webpush") {
        if (isSettingEnabled) {
          unsubscribeWebPush();
        } else {
          updateLocalStorage(false);
        }
      }
    } catch (err) {
      // Revert to original state if error happens
      updateCommunicationSettings({
        ...communicationSettings,
        [key]: communicationSettings[key],
      });
    }
  };

  const allSettings: {
    key: keyof CommunicationSettings;
    title: string;
    subtitle: string;
  }[] = [
    {
      key: "email",
      title: "Email Communication",
      subtitle:
        "Promotions, Discounts, Service Updates and the Aimfox Newsletter",
    },
    {
      key: "phone",
      title: "SMS Communication",
      subtitle: "High impact promotions and important updates",
    },
    {
      key: "webpush",
      title: "Web Push Communication",
      subtitle:
        "Get campaign, account and service updates directly in your browser",
    },
  ];

  const content = (
    <div className="flex w-full flex-col">
      {showCommunicationSettings() ? (
        <div className="mb-6 flex flex-col gap-6">
          {allSettings.map(({ key, title, subtitle }) => {
            const isEnabled = communicationSettings[key];

            return (
              <CommunicationItem
                key={key}
                id={key}
                title={title}
                subtitle={subtitle}
                isEnabled={isEnabled}
                onToggle={() => handleToggle(key)}
              />
            );
          })}
        </div>
      ) : (
        <CommunicationItemLoader itemsCount={3} />
      )}
      <Alert
        description="We will always send you transactional and service critical updates"
        variant="warning"
      />
    </div>
  );

  return (
    <SettingsItemContainer label="Communication">
      {content}
    </SettingsItemContainer>
  );
}
