import React, { useState } from "react";

import usePromotions from "common/datahooks/usePromotions";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Promotion as PromotionType } from "common/types";
import Promotion from "common/components/Promotion";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import PromotionButton from "./PromotionButton";

export default function Promotions() {
  const [isOpen, setIsOpen] = useState(false);
  const [promotionType, setPromotionType] =
    useState<PromotionType["type"]>(null);
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const isTabletOrDesktop = useTwBreakpoint("md");

  const { appSumoPromotion, ltdPromotion } = usePromotions();

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  return (
    <>
      <div className="flex gap-x-2 max-lg:w-full">
        {!!appSumoPromotion && (
          <PromotionButton
            type="appsumo"
            onClick={() => {
              setPromotionType("appsumo");
              setIsOpen(true);
            }}
          />
        )}

        {!!ltdPromotion && (
          <PromotionButton
            type="ltd"
            onClick={() => {
              setPromotionType("ltd");
              setIsOpen(true);
            }}
          />
        )}
      </div>

      <Component open={isOpen} onOpenChange={setIsOpen}>
        <Content>
          <Promotion
            promotion={
              promotionType === "appsumo" ? appSumoPromotion : ltdPromotion
            }
            onClose={() => setIsOpen(false)}
            workspaceId={workspaceId}
          />
        </Content>
      </Component>
    </>
  );
}
