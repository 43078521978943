import React from "react";
import clsx from "clsx";

import { Dialog, DialogContent } from "common/components/ui/Dialog";

import DeleteWebhook from "./DeleteWebhook";
import { Webhook } from "../../types";
import WebhookForm from "./WebhookForm";

interface WebhookDialogProps {
  webhook: Webhook;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  action: "edit" | "delete";
}

export default function WebhookDialog({
  webhook,
  isOpen,
  setIsOpen,
  action,
}: WebhookDialogProps) {
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className={clsx(action === "delete" && "md:w-[448px]")}>
        {action === "delete" ? (
          <DeleteWebhook
            webhook={webhook}
            onBack={() => setIsOpen(false)}
            onSuccess={() => setIsOpen(false)}
          />
        ) : (
          <WebhookForm webhook={webhook} onBack={() => setIsOpen(false)} />
        )}
      </DialogContent>
    </Dialog>
  );
}
