import React, { useState } from "react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import SearchInput from "common/components/SearchInput";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import RenderIf from "common/components/RenderIf";
import ErrorPlaceholder from "common/components/ErrorPlaceholder";
import Loader from "common/components/Loader";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import { Button } from "common/components/ui/Button";

import DesktopTable from "./DesktopTable";
import MobileList from "./MobileList";
import useWorkspaceMembers from "../../datahooks/useWorkspaceMembers";
import InviteMember from "./InviteMemberDialog";

import NoDataImage from "assets/images/empty-placeholders/search-campaign.png";

export default function Members() {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { max_members: maxMembers } = useSelectedWorkspaceContext();

  const [searchTerm, setSearchTerm] = useState("");

  const { members, isLoadingMembers, isMembersError, refetchMembers } =
    useWorkspaceMembers();

  if (isMembersError)
    return (
      <ErrorPlaceholder
        errorMessage="Could not display Members"
        onRetry={refetchMembers}
      />
    );

  if (isLoadingMembers) return <Loader />;

  const lowerCaseSearchTerm = searchTerm.toLowerCase();

  const filteredMembers = members.filter(
    ({ first_name: firstName, last_name: lastName, email }) => {
      const isMatchingFirstName = firstName
        ?.toLowerCase()
        .includes(lowerCaseSearchTerm);

      const isMatchingLastName = lastName
        ?.toLowerCase()
        .includes(lowerCaseSearchTerm);

      const isMatchingEmail = email
        ?.toLowerCase()
        .includes(lowerCaseSearchTerm);

      return searchTerm
        ? isMatchingFirstName || isMatchingLastName || isMatchingEmail
        : true;
    },
  );

  function clearSearch() {
    setSearchTerm("");
  }

  const Component = isTabletOrDesktop ? DesktopTable : MobileList;

  return (
    <>
      {/* Heading */}
      <div className="flex w-full flex-col-reverse items-center justify-between gap-2 md:flex-row">
        <SearchInput
          className="w-full md:w-80"
          placeholder="Search members"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClear={clearSearch}
        />

        <div className="flex items-center justify-between gap-x-3 max-md:w-full">
          <RenderIf condition={!!maxMembers}>
            <span className="text-button-16 text-black-400">
              {members.length}/{maxMembers} teammates
            </span>
          </RenderIf>

          <InviteMember />
        </div>
      </div>

      {filteredMembers.length ? (
        <Component members={filteredMembers} />
      ) : (
        <EmptyPlaceholder
          title="No Members Found"
          subtitle="No members match your search criteria. Try adjusting your search"
          imageSrc={NoDataImage}
          actionComponent={<Button onClick={clearSearch}>Clear search</Button>}
        />
      )}
    </>
  );
}
