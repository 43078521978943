import React from "react";

import { logout } from "common/helpers/auth";
import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

interface LogOutModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

export default function LogOutModal({ isOpen, setIsOpen }: LogOutModalProps) {
  const isDesktopOrTablet = useTwBreakpoint("md");

  const Component = isDesktopOrTablet ? Dialog : Drawer;
  const Content = isDesktopOrTablet ? DialogContent : DrawerContent;

  return (
    <Component open={isOpen} onOpenChange={setIsOpen}>
      <Content className="md:w-[448px]">
        <h3 className="mb-2 text-pretty text-center text-headline-2xl-bold md:text-headline-xl-bold">
          Are you sure you want to log out?
        </h3>
        <p className="mb-12 text-center text-black-500">
          You will be returned to the login screen
        </p>
        <div className="flex flex-col gap-2 lg:flex-row-reverse">
          <Button
            className="flex-1"
            onClick={logout}
            size={isDesktopOrTablet ? "md" : "lg"}
            data-cy="log-out-confirmation-button"
          >
            Log out
          </Button>
          <Button
            variant="secondary-black"
            className="flex-1"
            onClick={() => setIsOpen(false)}
            size={isDesktopOrTablet ? "md" : "lg"}
          >
            Cancel
          </Button>
        </div>
      </Content>
    </Component>
  );
}
