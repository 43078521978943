import React from "react";
import clsx from "clsx";
import { Info } from "@phosphor-icons/react";

import { allMetrics } from "common/constants";
import { typedObjectEntries } from "common/helpers/utils";
import { Metrics } from "common/types";
import Skeleton from "common/components/ui/Skeleton";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";

interface MetricSelectorProps {
  metricsSum: Metrics;
  selectedMetric: keyof Metrics;
  setSelectedMetric: (metric: keyof Metrics) => void;
  isLoading: boolean;
  hiddenColumns?: Array<keyof Metrics>;
  totalMetrics?: Metrics;
}

export default function MetricSelector({
  metricsSum,
  setSelectedMetric,
  selectedMetric,
  isLoading,
  hiddenColumns = [],
  totalMetrics = null,
}: MetricSelectorProps) {
  const filteredMetrics = typedObjectEntries(allMetrics).filter(
    ([key]) => !hiddenColumns.includes(key),
  );

  return (
    <div className="mb-4 flex overflow-x-auto scrollbar-thin max-lg:gap-x-2">
      {filteredMetrics.map(
        ([
          key,
          { label, icon: Icon, tooltipText, selectedClassName, className },
        ]) => {
          const isSelected = selectedMetric === key;

          return (
            <button
              key={key}
              type="button"
              className={clsx(
                "flex shrink-0 grow basis-44 items-center gap-x-4 border border-black-200 p-3 max-lg:rounded-xl lg:border-l-0 lg:first:rounded-l-xl lg:first:border-l lg:last:rounded-r-xl",
                isSelected ? selectedClassName.button : "hover:bg-purple-50",
              )}
              onClick={() => setSelectedMetric(key)}
            >
              <div
                className={clsx(
                  "flex size-10 shrink-0 items-center justify-center rounded-full",
                  isSelected
                    ? clsx(selectedClassName.icon, "bg-black-950")
                    : clsx(className.icon, className.background),
                )}
              >
                <Icon className="size-5" />
              </div>
              <div className="flex grow flex-col lg:gap-y-0.5">
                <div className="flex items-center gap-x-0.5">
                  <h3 className="text-nowrap text-left text-caption-12-regular">
                    {label}
                  </h3>
                  <Tooltip>
                    <TooltipTrigger className="max-lg:hidden" asChild>
                      <div tabIndex={0} role="button" aria-label="more info">
                        <Info
                          weight="fill"
                          className={clsx(
                            isSelected ? "fill-black-700" : "fill-black-300",
                            "size-5",
                          )}
                        />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>{tooltipText}</TooltipContent>
                  </Tooltip>
                </div>
                <div className="flex items-end justify-between">
                  {isLoading ? (
                    <Skeleton className="h-7 w-16 rounded-lg" />
                  ) : (
                    <span className="text-headline-lg-bold lg:text-headline-xl-bold">
                      {metricsSum[key]}
                    </span>
                  )}
                  {totalMetrics && (
                    <span className="text-caption-12-regular">
                      total {totalMetrics[key]}
                    </span>
                  )}
                </div>
              </div>
            </button>
          );
        },
      )}
    </div>
  );
}
