import React, { useCallback, useMemo } from "react";
import { scaleLinear } from "@visx/scale";
import { Group } from "@visx/group";
import { Line as VisxLine } from "@visx/shape";
import { getStringWidth } from "@visx/text";
import { useTooltip } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { bisector } from "@visx/vendor/d3-array";

import { tailwindTheme } from "common/helpers/utils";
import { allMetrics } from "common/constants";
import { Metrics } from "common/types";

import Tooltip from "./Tooltip";
import Line from "./Line";
import Axes from "./Axes";

const placeholderData: (Metrics & { timestamp: number })[] = [];
for (let i = 0; i < 7; i += 1) {
  placeholderData.push({
    replies: 0,
    sent_connections: 0,
    sent_inmails: 0,
    views: 0,
    accepted_connections: 0,
    sent_messages: 0,
    timestamp: Date.now() - 1000 * 60 * 60 * 24 * (6 - i),
    message_requests: 0,
  });
}

export default function Chart({
  width,
  height,
  metrics,
  selectedMetric,
  isEmpty,
  isHourly,
}: {
  metrics: (Metrics & { timestamp: number })[];
  selectedMetric: keyof Metrics;
  width: number;
  height: number;
  isEmpty: boolean;
  isHourly: boolean;
}) {
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen: isTooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<Metrics & { timestamp: number }>();

  const strokeClassName = allMetrics[selectedMetric].className.stroke;
  const finalMetrics = !metrics || !metrics.length ? placeholderData : metrics;

  const maxYValue = finalMetrics.reduce((accumulator, currentValue) => {
    if (currentValue[selectedMetric] > accumulator) {
      return currentValue[selectedMetric];
    }
    return accumulator;
  }, 0);
  const actualMaxYValue = maxYValue === 0 ? 10 : maxYValue;

  const margins = useMemo(
    () => ({
      top: 8,
      right: 14,
      bottom: 46,
      left:
        getStringWidth(actualMaxYValue.toString(), {
          fontFamily: tailwindTheme.fontFamily.manrope,
          fontSize: 14,
        }) + 14,
    }),
    [actualMaxYValue],
  );
  const finalWidth = width - margins.left - margins.right;
  const finalHeight = height - margins.top - margins.bottom;

  const xScale = scaleLinear({
    domain: [
      finalMetrics[0].timestamp,
      finalMetrics[finalMetrics.length - 1].timestamp,
    ],
    range: [0, finalWidth],
  });

  const yScale = scaleLinear({
    domain: [0, actualMaxYValue],
    range: [finalHeight, 0],
    nice: true,
  });

  const handleTooltip = useCallback(
    (event: React.TouchEvent<SVGElement> | React.MouseEvent<SVGElement>) => {
      if (metrics) {
        const { x } = localPoint(event) || { x: 0 };
        const value = xScale.invert(x);
        const index = bisector<Metrics & { timestamp: number }, number>(
          ({ timestamp }) => timestamp,
        ).left(metrics, value);
        const data = metrics[index === 0 ? 0 : index - 1];
        showTooltip({
          tooltipData: data,
          tooltipLeft: xScale(data.timestamp),
          tooltipTop: yScale(data[selectedMetric]),
        });
      }
    },
    [showTooltip, metrics, selectedMetric, xScale, yScale],
  );

  return (
    <>
      <svg
        className="overflow-visible"
        width={width}
        height={height}
        onTouchStart={handleTooltip}
        onTouchMove={handleTooltip}
        onMouseMove={handleTooltip}
        onMouseEnter={handleTooltip}
        onMouseLeave={hideTooltip}
      >
        <Group left={margins.left} top={margins.top}>
          <Axes
            metrics={finalMetrics}
            height={finalHeight}
            yScale={yScale}
            xScale={xScale}
            isHourly={isHourly}
          />
          {!isEmpty && (
            <Line
              metrics={finalMetrics}
              selectedMetric={selectedMetric}
              xScale={xScale}
              yScale={yScale}
            />
          )}
        </Group>
        {tooltipData && (
          <g>
            <VisxLine
              className={strokeClassName}
              from={{ x: tooltipLeft + margins.left, y: margins.top }}
              to={{
                x: tooltipLeft + margins.left,
                y: height - margins.bottom,
              }}
              strokeWidth={1}
              pointerEvents="none"
              strokeDasharray="4,4"
            />
            <circle
              className={strokeClassName}
              strokeWidth={6}
              fill="white"
              cx={tooltipLeft + margins.left}
              cy={tooltipTop + margins.top}
              r={7}
              pointerEvents="none"
            />
          </g>
        )}
      </svg>
      {isTooltipOpen && (
        <Tooltip
          left={tooltipLeft + margins.left + 6}
          top={tooltipTop - margins.top}
          selectedMetric={selectedMetric}
          data={tooltipData}
        />
      )}
    </>
  );
}
