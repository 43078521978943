/* eslint-disable react/react-in-jsx-scope */
import React from "react";
import { type Control, Controller } from "react-hook-form";

import { Input } from "common/components/ui/Input";
import Label from "common/components/ui/Label";
import Switch from "common/components/ui/Switch";
import RenderIf from "common/components/RenderIf";

import { WebhookFormType } from "../../../schema";

interface WebhookHeaderProps {
  isProtected: boolean;
  setIsProtected: (value: boolean) => void;
  control: Control<WebhookFormType>;
}

export default function WebhookHeader({
  isProtected,
  setIsProtected,
  control,
}: WebhookHeaderProps) {
  return (
    <section className="mb-4">
      <div className="flex items-center justify-between gap-4">
        <div>
          <h5 className="mb-2 text-button-14 text-black-700">
            Protect webhook URL with basic authentication
          </h5>
          <p className="text-black-700">
            Allows you to add a username and password to secure your webhook URL
          </p>
        </div>

        <Switch checked={isProtected} onCheckedChange={setIsProtected} />
      </div>

      <RenderIf condition={isProtected}>
        <div className="flex gap-4 border-black-200 animate-in fade-in-0 max-md:flex-col max-md:pt-4 md:grid md:grid-cols-2 md:border-b md:py-4">
          <div>
            <Label htmlFor="headers.name" className="mb-1">
              Header name
            </Label>
            <Controller
              name="headers.name"
              control={control}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <Input
                  variant="lg"
                  name={name}
                  value={value}
                  onChange={(e) => onChange(e)}
                  placeholder="Enter headers name"
                  error={error?.message}
                />
              )}
            />
          </div>

          <div>
            <Label htmlFor="headers.value" className="mb-1">
              Header value
            </Label>
            <Controller
              name="headers.value"
              control={control}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <Input
                  variant="lg"
                  name={name}
                  value={value}
                  onChange={(e) => onChange(e)}
                  placeholder="Enter headers value"
                  error={error?.message}
                />
              )}
            />
          </div>
        </div>
      </RenderIf>
    </section>
  );
}
