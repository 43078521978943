import React, { Fragment, useRef } from "react";
import { Bell } from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import { clsx } from "clsx";
import { PopoverClose } from "@radix-ui/react-popover";

import { Button } from "common/components/ui/Button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";
import { Tag } from "common/components/ui/Tag";

import useNotifications from "../../../../datahooks/useNotifications";
import NotificationItem from "./NotificationItem";
import BadgeWrapper from "../../BadgeWrapper";

import EmptyPlaceholderImage from "assets/images/empty-placeholders/notifications.png";

export default function NotificationsPopup() {
  const buttonRef = useRef<HTMLButtonElement>();
  const { notifications, unseenCount } = useNotifications();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          intent="iconOnly"
          variant="quaternary-black"
          aria-label="notification"
          className="group"
          ref={buttonRef}
        >
          <BadgeWrapper
            badgeClassName={clsx(
              "right-0 top-0 size-2 border-white group-hover:border-black-50",
              !unseenCount && "hidden",
            )}
          >
            <Bell weight="fill" />
          </BadgeWrapper>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[420px]" align="end">
        <div className="flex items-center gap-x-1 border-b border-b-black-200 p-4">
          <h3 className="text-headline-xl-bold">Notifications</h3>
          {!!notifications.length && (
            <Tag variant="secondary">{notifications.length}</Tag>
          )}
        </div>
        {notifications.length ? (
          <>
            <div className="flex max-h-[400px] flex-col gap-y-2 overflow-y-auto p-4 scrollbar-thin">
              {notifications.map((notification) => (
                <Fragment key={notification.id}>
                  <NotificationItem key={notification.id} {...notification} />
                  {notification.seen && (
                    <div className="my-0.5 h-px bg-black-100 last:hidden" />
                  )}
                </Fragment>
              ))}
            </div>
            <div className="flex justify-end border-t border-t-black-200 bg-white px-4 py-3">
              <PopoverClose asChild>
                <Button asChild size="lg" variant="quaternary-black">
                  <Link to="notifications">Show all</Link>
                </Button>
              </PopoverClose>
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center gap-y-4 px-4 py-10">
            <img
              src={EmptyPlaceholderImage}
              alt="Empty notifications placeholder"
              width={298}
              height={208}
            />

            <h5 className="text-center text-headline-2xl-bold">
              Nothing to report
            </h5>
            <p className="-mt-2 text-center text-black-500">
              This area will light up with new notifications once there&apos;s
              activity in your workspaces
            </p>
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
}
