import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import getQueryKeys from "common/datahooks/getQueryKeys";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { del, get, patch, post } from "common/helpers/HTTP";

import { Webhook } from "../types";

async function getWebhooksRequest(workspaceId: string): Promise<Webhook[]> {
  return (await get(`workspaces/${workspaceId}/webhooks`)).webhooks;
}

async function deleteWebhookRequest(workspaceId: string, webhookId: string) {
  await del(`workspaces/${workspaceId}/webhooks/${webhookId}`);
}

async function updateWebhookRequest(
  workspaceId: string,
  webhookId: string,
  updates: Partial<Omit<Webhook, "id">>,
) {
  await patch(`workspaces/${workspaceId}/webhooks/${webhookId}`, updates);
}

async function createWebhookRequest(
  workspaceId: string,
  newWebhook: Omit<Webhook, "id">,
): Promise<Webhook> {
  return (
    await post(`workspaces/${workspaceId}/webhooks`, {
      ...newWebhook,
      // Note: This is needed for the backend
      integration: false,
    })
  ).webhook;
}

export default function useWebhooks({
  disableQuery,
}: {
  disableQuery?: boolean;
} = {}) {
  const queryClient = useQueryClient();
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const { webhooksKeys } = getQueryKeys(workspaceId);

  const {
    data: webhooks,
    isLoading: isLoadingWebhooks,
    isError: isWebhooksError,
    refetch: refetchWebhooks,
  } = useQuery({
    queryKey: webhooksKeys.list,
    queryFn: () => getWebhooksRequest(workspaceId),
    enabled: !disableQuery,
  });

  const { mutateAsync: deleteWebhook, isPending: isDeletingWebhook } =
    useMutation({
      mutationFn: ({ webhookId }: { webhookId: string }) =>
        deleteWebhookRequest(workspaceId, webhookId),
      onSuccess: (_, { webhookId }) => {
        queryClient.setQueryData<Webhook[]>(webhooksKeys.list, (prevWebhooks) =>
          prevWebhooks.filter(({ id }) => id !== webhookId),
        );

        toast.success("Webhook deleted");
      },
      onError: () => toast.error("Error deleting Webhook"),
    });

  const { mutateAsync: updateWebhook, isPending: isUpdatingWebhook } =
    useMutation({
      mutationFn: ({
        webhookId,
        updates,
      }: {
        webhookId: string;
        updates: Partial<Omit<Webhook, "id">>;
      }) => updateWebhookRequest(workspaceId, webhookId, updates),
      onError: () => toast.error("Error updating Webhook"),
      onMutate: ({ webhookId, updates }) => {
        queryClient.setQueryData<Webhook[]>(webhooksKeys.list, (prevWebhooks) =>
          prevWebhooks.map((webhook) => {
            if (webhook.id === webhookId) {
              return { ...webhook, ...updates };
            }

            return webhook;
          }),
        );
      },
      onSuccess: () => toast.success("Webhook updated successfully"),
    });

  const { mutateAsync: createWebhook, isPending: isCreatingWebhook } =
    useMutation({
      mutationFn: ({ newWebhook }: { newWebhook: Omit<Webhook, "id"> }) =>
        createWebhookRequest(workspaceId, newWebhook),
      onSuccess: (newWebhook) => {
        queryClient.setQueryData<Webhook[]>(
          webhooksKeys.list,
          (prevWebhooks) => [newWebhook, ...prevWebhooks],
        );

        toast.success("Webhook created");
      },
      onError: () => toast.error("Error creating Webhook"),
    });

  return {
    webhooks,
    isLoadingWebhooks,
    isWebhooksError,
    refetchWebhooks,
    deleteWebhook,
    isDeletingWebhook,
    updateWebhook,
    isUpdatingWebhook,
    createWebhook,
    isCreatingWebhook,
  };
}
