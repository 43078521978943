import React from "react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";

import {
  Dialog,
  DialogClose,
  DialogContent,
} from "common/components/ui/Dialog";
import { Button } from "common/components/ui/Button";
import Label from "common/components/ui/Label";
import { Input } from "common/components/ui/Input";

import { ApiToken } from "../../types";
import useApiTokens from "../../datahooks/useApiTokens";
import { ApiKeyNameSchema } from "../../schema";

interface EditNameDialogProps {
  apiToken: ApiToken;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

export default function EditNameDialog({
  apiToken,
  isOpen,
  onOpenChange,
}: EditNameDialogProps) {
  const { name, id: tokenId } = apiToken || {};

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    name: string;
  }>({
    resolver: valibotResolver(ApiKeyNameSchema),
    defaultValues: { name },
  });

  const { updateApiToken, isUpdatingToken } = useApiTokens({
    disableQuery: true,
  });

  function onSubmit(data: { name: string }): void {
    updateApiToken({
      tokenId,
      updates: { name: data.name },
    }).then((newToken) => {
      reset({ name: newToken.name });
      onOpenChange(false);
    });
  }

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent>
        {/* Title */}
        <h2 className="mb-8 text-center text-headline-xl-bold">Edit API Key</h2>

        {/* Form input for editing key name */}
        <form
          className="flex flex-col gap-y-8"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col gap-y-2">
            <Label htmlFor="name">Key name</Label>

            <Input
              {...register("name")}
              error={errors.name?.message}
              defaultValue={name}
              variant="lg"
              id="name"
            />
          </div>

          {/* Action buttons */}
          <div className="grid gap-4 md:grid-cols-2">
            <DialogClose asChild>
              <Button disabled={isUpdatingToken} variant="secondary-black">
                Cancel
              </Button>
            </DialogClose>

            <Button isLoading={isUpdatingToken} type="submit">
              Save
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}
