import React, { Dispatch, SetStateAction, useState } from "react";
import { Check, EnvelopeOpen, User, type Icon } from "@phosphor-icons/react";

import { CreatedTemplate } from "common/types";
import { Button } from "common/components/ui/Button";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import { templateTypes } from "common/constants";
import { formatDate } from "common/helpers/utils";
import TemplateDetailsForm from "common/components/TemplateDetailsForm";

import useTemplate from "../../datahooks/useTemplate";

interface CampaignPreviewProps {
  template: CreatedTemplate;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

function MetricItem({
  icon: Icon,
  label,
  value,
}: {
  icon: Icon;
  label: string;
  value: string | number;
}) {
  return (
    <div className="flex justify-between">
      <span className="text-black-400">{label}</span>
      <div className="flex gap-1">
        <Icon size={20} className="fill-black-400" />
        <span>{value}</span>
      </div>
    </div>
  );
}

export default function TemplatePreview({
  template,
  isOpen,
  setIsOpen,
}: CampaignPreviewProps) {
  const [currentStep, setCurrentStep] = useState<"preview" | "edit" | "delete">(
    "preview",
  );

  const { deleteTemplate, isDeletingTemplate } = useTemplate();

  const { type, created_at: createdAt, name, stats, id } = template;
  const { icon: Icon } = templateTypes[type];

  function removeTemplate() {
    deleteTemplate({ templateId: id }).then(() => setIsOpen(false));
  }

  function getContent() {
    const acceptedOrReplied =
      type === "NOTE_TEMPLATE" ? stats.accepted : stats.replies;

    if (currentStep === "preview") {
      return (
        <>
          <div className="mb-6 flex items-center gap-2">
            <span className="inline-flex size-10 shrink-0 items-center justify-center rounded-2xl border border-black-300">
              <Icon size={20} />
            </span>
            <div className="w-[calc(100%_-_48px)] ">
              <span className="text-button-12 text-black-500">
                Created on {formatDate(createdAt)}
              </span>

              <h5 className="break-words text-body-16-bold">{name}</h5>
            </div>
          </div>

          {/* Metrics */}
          <div className="flex flex-col gap-5">
            <MetricItem label="Sent" icon={User} value={stats.sent} />
            <MetricItem
              label="Accepted/Replied"
              icon={EnvelopeOpen}
              value={acceptedOrReplied}
            />

            <MetricItem
              label="Success rate"
              icon={Check}
              value={`${
                acceptedOrReplied
                  ? Math.round((acceptedOrReplied / stats.sent) * 100)
                  : 0
              }%`}
            />
          </div>
          <Button
            className="mb-2 mt-12"
            onClick={() => setCurrentStep("edit")}
            size="lg"
          >
            Edit
          </Button>
          <Button
            variant="tertiary-danger"
            onClick={() => setCurrentStep("delete")}
            size="lg"
          >
            Delete Template
          </Button>
        </>
      );
    }

    if (currentStep === "delete") {
      return (
        <>
          <h5 className="mb-2 text-center text-headline-2xl-bold">
            Delete Template
          </h5>
          <p className="mb-8 text-center text-black-500">
            Are you sure you want to delete &quot;{name}
            &quot; Template?
          </p>
          <Button
            variant="tertiary-danger"
            className="mb-2"
            onClick={removeTemplate}
            isLoading={isDeletingTemplate}
            size="lg"
          >
            Yes
          </Button>
          <Button
            variant="secondary-black"
            onClick={() => setCurrentStep("preview")}
            disabled={isDeletingTemplate}
            size="lg"
          >
            Cancel
          </Button>
        </>
      );
    }

    return (
      <TemplateDetailsForm
        template={template}
        goBack={() => setCurrentStep("preview")}
      />
    );
  }

  return (
    <Drawer
      open={isOpen}
      onOpenChange={setIsOpen}
      onAfterClose={() => setCurrentStep("preview")}
    >
      <DrawerContent>{getContent()}</DrawerContent>
    </Drawer>
  );
}
