import React, { useState } from "react";
import { CalendarDots, CaretDown } from "@phosphor-icons/react";
import clsx from "clsx";

import { MetricsParameters } from "common/types";
import { Button } from "common/components/ui/Button";
import DatePicker from "common/components/DatePicker";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";
import { getMonthName } from "common/helpers/utils";

import { statsTimeframeOptions } from "../../pages/campaigns/constants";

export default function TimeframeSelector({
  selectedParams,
  setSelectedParams,
}: {
  selectedParams: MetricsParameters;
  setSelectedParams: (selectedTimeframe: MetricsParameters) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isCustomDate, setIsCustomDate] = useState(false);

  function setSelectedRange(range: { from: number; to: number }) {
    setSelectedParams({
      timestamps: range,
      bucketSize: "1 day",
    });
  }
  function onClear() {
    setSelectedParams(statsTimeframeOptions[1].parameters);
    setIsCustomDate(false);
  }

  const selectedTimeframeOption = statsTimeframeOptions.find(
    ({ parameters }) => parameters === selectedParams,
  );

  let buttonLabel;

  if (isCustomDate) {
    const { from, to } = selectedParams.timestamps;
    const fromDate = new Date(from);
    const toDate = new Date(to);

    buttonLabel = `${getMonthName(fromDate.getMonth(), true)} ${fromDate.getDate()} - ${toDate.getMonth() === fromDate.getMonth() ? "" : getMonthName(toDate.getMonth(), true)} ${toDate.getDate()}`;
  } else if (selectedTimeframeOption) {
    buttonLabel = selectedTimeframeOption.label;
  }

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="secondary-black"
          leftIcon={<CalendarDots />}
          rightIcon={
            <CaretDown
              className={clsx(isOpen && "rotate-180", "transition-transform")}
            />
          }
          className={isOpen && "bg-black-200"}
        >
          {buttonLabel}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="end"
        className="flex w-auto min-w-[200px] flex-col gap-y-1"
      >
        {isCustomDate ? (
          <DatePicker
            selectedRange={selectedParams.timestamps}
            setSelectedRange={setSelectedRange}
            onClear={onClear}
          />
        ) : (
          <>
            <button
              type="button"
              onClick={() => setIsCustomDate(true)}
              className="flex px-4 py-2 text-button-14 first:mt-2 last:mb-2 hover:bg-black-50"
            >
              Custom
            </button>
            {statsTimeframeOptions.map(({ label, parameters }) => {
              const isSelected = parameters === selectedParams;
              return (
                <button
                  key={label}
                  type="button"
                  onClick={() => {
                    setSelectedParams(parameters);
                    setIsOpen(false);
                  }}
                  className={clsx(
                    isSelected && "bg-black-50",
                    "flex px-4 py-2 text-button-14 first:mt-2 last:mb-2 hover:bg-black-50",
                  )}
                >
                  {label}
                </button>
              );
            })}
          </>
        )}
      </PopoverContent>
    </Popover>
  );
}
