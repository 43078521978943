import React, {
  ComponentProps,
  ReactNode,
  forwardRef,
  ForwardedRef,
} from "react";

import { cn } from "common/helpers/utils";

interface EmptyPlaceholderProps extends ComponentProps<"div"> {
  title: string;
  subtitle: string;
  imageSrc: string;
  actionComponent?: ReactNode;
}

function EmptyPlaceholder(
  {
    className,
    title,
    subtitle,
    actionComponent = null,
    imageSrc,
    ...props
  }: EmptyPlaceholderProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <div
      ref={ref}
      className={cn(
        "max-w-72 md:max-w-96 flex gap-4 flex-col mx-auto items-center grow justify-center",
        className,
      )}
      {...props}
    >
      <img src={imageSrc} alt={title} width={364} height={254} />
      <h5 className="text-center text-headline-2xl-bold">{title}</h5>
      <p className="-mt-2 w-full text-pretty break-words text-center text-black-500">
        {subtitle}
      </p>
      {actionComponent}
    </div>
  );
}
EmptyPlaceholder.displayName = "EmptyPlaceholder";

export default forwardRef(EmptyPlaceholder);
