import {
  DotsThreeVertical,
  PencilSimpleLine,
  Trash,
} from "@phosphor-icons/react";
import React, { useState } from "react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";
import { Button } from "common/components/ui/Button";
import { Dialog, DialogContent } from "common/components/ui/Dialog";

import DeleteLabel from "./DeleteLabel";

export default function LabelDropdownMenu({
  labelId,
  editLabel,
}: {
  labelId: string;
  editLabel: () => void;
}) {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            intent="iconOnly"
            size="md"
            variant="secondary-black"
            data-cy="label-dropdown-button"
          >
            <DotsThreeVertical />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent side="left">
          <DropdownMenuItem onClick={editLabel}>
            <PencilSimpleLine size={20} />
            Edit label
          </DropdownMenuItem>
          <DropdownMenuItem
            className="text-red-500"
            onClick={() => setIsDeleteOpen(true)}
            data-cy="label-dropdown-delete"
          >
            <Trash size={20} />
            Delete label
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={isDeleteOpen} onOpenChange={setIsDeleteOpen}>
        <DialogContent className="md:w-[448px]">
          <DeleteLabel
            labelId={labelId}
            onBack={() => setIsDeleteOpen(false)}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
