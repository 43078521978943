import React from "react";

import { MetricsParameters } from "common/types";
import { Button } from "common/components/ui/Button";

import OverviewCard from "./OverviewCard";
import { CampaignFlow, CampaignFlowType } from "../../../../types";
import FlowTypeLabel from "../../../FlowTypeLabel";

interface FlowsOverviewProps {
  flows: CampaignFlow[];
  metricsParams: MetricsParameters;
  goToFlowTab: (flowId: string) => void;
}

export default function FlowsOverview({
  flows,
  metricsParams,
  goToFlowTab,
}: FlowsOverviewProps) {
  return (
    <section className="flex flex-col gap-4 md:flex-row">
      {flows.map((flow) => {
        const headingOptions: Record<
          CampaignFlowType,
          {
            title: string;
            description: string;
          }
        > = {
          CONNECT_OPTIMIZATION: {
            title: "Connect",
            description: "Performance details for the Connect Flow",
          },
          INMAIL_OPTIMIZATION: {
            title: "InMail",
            description: "Performance details for the InMail Flow",
          },
          PRIMARY_CONNECT: {
            title: flow.name,
            description: `Performance details for ${flow.name}`,
          },
          PRIMARY_MESSAGE: {
            title: flow.name,
            description: `Performance details for ${flow.name}`,
          },
        };

        const { title, description } = headingOptions[flow.type];

        return (
          <article
            key={flow.id}
            className="flex flex-1 flex-col rounded-2xl border border-black-200 px-3 pb-2 pt-4 md:px-4 [&>:nth-last-child(2)]:mb-2.5"
          >
            {/* Heading */}
            <div className="-mx-3 flex gap-3 border-b border-black-200 px-3 pb-4 md:-mx-4 md:px-4">
              <FlowTypeLabel type={flow.type} />
              <div>
                <h3 className="text-headline-lg-bold">{title}</h3>
                <p className="text-black-500">{description}</p>
              </div>
            </div>

            <OverviewCard flow={flow} metricsParams={metricsParams} />

            <Button
              variant="quaternary-purple"
              className="mt-auto"
              onClick={() => goToFlowTab(flow.id)}
            >
              View stats
            </Button>
          </article>
        );
      })}
    </section>
  );
}
