import React, { useState } from "react";
import { Plus, PlusCircle } from "@phosphor-icons/react";
import { clsx } from "clsx";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";

import { Label } from "common/types";
import { labelColors } from "common/constants";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";
import Checkbox from "common/components/ui/Checkbox";
import SearchInput from "common/components/SearchInput";
import useLeadLabels from "common/datahooks/useLeadLabels";
import { Tag } from "common/components/ui/Tag";
import { Button } from "common/components/ui/Button";
import LabelColorSelection from "common/components/LeadLabels/LabelColorSelection";
import { LabelSchema, LabelSchemaType } from "common/schemas";
import useWorkspaceLabelsMutations from "common/datahooks/useWorkspaceLabelsMutations";
import RenderIf from "common/components/RenderIf";
import LabelTag from "common/components/LabelTag";
import { getSchemaMaxLength } from "common/helpers/utils";

import useWorkspaceLabels from "../../datahooks/useWorkspaceLabels";

function LeadLabels({
  labels,
  profileId,
}: {
  labels: Label[];
  profileId: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const { handleSubmit, setValue, register, watch, reset } =
    useForm<LabelSchemaType>({
      defaultValues: {
        name: "",
      },
      resolver: valibotResolver(LabelSchema),
    });
  const { workspaceLabels } = useWorkspaceLabels();
  const { removeLeadLabel, addLeadLabel } = useLeadLabels();
  const { addWorkspaceLabel } = useWorkspaceLabelsMutations();

  function toggleLeadLabel(label: Label, isSelected: boolean) {
    (isSelected ? removeLeadLabel : addLeadLabel)({ label, profileId });
  }
  function createAndAddLabel(label: LabelSchemaType) {
    addWorkspaceLabel(label).then((newLabel) => {
      addLeadLabel({ label: newLabel, profileId }).then(() => {
        setIsCreatingNew(false);
        setTimeout(reset, 300);
      });
    });
  }
  const labelName = watch("name");
  const filteredLabels = workspaceLabels
    ? workspaceLabels.filter(({ name }) =>
        labelName ? name.toLowerCase().includes(labelName.toLowerCase()) : true,
      )
    : [];

  return (
    <>
      <DropdownMenu
        open={isOpen}
        onOpenChange={(open) => {
          if (!open) {
            setIsOpen(open);

            if (!isCreatingNew) {
              setTimeout(reset, 300);
            }
          }
        }}
      >
        <DropdownMenuTrigger asChild>
          <div className="flex flex-wrap items-center gap-2">
            {labels.length ? (
              <>
                {labels.map((label) => (
                  <LabelTag
                    key={label.id}
                    label={label}
                    onClick={() => setIsOpen(true)}
                  />
                ))}
                <Button
                  variant="tertiary-black"
                  size="xs"
                  intent="iconOnly"
                  onClick={() => setIsOpen(true)}
                >
                  <Plus />
                </Button>
              </>
            ) : (
              <Tag variant="tertiary" asChild leftIcon={<Plus />}>
                <button type="button" onClick={() => setIsOpen(true)}>
                  Add label
                </button>
              </Tag>
            )}
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="z-aboveDialog max-w-[--radix-popper-anchor-width] sm:max-w-[400px]"
          align="start"
          onKeyDown={(e) => e.stopPropagation()}
          onKeyDownCapture={(e) => e.stopPropagation()}
        >
          <SearchInput
            {...register("name")}
            variant="sm"
            placeholder={
              workspaceLabels?.length ? "Search labels" : "Add new label"
            }
            maxLength={getSchemaMaxLength(LabelSchema.entries.name)}
            className="mx-3 mt-1"
            onClear={() => reset()}
          />

          {workspaceLabels?.length ? (
            <div className="flex max-h-72 flex-col overflow-y-auto scrollbar-thin">
              {filteredLabels.map((label) => {
                const { id, color, name } = label;
                const isSelected = !!labels.find(
                  (existingLabel) => existingLabel.id === id,
                );
                return (
                  <DropdownMenuItem
                    key={id}
                    onSelect={() => {
                      toggleLeadLabel(label, isSelected);
                    }}
                  >
                    <Checkbox
                      checked={isSelected}
                      size="sm"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      className="after:absolute after:-inset-y-3 after:-left-5 after:h-8 after:w-10"
                      onChange={() => toggleLeadLabel(label, isSelected)}
                    />
                    <div
                      className={clsx(
                        "ml-3 size-3.5 shrink-0 rounded",
                        labelColors[color].bgClassName,
                      )}
                    />
                    <span className="truncate">{name}</span>
                  </DropdownMenuItem>
                );
              })}
            </div>
          ) : (
            <p className="px-4 py-16 text-center text-caption-12-regular text-black-500">
              You don&apos;t have any labels yet.
              <br /> Start typing to create a label
            </p>
          )}
          <RenderIf
            condition={
              labelName &&
              !filteredLabels.find(({ name }) => name === labelName)
            }
          >
            <DropdownMenuItem
              onSelect={() => setIsCreatingNew(true)}
              className="break-all"
            >
              <PlusCircle size={20} weight="fill" />
              Create a new Label ‘{labelName}’
            </DropdownMenuItem>
          </RenderIf>
        </DropdownMenuContent>
      </DropdownMenu>
      <LabelColorSelection
        labelName={labelName}
        isOpen={isCreatingNew}
        setIsOpen={setIsCreatingNew}
        onColorSelect={(color) => {
          setValue("color", color);
          handleSubmit(createAndAddLabel)();
        }}
      />
    </>
  );
}
LeadLabels.displayName = "LeadLabels";

export default LeadLabels;
