import React from "react";

import { getProfileSubtitle } from "common/helpers/utils";
import FavoriteLead from "common/components/FavoriteLead";

import { Conversation } from "../../../types";

interface ConversationInfoProps {
  conversation: Conversation;
  groupParticipantsString: string | null;
  fullName: string;
}

export default function ConversationInfo({
  conversation,
  groupParticipantsString,
  fullName,
}: ConversationInfoProps) {
  const { participants } = conversation;

  return (
    <div className="flex flex-col items-center justify-center text-center">
      <div className="flex flex-row gap-1.5">
        <span className="line-clamp-1 max-w-[20ch] text-button-14 md:max-w-full">
          {groupParticipantsString || fullName}
        </span>

        <FavoriteLead
          leadId={participants[0].id}
          isLead={participants[0].is_lead}
          isFavorite={participants[0].favorite}
        />
      </div>

      <span className="line-clamp-1 max-w-[40ch] text-caption-12-regular text-black-600">
        {groupParticipantsString
          ? `${participants.length} members`
          : getProfileSubtitle(participants[0])}
      </span>
    </div>
  );
}
