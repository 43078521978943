import React from "react";
import { Star } from "@phosphor-icons/react";
import clsx from "clsx";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import useFavorite from "../../../pages/leads/datahooks/useFavorite";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/Tooltip";

interface FavoriteLeadProps {
  leadId: string;
  isFavorite: boolean;
  isLead?: boolean;
}

export default function FavoriteLead({
  leadId,
  isFavorite,
  isLead = true,
}: FavoriteLeadProps) {
  const { addFavorite, removeFavorite } = useFavorite();
  const isTabletOrDesktop = useTwBreakpoint("md");

  if (!isLead) {
    return (
      <TooltipProvider delayDuration={100}>
        <Tooltip>
          <TooltipTrigger>
            <Star size={14} weight="fill" className="text-black-300" />
          </TooltipTrigger>

          <TooltipContent
            side={isTabletOrDesktop ? "top" : "bottom"}
            className="w-fit"
          >
            <p>Only leads can be marked as favorites</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  const handleFavoriteClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (isFavorite) {
      removeFavorite({ leadId });
      return;
    }

    addFavorite({ leadId });
  };

  const ariaLabel = isFavorite ? "Remove from favorites" : "Add to favorites";

  return (
    <button
      type="button"
      className="group relative"
      aria-label={ariaLabel}
      onClick={handleFavoriteClick}
    >
      <Star
        className={clsx(
          "min-h-3.5 min-w-3.5 transition-colors duration-200",
          isFavorite
            ? "text-yellow-500 hover:text-yellow-600"
            : "text-black-300",
        )}
        weight="fill"
        size={14}
      />

      {!isFavorite && (
        <Star
          size={14}
          weight="fill"
          className="absolute left-0 top-0 z-10 opacity-0 transition-opacity duration-200 group-hover:opacity-100"
        />
      )}
    </button>
  );
}
