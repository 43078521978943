import React from "react";
import clsx from "clsx";

import { Button } from "common/components/ui/Button";
import {
  Dialog,
  DialogClose,
  DialogContent,
} from "common/components/ui/Dialog";
import RenderIf from "common/components/RenderIf";
import useWorkspaceMutations from "common/datahooks/useWorkspaceMutations";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
} from "common/components/ui/Drawer";

interface DeleteWorkspaceDialogProps {
  workspaceId: string;
  isDisabled: boolean;
  isDeletingWorkspace: boolean;
  setIsDeletingWorkspace: (isDeletingWorkspace: boolean) => void;
}

export default function DeleteWorkspaceDialog({
  workspaceId,
  isDisabled,
  isDeletingWorkspace,
  setIsDeletingWorkspace,
}: DeleteWorkspaceDialogProps) {
  const isDesktop = useTwBreakpoint("lg");

  const title = isDisabled
    ? "Cannot delete workspace"
    : "Are you sure you want to delete this workspace?";

  const { deleteWorkspace } = useWorkspaceMutations();

  const Component = isDesktop ? Dialog : Drawer;
  const Content = isDesktop ? DialogContent : DrawerContent;
  const Close = isDesktop ? DialogClose : DrawerClose;

  return (
    <Component open={isDeletingWorkspace} onOpenChange={setIsDeletingWorkspace}>
      <Content className={clsx("lg:w-[448px]", isDisabled && "pb-10")}>
        <h3 className="mb-2 text-center text-headline-2xl-bold lg:text-headline-xl-bold">
          {title}
        </h3>
        <p className="text-center text-black-500">
          {isDisabled ? (
            <>
              A workspace can only be deleted if it has no added LinkedIn
              accounts, and no active subscription. You must{" "}
              <span className="font-semibold text-black-900">
                remove all of your LinkedIn accounts and fully terminate your
                subscription
              </span>{" "}
              to delete this workspace
            </>
          ) : (
            "Deleting this workspace will completely remove all data related to this workspace. This is a non-reversible action"
          )}
        </p>

        <RenderIf condition={!isDisabled}>
          <div className="mt-8 grid grid-cols-1 gap-4 lg:grid-cols-2">
            <Close asChild>
              <Button size={isDesktop ? "md" : "lg"} variant="secondary-black">
                Cancel
              </Button>
            </Close>

            <Button
              size={isDesktop ? "md" : "lg"}
              variant="tertiary-danger"
              onClick={() => deleteWorkspace({ id: workspaceId })}
            >
              Delete
            </Button>
          </div>
        </RenderIf>
      </Content>
    </Component>
  );
}
