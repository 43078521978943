/* eslint-disable react/no-unstable-nested-components */
import React, { ReactNode, useMemo, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import {
  CaretRight,
  Check,
  ClockCounterClockwise,
} from "@phosphor-icons/react";

import ProfileImage from "common/components/ui/ProfileImage";
import { Tag, TagVariantType } from "common/components/ui/Tag";
import DataTable from "common/components/DataTable";
import { WorkspaceAccount } from "common/types";
import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import RenderIf from "common/components/RenderIf";
import Licenses from "common/components/Licenses";

import LicenseTag from "./LicenseTag";
import MobilePreview from "./MobilePreview";
import AccountSettings from "./AccountSettings";
import AccountActionsDropdown from "./AccountActionsDropdown";
import AccountActionsDialog from "./AccountActionsDialog";
import { AccountAction } from "../types";

export default function AccountsTable({
  accounts,
}: {
  accounts: WorkspaceAccount[];
}) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const [isOpen, setIsOpen] = useState(false);
  const [isLicenseOpen, setIsLicenseOpen] = useState(false);
  const [action, setAction] = useState<AccountAction>(null);
  const [selectedAccount, setSelectedAccount] =
    useState<WorkspaceAccount>(null);

  function onRowClick(account: WorkspaceAccount) {
    setSelectedAccount(account);
    setAction(isTabletOrDesktop ? "settings" : null);
    setIsOpen(true);
  }

  const columns = useMemo(() => {
    const templateColumns: ColumnDef<WorkspaceAccount>[] = [
      {
        header: "Account",
        cell: ({
          row: {
            original: {
              picture_url: pictureUrl,
              full_name: fullName,
              occupation,
              license,
            },
          },
        }) => (
          <div className="flex items-center gap-x-2 md:gap-x-3">
            <ProfileImage
              size={isTabletOrDesktop ? "lg" : "md"}
              src={pictureUrl}
            />

            <div className="flex flex-col gap-y-0.5 md:gap-y-1">
              <div className="flex items-center gap-x-2">
                <span className="line-clamp-1 break-all text-body-14-bold md:text-headline-lg-bold">
                  {fullName}
                </span>

                <LicenseTag license={license} className="md:hidden" />
              </div>

              <span className="line-clamp-1 break-all text-caption-12-regular text-black-700 md:text-body-14-regular md:text-black-600">
                {occupation}
              </span>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "state",
        header: isTabletOrDesktop ? "Login status" : "Status",
        cell: ({ getValue }) => {
          const isDisconnected = getValue() !== "LoggedIn";

          let tagContent: ReactNode = isDisconnected ? (
            <ClockCounterClockwise />
          ) : (
            <Check />
          );
          let variant: TagVariantType = isDisconnected
            ? "quaternary"
            : "success";

          if (isTabletOrDesktop) {
            tagContent = isDisconnected ? "Not connected" : "Connected";
            variant = isDisconnected ? "disabled" : "success";
          }

          return (
            <Tag variant={variant} className="max-md:p-0">
              {tagContent}
            </Tag>
          );
        },
      },
    ];

    if (isTabletOrDesktop) {
      templateColumns.push({
        header: "Outreach seat",
        cell: ({
          row: {
            original: { license },
          },
        }) => <LicenseTag license={license} />,
      });
      templateColumns.push({
        id: "action",
        cell: ({ row: { original } }) => {
          function handleLicenseModal() {
            setSelectedAccount(original);
            setIsLicenseOpen(true);
          }

          function setNewAction(newAction: AccountAction) {
            setSelectedAccount(original);
            setAction(newAction);
            setIsOpen(true);
          }

          return (
            <AccountActionsDropdown
              account={original}
              openLicenseModal={handleLicenseModal}
              setAction={setNewAction}
            />
          );
        },
      });
    } else {
      templateColumns.push({
        id: "action",
        cell: () => (
          <Button intent="labelIcon" size="sm" variant="quaternary-black">
            <CaretRight />
          </Button>
        ),
      });
    }

    return templateColumns;
  }, [isTabletOrDesktop]);

  let modalComponent;
  if (isTabletOrDesktop) {
    modalComponent =
      action === "settings" ? (
        <AccountSettings
          isOpen={isOpen}
          account={selectedAccount}
          setIsOpen={setIsOpen}
        />
      ) : (
        <AccountActionsDialog
          isOpen={isOpen}
          account={selectedAccount}
          action={action}
          onClose={() => {
            setIsOpen(false);
          }}
        />
      );
  } else {
    modalComponent = (
      <MobilePreview
        account={selectedAccount}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        action={action}
        setAction={setAction}
        openLicenseModal={() => setIsLicenseOpen(true)}
      />
    );
  }

  return (
    <>
      <DataTable columns={columns} data={accounts} onRowClick={onRowClick} />

      <RenderIf condition={!!selectedAccount}>
        {modalComponent}
        <Licenses
          isOpen={isLicenseOpen}
          setIsOpen={setIsLicenseOpen}
          accountId={selectedAccount?.id}
        />
      </RenderIf>
    </>
  );
}
