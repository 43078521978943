import React from "react";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { useForm } from "react-hook-form";

import { Input } from "common/components/ui/Input";
import { getSchemaMaxLength, typedObjectKeys } from "common/helpers/utils";
import { LabelSchema, LabelSchemaType } from "common/schemas";
import { Button } from "common/components/ui/Button";
import { labelColors } from "common/constants";
import { Label } from "common/types";
import useWorkspaceLabelsMutations from "common/datahooks/useWorkspaceLabelsMutations";
import RenderIf from "common/components/RenderIf";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import LabelColorPicker from "./LabelColorPicker";

interface LabelFormProps {
  label?: Label;
  onBack: () => void;
}

export default function LabelForm({ label = null, onBack }: LabelFormProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const {
    addWorkspaceLabel,
    isAddingWorkspaceLabel,
    updateWorkspaceLabel,
    isUpdatingWorkspaceLabel,
  } = useWorkspaceLabelsMutations();

  const isLoading = isAddingWorkspaceLabel || isUpdatingWorkspaceLabel;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<LabelSchemaType>({
    defaultValues: {
      name: label ? label.name : "",
      color: label ? label.color : typedObjectKeys(labelColors)[0],
    },
    resolver: valibotResolver(LabelSchema),
  });

  function onSave(newLabel: LabelSchemaType) {
    if (label) {
      updateWorkspaceLabel({ label: newLabel, labelId: label.id }).then(() =>
        onBack(),
      );
    } else {
      addWorkspaceLabel(newLabel).then(() => onBack());
    }
  }
  const selectedColor = watch("color");
  const updatedName = watch("name");

  return (
    <>
      <h2 className="mb-8 text-center text-headline-2xl-bold md:text-headline-xl-bold">
        {label ? "Edit" : "Create new"} label
      </h2>
      <Input
        placeholder="Label name"
        autoFocus
        className="mb-2"
        maxLength={getSchemaMaxLength(LabelSchema.entries.name)}
        variant="lg"
        error={errors.name?.message}
        data-cy="label-name-input"
        {...register("name")}
      />
      <LabelColorPicker
        selectedColor={selectedColor}
        setSelectedColor={(color) => setValue("color", color)}
      />

      <div className="mt-12 flex flex-col items-center gap-2 md:flex-row-reverse md:gap-4">
        <Button
          size={isTabletOrDesktop ? "md" : "lg"}
          className="flex-1 max-md:w-full"
          onClick={handleSubmit(onSave)}
          disabled={
            label && label.name === updatedName && label.color === selectedColor
          }
          isLoading={isLoading}
          data-cy="submit-label-button"
        >
          {label ? "Save" : "Create"}
        </Button>

        <RenderIf condition={!label}>
          <Button
            size={isTabletOrDesktop ? "md" : "lg"}
            className="flex-1 max-md:w-full"
            variant="tertiary-black"
            onClick={onBack}
            disabled={isLoading}
          >
            Discard
          </Button>
        </RenderIf>
      </div>
    </>
  );
}
