import React from "react";
import { ArrowSquareOut } from "@phosphor-icons/react";

import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import { Button } from "common/components/ui/Button";
import ErrorPlaceholder from "common/components/ErrorPlaceholder";
import RenderIf from "common/components/RenderIf";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import Loader from "common/components/Loader";

import useWebhooks from "../../datahooks/useWebhooks";
import NewWebhook from "./NewWebhook";
import WebhooksTable from "./WebhooksTable";

import EmptyPlaceholderImage from "assets/images/empty-placeholders/webhooks.png";

export default function Webhooks() {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const { webhooks, isLoadingWebhooks, isWebhooksError, refetchWebhooks } =
    useWebhooks();

  if (isWebhooksError) {
    return (
      <ErrorPlaceholder
        errorMessage="Could not display Webhooks"
        onRetry={refetchWebhooks}
      />
    );
  }

  if (isLoadingWebhooks) return <Loader />;

  return (
    <>
      {/* Heading */}
      <section className="grid gap-x-3 max-md:grid-cols-2 md:flex md:justify-end md:gap-x-4">
        <Button
          variant="secondary-purple"
          leftIcon={<ArrowSquareOut />}
          asChild
        >
          <a
            href="https://docs.aimfox.com/webhooks"
            target="_blank"
            rel="noreferrer"
          >
            Documentation
          </a>
        </Button>
        <RenderIf condition={!!webhooks.length || !isTabletOrDesktop}>
          <NewWebhook />
        </RenderIf>
      </section>

      {webhooks.length ? (
        <WebhooksTable webhooks={webhooks} />
      ) : (
        <EmptyPlaceholder
          imageSrc={EmptyPlaceholderImage}
          title="No webhooks found"
          subtitle="Add a webhook to connect Aimfox with your application"
          actionComponent={isTabletOrDesktop && <NewWebhook />}
        />
      )}
    </>
  );
}
