import React, { useState } from "react";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { useForm } from "react-hook-form";

import Label from "common/components/ui/Label";
import { Input } from "common/components/ui/Input";
import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import WebhookEventsSelect from "./WebhookEventsSelect";
import useWebhooks from "../../../datahooks/useWebhooks";
import {
  ProtectedWebhookFormSchema,
  WebhookFormSchema,
  WebhookFormType,
} from "../../../schema";
import { Webhook } from "../../../types";
import WebhookHeader from "./WebhookHeader";

interface WebhookFormProps {
  webhook?: Webhook;
  onBack: () => void;
}

export default function WebhookForm({
  webhook = null,
  onBack,
}: WebhookFormProps) {
  const { headers, name, url, events, id } = webhook ?? {};

  const [isProtected, setIsProtected] = useState(webhook ? !!headers : false);
  const isTabletOrDesktop = useTwBreakpoint("md");

  const { updateWebhook, isUpdatingWebhook, createWebhook, isCreatingWebhook } =
    useWebhooks({
      disableQuery: true,
    });

  let defaultValues: WebhookFormType;
  if (webhook) {
    defaultValues = {
      name,
      url,
      events,
      headers: {
        name: headers ? Object.keys(headers)[0] : "",
        value: headers ? Object.values(headers)[0] : "",
      },
    };
  } else {
    defaultValues = {
      name: "",
      url: "",
      events: [],
      headers: {
        name: "",
        value: "",
      },
    };
  }

  const {
    control,
    reset,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<WebhookFormType>({
    defaultValues,
    resolver: valibotResolver(
      isProtected ? ProtectedWebhookFormSchema : WebhookFormSchema,
    ),
  });

  function onSubmit(data: WebhookFormType) {
    const submittedWebhook = {
      ...data,
      headers:
        isProtected && "headers" in data
          ? {
              [data.headers.name]: data.headers.value,
            }
          : null,
    };

    if (webhook) {
      updateWebhook({
        webhookId: id,
        updates: submittedWebhook,
      }).then(() => onBack());
    } else {
      createWebhook({
        newWebhook: submittedWebhook,
      }).then(() => onBack());
    }
  }

  return (
    <>
      <h3 className="mb-8 text-center text-headline-xl-bold capitalize">
        {webhook ? "Edit" : "Add"} webhook
      </h3>

      {/* Name */}
      <Label htmlFor="name" className="mb-1">
        Name
      </Label>
      <Input
        id="name"
        placeholder="Enter name"
        variant="lg"
        className="mb-4"
        error={errors.name?.message}
        {...register("name")}
      />

      {/* URL */}
      <Label htmlFor="url" className="mb-1">
        URL
      </Label>
      <Input
        id="url"
        placeholder="Paste URL"
        variant="lg"
        className="mb-4"
        error={errors.url?.message}
        {...register("url")}
      />

      {/* Optional Authorization headers */}
      <WebhookHeader
        control={control}
        isProtected={isProtected}
        setIsProtected={setIsProtected}
      />

      {/* Events */}
      <span className="mb-1 text-body-14-regular text-black-700">Events</span>
      <WebhookEventsSelect
        selectedEvents={watch("events")}
        setSelectedEvents={(newEvents) =>
          setValue("events", newEvents, {
            shouldValidate: true,
          })
        }
      />
      {errors.events && (
        <span className="mt-1 text-caption-12-regular text-red-500">
          {errors.events.message}
        </span>
      )}

      <div className="mt-8 flex flex-col gap-2 md:flex-row-reverse md:gap-4">
        <Button
          className="flex-1"
          size={isTabletOrDesktop ? "md" : "lg"}
          isLoading={isUpdatingWebhook || isCreatingWebhook}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>

        <Button
          className="flex-1"
          variant="secondary-black"
          size={isTabletOrDesktop ? "md" : "lg"}
          disabled={isUpdatingWebhook || isCreatingWebhook}
          onClick={() => {
            onBack();
            reset();
          }}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}
