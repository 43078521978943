import React, { useMemo, useState } from "react";
import { CaretRight } from "@phosphor-icons/react";

import Skeleton from "common/components/ui/Skeleton";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import { Button } from "common/components/ui/Button";

import { ApiToken } from "../../types";
import TokenDetails from "./TokenDetails";

interface MobileListProps {
  tokens: ApiToken[];
  isLoading: boolean;
  onDelete: (apiToken: ApiToken) => void;
}

export default function MobileList({
  tokens,
  isLoading,
  onDelete,
}: MobileListProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedToken, setSelectedToken] = useState<ApiToken>(null);

  const handleDrawerState = (isOpen: boolean) => {
    setIsDrawerOpen(isOpen);
  };

  const handleRowClick = (apiToken: ApiToken) => {
    setSelectedToken(apiToken);
    setIsDrawerOpen(true);
  };

  const handleDelete = () => {
    setIsDrawerOpen(false);
    onDelete(selectedToken);
  };

  const tableData = useMemo(
    () => (isLoading ? new Array(3).fill({}) : tokens),
    [tokens, isLoading],
  );

  return (
    <div className="flex flex-col gap-y-2">
      <div className="grid grid-cols-3 rounded-xl bg-black-100 px-4 py-2">
        <span className="text-caption-12-regular">Key name</span>
        <span className="text-center text-caption-12-regular">Permission</span>
      </div>

      <div className="flex flex-col gap-2">
        {tableData.map((apiKey) => {
          const { id, name, read: canRead, write: canWrite } = apiKey;
          const permissions = canRead && canWrite ? "All" : "Read only";

          return (
            <button
              key={id}
              type="button"
              className="grid grid-cols-3 items-center gap-4 rounded-2xl border border-black-200 p-3"
              onClick={() => handleRowClick(apiKey)}
            >
              {/* Token name */}
              {isLoading ? (
                <Skeleton className="size-12 rounded-full" />
              ) : (
                <span className="line-clamp-1 break-words text-start text-headline-lg-bold">
                  {name}
                </span>
              )}

              {/* Permissions */}
              {isLoading ? (
                <Skeleton className="h-4 w-24" />
              ) : (
                <span className="capitalize text-black-700">{permissions}</span>
              )}

              {/* Action button */}
              <Button
                asChild
                className="ml-auto"
                intent="labelIcon"
                variant="quaternary-black"
              >
                <CaretRight />
              </Button>
            </button>
          );
        })}
      </div>

      {/* Selected token details & preview */}
      <Drawer open={isDrawerOpen} onOpenChange={handleDrawerState}>
        <DrawerContent>
          <TokenDetails
            selectedToken={selectedToken}
            onDelete={handleDelete}
            onClose={() => setIsDrawerOpen(false)}
          />
        </DrawerContent>
      </Drawer>
    </div>
  );
}
