import React, { useState } from "react";
import { ArrowSquareOut, PlusCircle } from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import RenderIf from "common/components/RenderIf";
import ErrorPlaceholder from "common/components/ErrorPlaceholder";

import { ApiToken } from "../../types";
import IntegrationItem from "./IntegrationItem";
import DesktopTable from "./DesktopTable";
import MobileList from "./MobileList";
import CreateTokenDialog from "./CreateTokenDialog";
import EditNameDialog from "./EditNameDialog";
import DeleteTokenDialog from "./DeleteTokenDialog";
import useApiTokens from "../../datahooks/useApiTokens";
import { integrationsList } from "../../constants";

import NoAPIKeysFound from "assets/images/empty-placeholders/no-api-key-found.png";

export default function Integrations() {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { tokens, isLoadingTokens, tokensError, refetchTokens } = useApiTokens({
    disableQuery: true,
  });

  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedToken, setSelectedToken] = useState<ApiToken>(null);

  if (tokensError) {
    return (
      <ErrorPlaceholder
        onRetry={refetchTokens}
        errorMessage="Could not display API tokens"
      />
    );
  }

  const Component = isTabletOrDesktop ? DesktopTable : MobileList;

  function handleEdit(apiToken: ApiToken): void {
    setSelectedToken(apiToken);
    setIsEditing(true);
  }

  function handleDelete(apiToken: ApiToken): void {
    setSelectedToken(apiToken);
    setIsDeleting(true);
  }

  function closeEditingDialog(isOpen: boolean): void {
    setSelectedToken(null);
    setIsEditing(isOpen);
  }

  const triggerButton = (
    <Button
      leftIcon={<PlusCircle weight="fill" />}
      size="md"
      className="flex-1"
      onClick={() => setIsCreating(true)}
    >
      Create API Key
    </Button>
  );

  return (
    <>
      <section className="flex flex-col gap-4">
        <h2 className="text-headline-xl-bold">Integrations</h2>

        {/* Integrations cards */}
        <div className="grid gap-3 md:grid-cols-3 md:gap-4">
          {integrationsList.map((integration) => (
            <IntegrationItem
              key={integration.title.toLowerCase()}
              integration={integration}
            />
          ))}
        </div>

        {/* Button with direction & create API */}
        <div className="mt-2 flex flex-row flex-wrap items-center justify-between gap-4">
          <h2 className="text-headline-xl-bold">API Keys</h2>

          <div className="flex w-full flex-row flex-wrap gap-2 sm:w-fit md:gap-4">
            <Button
              variant="secondary-purple"
              className="flex-1"
              asChild
              leftIcon={<ArrowSquareOut />}
            >
              <a
                href="https://docs.aimfox.com/"
                target="_blank"
                rel="noreferrer"
              >
                Documentation
              </a>
            </Button>

            <RenderIf condition={!!tokens?.length}>{triggerButton}</RenderIf>
          </div>
        </div>

        {!isLoadingTokens && !tokens?.length ? (
          <EmptyPlaceholder
            className="mt-6"
            title="No API keys found"
            subtitle="Create an API key to connect Aimfox with your application"
            imageSrc={NoAPIKeysFound}
            actionComponent={triggerButton}
          />
        ) : (
          <Component
            tokens={tokens}
            isLoading={isLoadingTokens}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        )}
      </section>

      <RenderIf condition={!isLoadingTokens}>
        {/* Creating dialog */}
        <CreateTokenDialog
          tokensLength={tokens?.length}
          isOpen={isCreating}
          onOpenChange={setIsCreating}
        />

        {/* Editing dialog */}
        <EditNameDialog
          key={`${selectedToken?.id}-edit`}
          apiToken={selectedToken}
          isOpen={isEditing}
          onOpenChange={closeEditingDialog}
        />

        {/* Deleting Dialog */}
        <DeleteTokenDialog
          key={`${selectedToken?.id}-delete`}
          apiToken={selectedToken}
          isOpen={isDeleting}
          onOpenChange={setIsDeleting}
        />
      </RenderIf>
    </>
  );
}
