import React, { useState } from "react";
import { PlusCircle } from "@phosphor-icons/react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "common/components/ui/Drawer";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "common/components/ui/Dialog";
import { Button } from "common/components/ui/Button";

interface SplitTestProps {
  duplicateFlow: () => void;
  addFlow: () => void;
}

export default function SplitTest({ duplicateFlow, addFlow }: SplitTestProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const [isOpen, setIsOpen] = useState(false);

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Trigger = isTabletOrDesktop ? DialogTrigger : DrawerTrigger;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  return (
    <Component open={isOpen} onOpenChange={setIsOpen}>
      <Trigger className="flex h-10 items-center rounded-xl border border-dashed border-black-300 px-4 text-button-14 md:h-12 md:px-6 md:text-button-16">
        <PlusCircle size={20} weight="fill" className="-ml-3 mr-2.5" />
        {isTabletOrDesktop ? "Create split test" : "Add flow"}
      </Trigger>
      <Content>
        <h3 className="mb-2 text-center text-headline-2xl-bold md:text-headline-xl-bold">
          Duplicate current flow?
        </h3>
        <p className="mb-12 text-center text-black-500">
          Do you want to copy your current flow or make a new one from scratch?
        </p>

        <div className="flex flex-col gap-2 md:flex-row-reverse md:gap-4">
          <Button
            size="lg"
            onClick={() => {
              duplicateFlow();
              setIsOpen(false);
            }}
            className="flex-1"
          >
            Duplicate flow
          </Button>
          <Button
            size="lg"
            variant="tertiary-black"
            onClick={() => {
              addFlow();
              setIsOpen(false);
            }}
            className="flex-1"
          >
            Create new
          </Button>
        </div>
      </Content>
    </Component>
  );
}
