import React, { FunctionComponent, SVGProps } from "react";
import { ArrowSquareOut } from "@phosphor-icons/react";

import { Tag } from "common/components/ui/Tag";
import { cn } from "common/helpers/utils";

interface IntegrationItemProps {
  title: string;
  description: string;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  redirectUrl?: string;
}

export default function IntegrationItem({
  integration,
}: {
  integration: IntegrationItemProps;
}) {
  const { title, description, icon, redirectUrl } = integration;

  const Component = redirectUrl ? "a" : "div";
  const IntegrationIcon = icon;

  return (
    <Component
      className={cn([
        "group flex flex-col gap-y-2 rounded-3xl border border-black-200 p-4 transition-all",
        redirectUrl && "hover:bg-black-50 hover:shadow-sm",
      ])}
      {...(redirectUrl && {
        href: redirectUrl,
        target: "_blank",
        rel: "noreferrer",
      })}
    >
      <div className="flex flex-row items-center justify-between gap-2">
        {/* Integration icon */}
        <IntegrationIcon />

        {/* Redirection button or tag */}
        {redirectUrl ? (
          <ArrowSquareOut
            size={24}
            className="text-black-400 group-hover:text-purple-500"
          />
        ) : (
          <Tag variant="gradient">Coming soon</Tag>
        )}
      </div>

      {/* Title */}
      <h2 className="text-headline-xl-bold">{title}</h2>

      {/* Description */}
      <p className="text-black-600">{description}</p>
    </Component>
  );
}
