import React, { useState } from "react";

import useCustomer from "common/datahooks/useCustomer";

import UpdateNameEdit from "./UpdateNameEdit";
import SettingsItemContainer from "../SettingsItemContainer";

export default function UpdateName() {
  const [isEditingName, setIsEditingName] = useState(false);
  const { customerInfo } = useCustomer();

  function getFullName() {
    const { first_name: firstName, last_name: lastName } = customerInfo;
    return `${firstName} ${lastName}`;
  }

  return (
    <SettingsItemContainer
      label="Full Name"
      isEditing={isEditingName}
      onEditClick={() => {
        setIsEditingName(true);
      }}
      editingComponent={
        <UpdateNameEdit
          isEditing={isEditingName}
          onClose={() => {
            setIsEditingName(false);
          }}
        />
      }
    >
      <span className="text-body-14-bold">{getFullName()}</span>
    </SettingsItemContainer>
  );
}
