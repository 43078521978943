import React, { ReactNode, useState } from "react";
import clsx from "clsx";

import { formatDate } from "common/helpers/utils";
import { DrawerClose } from "common/components/ui/Drawer";
import Avatar from "common/components/ui/Avatar";
import { Member } from "common/types";
import { Tag } from "common/components/ui/Tag";
import { Button } from "common/components/ui/Button";
import RenderIf from "common/components/RenderIf";
import { userRoles } from "common/constants";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import useCustomer from "common/datahooks/useCustomer";

import { INVITATION_STATUSES } from "../../constants";
import useWorkspaceMembers from "../../datahooks/useWorkspaceMembers";
import ChangeRoleDialog from "./ChangeRoleDialog";

interface MobileDrawerContentProps {
  member: Member;
  hasMemberActions: boolean;
}

export default function MobileDrawerContent({
  member,
  hasMemberActions,
}: MobileDrawerContentProps) {
  const [actionId, setActionId] = useState<"delete" | "revoke">();

  const {
    removeMember,
    leaveWorkspace,
    revokeInvite,
    resendInvite,
    isRemovingMember,
    isLeavingWorkspace,
    isRevokingInvite,
    isResendingInvite,
  } = useWorkspaceMembers({ disableQuery: true });

  const { name: workspaceName } = useSelectedWorkspaceContext();
  const [isChangingRole, setIsChangingRole] = useState(false);

  const {
    token: inviteToken,
    first_name: firstName,
    last_name: lastName,
    role,
    customer_id: memberId,
    email,
    created_at: createdAt,
  } = member;

  const { customerInfo } = useCustomer();
  const { email: customerEmail } = customerInfo;

  const renderStatusTag = () => {
    if (role === "owner") {
      return "-";
    }
    const { label } = INVITATION_STATUSES[member?.status];
    return <span>{label}</span>;
  };

  const roleData = userRoles[role];
  const { label: roleLabel, tagVariant: roleTagVariant } = roleData || {};

  let content = (
    <>
      <div className="flex flex-row items-center gap-2">
        <Avatar size="lg" variant={memberId ? "primary" : "quaternary"}>
          {memberId ? `${firstName[0]}${lastName[0]}` : "N/A"}
        </Avatar>

        <div className="flex flex-col gap-0.5">
          <div className="flex flex-row items-center gap-2 text-body-14-bold">
            <span
              className={clsx(
                "text-headline-xl-bold",
                !memberId && "text-black-300",
              )}
            >
              {memberId ? `${firstName} ${lastName}` : "New member"}
            </span>

            <Tag variant={roleTagVariant} className="capitalize">
              {roleLabel}
            </Tag>
          </div>

          <span className="line-clamp-1 max-w-[40ch] text-start text-black-500">
            {email}
          </span>
        </div>
      </div>

      <div className="-mx-4 my-4 h-px bg-black-200" />

      <div className="grid grid-cols-2">
        <div className="flex flex-col">
          <span className="capitalize text-black-700">Status</span>
          <span className="text-button-14">{renderStatusTag()}</span>
        </div>

        <div className="flex flex-col">
          <span className="w-1/2 capitalize text-black-700">Date added</span>

          <span className="text-button-14 capitalize">
            {formatDate(createdAt)}
          </span>
        </div>
      </div>

      <div className="mt-10 flex flex-col gap-2">
        <RenderIf condition={hasMemberActions}>
          {memberId ? (
            <>
              <RenderIf condition={customerEmail !== email}>
                <Button size="lg" onClick={() => setIsChangingRole(true)}>
                  Change role
                </Button>
              </RenderIf>

              <Button
                variant="tertiary-danger"
                size="lg"
                onClick={() => setActionId("delete")}
              >
                {customerEmail === email
                  ? "Leave workspace"
                  : "Remove from workspace"}
              </Button>
            </>
          ) : (
            <>
              <Button
                isLoading={isResendingInvite}
                variant="secondary-black"
                size="lg"
                onClick={() => {
                  resendInvite({ inviteToken });
                }}
              >
                Resend invite
              </Button>

              <Button
                variant="tertiary-danger"
                size="lg"
                onClick={() => setActionId("revoke")}
              >
                Revoke invite
              </Button>
            </>
          )}
        </RenderIf>
      </div>

      <ChangeRoleDialog
        isOpen={isChangingRole}
        currentRole={role}
        memberId={memberId}
        onOpenChange={setIsChangingRole}
      />
    </>
  );

  function handleRemoveClick(): void {
    if (customerEmail === email) {
      leaveWorkspace({ memberId });
      return;
    }

    if (actionId === "delete") {
      removeMember({ memberId });
      return;
    }

    revokeInvite({ inviteToken });
  }

  if (actionId) {
    let submitBtnLabel = "Remove";
    let title = "Remove from workspace?";
    let description: ReactNode = (
      <>
        Are you sure you want to remove this{" "}
        <span className="text-body-14-bold text-black-900">
          &apos;
          {email}
          &apos;
        </span>{" "}
        from {workspaceName}
      </>
    );

    if (actionId === "revoke") {
      title = "Revoke invite?";
      description = "This email will no longer be able to join your workspace";
      submitBtnLabel = "Revoke Invite";
    }

    if (customerEmail === email) {
      title = "Are you sure you want to leave this workspace?";
      description =
        "You will no longer be able to access this workspace or any data related to it";
      submitBtnLabel = "Leave workspace";
    }

    const isLoading =
      isRevokingInvite || isRemovingMember || isLeavingWorkspace;

    content = (
      <>
        <div className="mb-12 mt-6 flex flex-col items-center justify-center gap-2">
          <h3 className="text-center text-headline-2xl-bold text-black-950">
            {title}
          </h3>
          <span className="text-center text-black-500">{description}</span>
        </div>

        <div className="flex flex-col gap-2">
          <DrawerClose asChild>
            <Button
              variant="tertiary-danger"
              size="lg"
              isLoading={isLoading}
              onClick={handleRemoveClick}
            >
              {submitBtnLabel}
            </Button>
          </DrawerClose>

          <Button
            variant="secondary-black"
            size="lg"
            disabled={isLoading}
            onClick={() => {
              setActionId(null);
            }}
          >
            Cancel
          </Button>
        </div>
      </>
    );
  }

  return content;
}
