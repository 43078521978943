import { clsx } from "clsx";
import {
  ArrowUpRight,
  CaretRight,
  Check,
  PencilSimpleLine,
  X,
} from "@phosphor-icons/react";
import React from "react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { Link } from "react-router-dom";

import { Button } from "common/components/ui/Button";
import { Label } from "common/types";
import { LabelSchema, LabelSchemaType } from "common/schemas";
import { Input } from "common/components/ui/Input";
import { getSchemaMaxLength, typedObjectKeys } from "common/helpers/utils";
import { labelColors } from "common/constants";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import RenderIf from "common/components/RenderIf";
import useWorkspaceLabelsMutations from "common/datahooks/useWorkspaceLabelsMutations";

import LabelColorPicker from "./LabelColorPicker";
import LabelDropdownMenu from "./LabelDropdownMenu";

type LabelListItemProps = {
  label: Label;
  isEditing: boolean;
  onCancelEdit: () => void;
  onStartEdit: () => void;
};

export default function LabelListItem({
  label,
  isEditing,
  onStartEdit,
  onCancelEdit,
}: LabelListItemProps) {
  const isDesktopOrTablet = useTwBreakpoint("md");

  const { updateWorkspaceLabel, isUpdatingWorkspaceLabel } =
    useWorkspaceLabelsMutations();

  const { register, handleSubmit, reset, watch, setValue } =
    useForm<LabelSchemaType>({
      defaultValues: {
        name: label ? label.name : "",
        color: label ? label.color : typedObjectKeys(labelColors)[0],
      },
      resolver: valibotResolver(LabelSchema),
    });

  const selectedColor = watch("color");

  function cancelEdit() {
    onCancelEdit();
    reset();
  }

  function onSave(newLabel: LabelSchemaType) {
    updateWorkspaceLabel({ label: newLabel, labelId: label.id }).then(() =>
      onCancelEdit(),
    );
  }

  const isShowingEditLayout = (isEditing || !label) && isDesktopOrTablet;

  let actionComponent = (
    <Button variant="quaternary-black" intent="iconOnly">
      <CaretRight />
    </Button>
  );
  if (isShowingEditLayout) {
    actionComponent = (
      <>
        <Button
          intent="iconOnly"
          size="md"
          variant="tertiary-black"
          onClick={cancelEdit}
          disabled={isUpdatingWorkspaceLabel}
        >
          <X />
        </Button>
        <Button
          intent="iconOnly"
          size="md"
          variant="secondary-purple"
          onClick={handleSubmit(onSave)}
          isLoading={isUpdatingWorkspaceLabel}
          data-cy="submit-edit-label-button"
        >
          <Check />
        </Button>
      </>
    );
  } else if (isDesktopOrTablet) {
    actionComponent = (
      <>
        <Button
          intent="iconOnly"
          size="md"
          variant="tertiary-black"
          onClick={onStartEdit}
          data-cy="edit-label-button"
        >
          <PencilSimpleLine />
        </Button>
        <LabelDropdownMenu labelId={label.id} editLabel={onStartEdit} />
      </>
    );
  }

  const ContainerComponent = isDesktopOrTablet ? "div" : "button";

  return (
    <ContainerComponent
      className={clsx(
        "flex items-center gap-x-2 rounded-2xl border border-black-200 p-3",
        isShowingEditLayout && "shadow-sm",
      )}
      onClick={isDesktopOrTablet ? undefined : onStartEdit}
    >
      {isShowingEditLayout && isDesktopOrTablet ? (
        <LabelColorPicker
          selectedColor={selectedColor}
          setSelectedColor={(color) => setValue("color", color)}
          variant="picker"
        />
      ) : (
        <div
          className={clsx(
            "size-5 shrink-0 rounded",
            labelColors[label.color].bgClassName,
          )}
        />
      )}
      {isShowingEditLayout ? (
        <Input
          {...register("name")}
          className="grow"
          placeholder="Label name"
          autoFocus
          maxLength={getSchemaMaxLength(LabelSchema.entries.name)}
          data-cy="label-name-input"
        />
      ) : (
        <div className="flex grow items-center gap-x-2 overflow-hidden">
          <span className="truncate text-start text-button-14">
            {label.name}
          </span>
          <RenderIf condition={!!label.count}>
            <div className="h-4 w-px bg-black-400" />
            <Link
              className="group flex items-center gap-x-0.5 text-nowrap text-caption-12-regular text-black-400 hover:text-black-600"
              to={`../leads?labels=${encodeURIComponent(JSON.stringify([{ id: label.id, name: label.name }]))}`}
            >
              Used for {label.count} leads
              <ArrowUpRight className="size-4 group-hover:visible md:invisible" />
            </Link>
          </RenderIf>
        </div>
      )}
      {actionComponent}
    </ContainerComponent>
  );
}
