import React from "react";
import {
  DotsThreeVertical,
  PencilSimpleLine,
  Trash,
} from "@phosphor-icons/react";

import { Button } from "common/components/ui/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";

type Action = "edit" | "delete";

interface WebhookActionsDropdownProps {
  openActionModal: (action: Action) => void;
}

export default function WebhookActionsDropdown({
  openActionModal,
}: WebhookActionsDropdownProps) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          intent="iconOnly"
          variant="secondary-black"
          onClick={(e) => e.stopPropagation()}
        >
          <DotsThreeVertical />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" onClick={(e) => e.stopPropagation()}>
        <DropdownMenuItem onClick={() => openActionModal("edit")}>
          <PencilSimpleLine size={20} />
          Edit
        </DropdownMenuItem>
        <DropdownMenuItem
          className="text-red-400"
          onClick={() => openActionModal("delete")}
        >
          <Trash size={20} />
          Delete
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
