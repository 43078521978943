import React from "react";
import clsx from "clsx";

import RadioButton from "common/components/ui/RadioButton";
import { ApiKeyPermission } from "common/types";

interface PermissionOptionProps {
  option: ApiKeyPermission;
  selectedPermission: string;
  onPermissionChange: (permission: ApiKeyPermission) => void;
  label: string;
  description: string;
}

export default function PermissionOption({
  option,
  selectedPermission,
  onPermissionChange,
  label,
  description,
}: PermissionOptionProps) {
  return (
    <button
      type="button"
      aria-label={option}
      className={clsx([
        "grid cursor-pointer grid-flow-col gap-4 rounded-2xl p-3",
        selectedPermission === option ? "bg-black-50" : "bg-white",
      ])}
      onClick={() => onPermissionChange(option)}
    >
      <RadioButton
        id={option}
        className="mt-1"
        checked={selectedPermission === option}
        isStyleOnly
      />

      <div className="flex flex-col text-start">
        <span className="text-body-16-bold">{label}</span>

        <span className="mt-0.5 text-caption-12-regular text-black-700">
          {description}
        </span>
      </div>
    </button>
  );
}
