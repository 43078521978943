import React, { useState } from "react";

import { Button } from "common/components/ui/Button";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { DialogFooter } from "common/components/ui/Dialog";
import { DrawerFooter } from "common/components/ui/Drawer";
import { PaymentSource, PricingPeriodUnit } from "common/types";
import useLicenseMutations from "common/datahooks/useLicenseMutations";
import useSubscriptionMutations from "common/datahooks/useSubscriptionMutations";
import { PaymentMethod } from "common/components/PaymentMethod";
import RenderIf from "common/components/RenderIf";
import usePrimaryPaymentSource from "common/datahooks/usePrimaryPaymentSource";
import { isLifetimeDeal } from "common/helpers/utils";
import usePeriodUnitPricing from "common/hooks/usePeriodUnitPricing";
import useGTM from "common/hooks/useGTM";

import PricingInfo from "../PricingInfo";
import PrimaryPaymentSource from "./PrimaryPaymentSource";

interface SummaryProps {
  licenseCount: number;
  selectedPeriodUnit: PricingPeriodUnit;
  accountsToCancel: string[];
  goToFreeTrialSuccess: () => void;
  goToUpdateSubscriptionSuccess: () => void;
  setPaymentSource: (newPaymentSource: PaymentSource) => void;
  goBack: () => void;
  paymentSource: PaymentSource;
  accountId?: string;
}

export default function Summary({
  licenseCount,
  selectedPeriodUnit,
  accountsToCancel,
  goToFreeTrialSuccess,
  goToUpdateSubscriptionSuccess,
  setPaymentSource,
  goBack,
  accountId = null,
  paymentSource,
}: SummaryProps) {
  const { sendEvent } = useGTM();

  const { primaryPaymentSource, isLoadingPrimaryPaymentSource } =
    usePrimaryPaymentSource();
  const [isEditingPaymentSource, setIsEditingPaymentSource] = useState(false);

  const {
    subscription,
    licenses,
    license_count: workspaceLicenseCount,
  } = useSelectedWorkspaceContext();
  const { plan } = usePeriodUnitPricing(selectedPeriodUnit);

  const {
    setLicenseCount,
    isSettingLicenseCount,
    assignLicense,
    isAssigningLicense,
  } = useLicenseMutations();
  const { createSubscription, isCreatingSubscription } =
    useSubscriptionMutations();

  const isTabletOrDesktop = useTwBreakpoint("md");
  const Footer = isTabletOrDesktop ? DialogFooter : DrawerFooter;

  const selectedPaymentSource = primaryPaymentSource ?? paymentSource;

  const isDowngrade = licenseCount < workspaceLicenseCount;
  const shouldAddPaymentSource =
    !isLoadingPrimaryPaymentSource && !selectedPaymentSource && !isDowngrade;

  function getButtonTex() {
    if (shouldAddPaymentSource) {
      return "Add payment method";
    }

    if (
      (!subscription && selectedPeriodUnit === "year") ||
      subscription?.status === "cancelled"
    ) {
      return "Pay and subscribe";
    }

    if (!!subscription || isLifetimeDeal(licenses)) {
      return "Update subscription";
    }

    return "Start free trial";
  }

  function handleLicenseUpdate() {
    const updateOrCreateSubscription = subscription
      ? setLicenseCount({
          count: licenseCount,
          planId: plan.id,
          accountsToCancel,
        })
      : createSubscription({
          licenseCount,
          planId: plan.id,
          paymentSourceId: selectedPaymentSource.id,
        });

    updateOrCreateSubscription
      .then(() => {
        if (accountId) {
          assignLicense({ accountId });
        }
      })
      .then(() => {
        const isYearlyPlan = selectedPeriodUnit === "year";

        if (isYearlyPlan || subscription || isLifetimeDeal(licenses)) {
          goToUpdateSubscriptionSuccess();
        } else {
          sendEvent("free_trial_start");
          goToFreeTrialSuccess();
        }
      });
  }

  const isActionPending =
    isSettingLicenseCount || isCreatingSubscription || isAssigningLicense;

  function addPaymentSource(newPaymentSource: PaymentSource) {
    setPaymentSource(newPaymentSource);
    setIsEditingPaymentSource(false);
  }

  if (isEditingPaymentSource)
    return (
      <PaymentMethod
        paymentSource={selectedPaymentSource}
        onBack={() => setIsEditingPaymentSource(false)}
        onSelect={!subscription && setPaymentSource}
        onNext={!subscription && addPaymentSource}
      />
    );

  return (
    <>
      <h3 className="mb-8 text-center text-headline-2xl-bold">Summary</h3>

      <div className="flex flex-col gap-6 rounded-2xl bg-black-50 p-4">
        <PrimaryPaymentSource
          isLoading={isLoadingPrimaryPaymentSource}
          paymentSource={selectedPaymentSource}
          editPaymentSource={() => setIsEditingPaymentSource(true)}
        />

        <PricingInfo
          licenseCount={licenseCount}
          selectedPeriodUnit={selectedPeriodUnit}
        />
      </div>

      <Footer className="mt-6 md:-mx-16">
        <RenderIf condition={!accountId}>
          <Button
            size="lg"
            variant="secondary-black"
            onClick={goBack}
            disabled={isActionPending}
          >
            Back
          </Button>
        </RenderIf>
        <Button
          size="lg"
          className="ml-auto"
          onClick={
            shouldAddPaymentSource
              ? () => setIsEditingPaymentSource(true)
              : handleLicenseUpdate
          }
          isLoading={isActionPending || isLoadingPrimaryPaymentSource}
        >
          {getButtonTex()}
        </Button>
      </Footer>
    </>
  );
}
