import React from "react";

import { WorkspaceAccount } from "common/types";
import { Dialog, DialogContent } from "common/components/ui/Dialog";

import { AccountAction } from "../types";
import AccountActions from "./AccountActions";

interface AccountActionsDialogProps {
  isOpen: boolean;
  account: WorkspaceAccount;
  action: AccountAction;
  onClose: () => void;
}

export default function AccountActionsDialog({
  isOpen,
  account,
  action,
  onClose,
}: AccountActionsDialogProps) {
  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <DialogContent
        className={action !== "reconnect" && "md:w-[448px]"}
        onOpenAutoFocus={
          action !== "delete" ? undefined : (e) => e.preventDefault()
        }
      >
        <AccountActions
          account={account}
          action={action}
          goBack={onClose}
          onSuccess={onClose}
        />
      </DialogContent>
    </Dialog>
  );
}
