import { clsx } from "clsx";
import { EyedropperSample } from "@phosphor-icons/react";
import React from "react";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "common/components/ui/Popover";
import { labelColors } from "common/constants";
import { typedObjectEntries } from "common/helpers/utils";
import { LabelColor } from "common/types";

interface LabelColorPickerProps {
  selectedColor: LabelColor;
  setSelectedColor: (color: LabelColor) => void;
  variant?: "picker" | "stack";
}

export default function LabelColorPicker({
  selectedColor,
  setSelectedColor,
  variant = "stack",
}: LabelColorPickerProps) {
  const colorsComponent = (
    <div
      className={clsx(
        "flex justify-between gap-x-1 rounded-xl p-2",
        variant === "picker" && "w-fit",
      )}
    >
      {typedObjectEntries(labelColors).map(([key, { bgClassName, name }]) => (
        <button
          key={key}
          aria-label={name}
          className={clsx(
            "size-10 rounded-xl border p-1 md:rounded-lg",
            key === selectedColor ? "border-black-400" : "border-transparent",
            variant === "picker" && "size-8",
          )}
          type="button"
          onClick={() => setSelectedColor(key)}
        >
          <div className={clsx(bgClassName, "size-full min-w-0 rounded-md")} />
        </button>
      ))}
    </div>
  );

  if (variant === "picker") {
    return (
      <Popover>
        <PopoverTrigger className="flex size-10 rounded-xl border border-black-400 p-1">
          <div
            className={clsx(
              labelColors[selectedColor].textClassName,
              labelColors[selectedColor].bgClassName,
              "flex size-full items-center justify-center rounded-lg",
            )}
          >
            <EyedropperSample size={20} />
          </div>
        </PopoverTrigger>
        <PopoverContent asChild>{colorsComponent}</PopoverContent>
      </Popover>
    );
  }

  return colorsComponent;
}
