import React, { useState } from "react";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { useForm } from "react-hook-form";
import { ArrowCounterClockwise } from "@phosphor-icons/react";

import { Promotion as PromotionType } from "common/types";
import Label from "common/components/ui/Label";
import { Input } from "common/components/ui/Input";
import Alert from "common/components/ui/Alert";
import { AssignSeatsSchema, AssignSeatsSchemaType } from "common/schemas";
import { Button } from "common/components/ui/Button";
import usePromotionsMutations from "common/datahooks/usePromotionsMutations";
import useWorkspaces from "common/datahooks/useWorkspaces";
import RenderIf from "common/components/RenderIf";

import WorkspaceSelect from "./WorkspaceSelect";

function SeatsCount({ text, count }: { text: string; count: number }) {
  return (
    <div className="mt-4 flex items-center justify-between text-black-700">
      <span>{text}</span>
      <span className="text-body-14-bold">{count}</span>
    </div>
  );
}

interface WorkspacePromotionProps {
  promotion: PromotionType;
  onClose: () => void;
  onReset?: () => void;
  workspaceId?: string;
  closeText?: string;
}

export default function Promotion({
  promotion,
  onClose,
  onReset = null,
  workspaceId = undefined,
  closeText = "Close",
}: WorkspacePromotionProps) {
  const [selectedWorkspaceId, setSelectedWorkspaceId] =
    useState<string>(workspaceId);

  const { workspaces } = useWorkspaces();
  const { licenses, name } =
    workspaces.find(({ id }) => id === selectedWorkspaceId) ?? {};
  const { addLifetimeLicenses, isAddingLifetimeLicenses } =
    usePromotionsMutations();

  const { type, unassigned_license_count: seatsRemaining } = promotion;

  const canAssign =
    !!seatsRemaining &&
    (type !== "appsumo" || promotion.unassigned_license_count > 0);

  const assignedSeatsCount = selectedWorkspaceId
    ? licenses.filter(({ source }) => source === type).length
    : 0;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      seats: 1,
    },
    resolver: valibotResolver(AssignSeatsSchema),
  });

  function onAssign(data: AssignSeatsSchemaType) {
    if (data.seats > seatsRemaining) {
      setError("seats", { message: "You don't have that many licenses" });
    } else {
      addLifetimeLicenses({
        count: data.seats,
        source: type,
        workspaceId: selectedWorkspaceId,
      }).then(onClose);
    }
  }

  return (
    <>
      <h3 className="text-center text-headline-2xl-bold md:text-headline-lg-bold">
        Manage your lifetime seats
      </h3>
      {type === "appsumo" && (
        <p className="mt-2 text-center text-black-500">
          Workspaces remaining: {promotion.unassigned_workspaces}
        </p>
      )}

      <RenderIf condition={!!onReset}>
        <Button
          className="mb-4 ml-auto mt-8"
          variant="tertiary-black"
          leftIcon={<ArrowCounterClockwise />}
          onClick={onReset}
        >
          Reset seats and workspaces
        </Button>
      </RenderIf>

      {workspaceId ? (
        <>
          <span className="mb-1 mt-8 text-black-700">Workspace</span>
          <div className="flex h-12 shrink-0 items-center rounded-xl bg-whiteGray px-4 text-button-16 text-black-500">
            {name}
          </div>
        </>
      ) : (
        <>
          <Label htmlFor="licenses" className="mb-1">
            Workspace
          </Label>
          <WorkspaceSelect
            selectedWorkspaceId={selectedWorkspaceId}
            setSelectedWorkspaceId={setSelectedWorkspaceId}
          />
        </>
      )}

      <SeatsCount text="Seats on this workspace" count={assignedSeatsCount} />

      <Label htmlFor="licenses" className="mb-1 mt-8">
        Seats to add
      </Label>
      <Input
        name="licenses"
        variant="lg"
        type="number"
        error={errors.seats?.message}
        {...register("seats", {
          valueAsNumber: true,
        })}
      />
      <SeatsCount text="Total seats remaining" count={seatsRemaining} />
      <Alert
        className="mb-12 mt-4"
        variant="warning"
        title={canAssign ? "Add carefully" : "No seats left"}
        description={
          canAssign
            ? "Adding seats is a one-way street. If you want to re-allocate seats, you will have to reset and start from scratch"
            : "You have used up all of your lifetime seats. You can upgrade your package if your promotion is ongoing, or re-assign seats by resetting"
        }
      />

      <div className="flex flex-col gap-2 md:flex-row-reverse md:gap-4">
        <Button
          onClick={handleSubmit(onAssign)}
          className="flex-1"
          size="lg"
          isLoading={isAddingLifetimeLicenses}
          disabled={!canAssign || !selectedWorkspaceId}
        >
          Assign
        </Button>
        <Button
          variant="secondary-black"
          className="flex-1"
          size="lg"
          disabled={isAddingLifetimeLicenses}
          onClick={onClose}
        >
          {closeText}
        </Button>
      </div>
    </>
  );
}
