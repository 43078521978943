import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import debounce from "debounce";
import { StringParam, useQueryParam } from "use-query-params";
import { PlusCircle } from "@phosphor-icons/react";

import { Lead } from "common/types";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import ErrorPlaceholder from "common/components/ErrorPlaceholder";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import { Button } from "common/components/ui/Button";
import RenderIf from "common/components/RenderIf";
import { LinkedInLoginModal } from "common/components/LinkedInLogin";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import useConversations from "../datahooks/useConversations";
import MobileInbox from "./MobileInbox";
import DesktopInbox from "./DesktopInbox";
import useUnreadMessagesCount from "../../../common/datahooks/useUnreadMessagesCount";
import useLeadConversationUrn from "../datahooks/useLeadConversationUrn";
import useLead from "../../leads/datahooks/useLead";
import { Conversation } from "../types";

import EmptyPlaceholderImage from "assets/images/empty-placeholders/inbox.png";

export default function Inbox() {
  const isDesktop = useTwBreakpoint("lg");
  const { accounts } = useSelectedWorkspaceContext();
  const { state }: { state: { lead?: Lead; selectedAccountId?: string } } =
    useLocation();
  const [activeConversationUrn, setActiveConversationUrn] = useQueryParam(
    "activeConversation",
    StringParam,
  );

  const [activeAccountId, setActiveAccountId] = useQueryParam(
    "activeAccount",
    StringParam,
  );

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [keywordsInputValue, setKeywordsInputValue] = useState("");
  const [keywords, setKeywords] = useState("");
  const [selectedAccountIds, setSelectedAccountIds] = useState([]);
  const [selectedCampaignIds, setSelectedCampaignIds] = useState([]);
  const [selectedLabelIds, setSelectedLabelIds] = useState([]);
  const { unreadMessagesCount, resetUnreadMessages } = useUnreadMessagesCount();

  const [filteredBy, setFilteredBy] = useState<"all" | "favorite" | "unread">(
    "all",
  );

  const {
    conversations,
    isLoadingConversations,
    loadNextConversations,
    hasMoreConversations,
    conversationsError,
    refetchConversations,
  } = useConversations({
    keywords,
    selectedAccountIds,
    selectedCampaignIds,
    selectedLabelIds,
    filteredBy,
  });

  const [isStateLeadEnabled, setIsStateLeadEnabled] = useState(true);

  const leadConversationUrn = useLeadConversationUrn({
    leadId: state?.lead.id,
    accountId: state?.lead.lead_of[0],
  });

  const { lead } = useLead({ profileId: state?.lead.id });

  function clearKeywords(): void {
    setKeywords("");
    setKeywordsInputValue("");
  }

  const onInputValueChange = useCallback(debounce(setKeywords, 800), []);

  useEffect(() => {
    onInputValueChange(keywordsInputValue);
  }, [keywordsInputValue]);

  useEffect(() => {
    if (unreadMessagesCount) {
      resetUnreadMessages();
    }
  }, [unreadMessagesCount]);

  const activeConversation: Conversation = useMemo(() => {
    if (conversations && activeConversationUrn) {
      return conversations.find(
        ({ conversation_urn, owner: accountId }) =>
          conversation_urn === activeConversationUrn &&
          accountId === activeAccountId,
      );
    }

    if (isStateLeadEnabled && state?.lead) {
      return {
        conversation_urn: leadConversationUrn,
        participants: [lead || { ...state.lead, is_lead: true }],
        unread_count: 0,
        last_activity_at: Date.now(),
        owner: state.selectedAccountId || state.lead.lead_of[0],
      };
    }
  }, [conversations, lead, leadConversationUrn, activeConversationUrn]);

  if (conversationsError)
    return (
      <ErrorPlaceholder
        onRetry={refetchConversations}
        errorMessage="Could not display Conversations"
      />
    );

  const isConversationsEmpty =
    !isLoadingConversations &&
    (!conversations || !conversations.length) &&
    !keywords;

  const isEmptyState =
    isConversationsEmpty &&
    !selectedAccountIds.length &&
    !selectedCampaignIds.length &&
    !selectedLabelIds.length &&
    !keywords &&
    filteredBy === "all";

  if (isEmptyState) {
    return (
      <>
        <EmptyPlaceholder
          imageSrc={EmptyPlaceholderImage}
          title={
            accounts.length
              ? "No conversations to display"
              : "Add a LinkedIn account to start chatting with your leads"
          }
          subtitle={
            accounts.length
              ? "It looks like you do not have any active LinkedIn conversations"
              : "Inbox will automatically sync with LinkedIn so you can chat with your leads directly in Aimfox"
          }
          actionComponent={
            accounts.length ? (
              <Button asChild>
                <Link to="../campaigns">Go to campaigns</Link>
              </Button>
            ) : (
              <Button
                leftIcon={<PlusCircle weight="fill" />}
                onClick={() => setIsLoginModalOpen(true)}
              >
                Connect account
              </Button>
            )
          }
        />
        <RenderIf condition={!accounts.length}>
          <LinkedInLoginModal
            isOpen={isLoginModalOpen}
            onClose={() => setIsLoginModalOpen(false)}
          />
        </RenderIf>
      </>
    );
  }

  const Component = isDesktop ? DesktopInbox : MobileInbox;

  return (
    <Component
      activeConversation={activeConversation}
      setActiveConversationUrn={(urn, accountId) => {
        setActiveConversationUrn(urn);
        setActiveAccountId(accountId);
        setIsStateLeadEnabled(false);
      }}
      conversations={conversations}
      loadMoreConversations={loadNextConversations}
      keywordsInputValue={keywordsInputValue}
      setKeywordsInputValue={setKeywordsInputValue}
      keywords={keywords}
      clearKeywords={clearKeywords}
      isLoadingConversations={isLoadingConversations}
      hasMoreConversations={hasMoreConversations}
      selectedAccountIds={selectedAccountIds}
      setSelectedAccountIds={setSelectedAccountIds}
      selectedCampaignIds={selectedCampaignIds}
      setSelectedCampaignIds={setSelectedCampaignIds}
      selectedLabelIds={selectedLabelIds}
      setSelectedLabelIds={setSelectedLabelIds}
      filteredBy={filteredBy}
      setFilteredBy={setFilteredBy}
    />
  );
}
