import { toast } from "react-toastify";
import { Export } from "@phosphor-icons/react";
import React, { useState } from "react";

import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";

import useLeadMutations from "../../datahooks/useLeadMutations";
import { LeadsSearchParams, SelectedFacetParam } from "../../types";

export default function ExportLeads({
  searchParams,
  totalLeads,
  isDisabled,
}: {
  searchParams: LeadsSearchParams<SelectedFacetParam>;
  totalLeads: number;
  isDisabled: boolean;
}) {
  const isDesktopOrTablet = useTwBreakpoint("md");

  const [exportUrl, setExportUrl] = useState<string>();
  const { exportLeads, isExportingLeads } = useLeadMutations();
  function onClick() {
    exportLeads({ searchParams }).then((url) => {
      setExportUrl(url);
    });
    toast("Your export is preparing, you will be notified when it's ready");
  }

  const Component = isDesktopOrTablet ? Dialog : Drawer;
  const Content = isDesktopOrTablet ? DialogContent : DrawerContent;

  return (
    <>
      <Button
        variant="secondary-purple"
        leftIcon={<Export weight="fill" />}
        disabled={isDisabled}
        isLoading={isExportingLeads}
        onClick={onClick}
      >
        Export ({totalLeads})
      </Button>
      <Component
        open={!!exportUrl}
        onOpenChange={(open) => {
          if (!open) {
            setExportUrl(undefined);
          }
        }}
      >
        <Content className="md:w-[448px]">
          <h2 className="mb-2 text-center text-headline-2xl-bold md:text-headline-xl-bold">
            Your export is ready
          </h2>
          <p className="mb-8 text-center text-black-500">
            Click the button below to download your export in CSV format
          </p>
          <Button
            size={isDesktopOrTablet ? "md" : "lg"}
            onClick={() => {
              window.open(exportUrl);
              setExportUrl(null);
            }}
          >
            Download
          </Button>
        </Content>
      </Component>
    </>
  );
}
