import { useQuery } from "@tanstack/react-query";

import { get } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { Label } from "common/types";
import getQueryKeys from "common/datahooks/getQueryKeys";

async function getLabels(workspaceId: string) {
  return (await get(`workspaces/${workspaceId}/labels`)).labels;
}

export default function useWorkspaceLabels() {
  const { id: workspaceId } = useSelectedWorkspaceContext();

  const { labelsKeys } = getQueryKeys(workspaceId);

  const {
    data: workspaceLabels,
    isLoading: isLoadingWorkspaceLabels,
    isError: isWorkspaceLabelsError,
    refetch: refetchWorkspaceLabels,
  } = useQuery<Label[]>({
    queryKey: labelsKeys.list,
    queryFn: () => getLabels(workspaceId),
  });

  return {
    workspaceLabels,
    isLoadingWorkspaceLabels,
    isWorkspaceLabelsError,
    refetchWorkspaceLabels,
  };
}
