import React from "react";

import {
  Dialog,
  DialogClose,
  DialogContent,
} from "common/components/ui/Dialog";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
} from "common/components/ui/Drawer";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { Button } from "common/components/ui/Button";

import useApiTokens from "../../datahooks/useApiTokens";
import { ApiToken } from "../../types";

interface DeleteTokenDialogProps {
  apiToken: ApiToken;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

export default function DeleteTokenDialog({
  apiToken,
  isOpen,
  onOpenChange,
}: DeleteTokenDialogProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;
  const Close = isTabletOrDesktop ? DialogClose : DrawerClose;

  const { name, id } = apiToken || {};
  const { deleteApiToken, isDeletingToken } = useApiTokens({
    disableQuery: true,
  });

  const handleDelete = () => {
    deleteApiToken(id).then(() => {
      onOpenChange(false);
    });
  };

  return (
    <Component open={isOpen} onOpenChange={onOpenChange}>
      <Content className="flex flex-col items-center justify-center gap-4 md:max-w-[448px]">
        {/* Title */}
        <h2 className="text-center text-headline-xl-bold">Delete API Key?</h2>

        {/* Subtitle */}
        <p className="mb-8 mt-4 text-center text-black-500 md:mb-4 md:mt-0">
          Are you sure you want to delete the API key named{" "}
          <span className="text-black text-body-14-bold">
            {name?.length > 20 ? `${name?.slice(0, 30)}...` : name}
          </span>
          ? This action cannot be undone. Deleting this key will immediately
          revoke access for any applications or services using it
        </p>

        {/* Action buttons */}
        <div className="grid w-full grid-flow-row gap-2 md:grid-cols-2 md:gap-4">
          <Close className="order-2 md:order-1" asChild>
            <Button
              variant="secondary-black"
              size="lg"
              disabled={isDeletingToken}
            >
              Cancel
            </Button>
          </Close>

          <Button
            className="order-1 md:order-2"
            variant="tertiary-danger"
            size="lg"
            onClick={handleDelete}
            isLoading={isDeletingToken}
          >
            Delete
          </Button>
        </div>
      </Content>
    </Component>
  );
}
