import { useMutation, useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";
import { toast } from "react-toastify";

import { post, del, patch } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { Label } from "common/types";
import getQueryKeys from "common/datahooks/getQueryKeys";
import useUpdateLeads from "common/datahooks/useUpdateLeads";
import { LabelSchemaType } from "common/schemas";

async function addLabel(
  workspaceId: string,
  label: LabelSchemaType,
): Promise<Label> {
  return (await post(`workspaces/${workspaceId}/labels`, label)).label;
}
async function deleteLabel(workspaceId: string, labelId: string) {
  await del(`workspaces/${workspaceId}/labels/${labelId}`);
}
async function updateLabel(
  workspaceId: string,
  labelId: string,
  updatedLabel: LabelSchemaType,
) {
  await patch(`workspaces/${workspaceId}/labels/${labelId}`, updatedLabel);
}

export default function useWorkspaceLabelsMutations() {
  const queryClient = useQueryClient();
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const updateLeads = useUpdateLeads();

  const { labelsKeys } = getQueryKeys(workspaceId);

  const { mutateAsync: addWorkspaceLabel, isPending: isAddingWorkspaceLabel } =
    useMutation({
      mutationFn: (label: LabelSchemaType) => addLabel(workspaceId, label),
      onSuccess: (label) => {
        toast.success("Label added successfully");

        queryClient.setQueryData(labelsKeys.list, (prevLabels: Label[]) => [
          label,
          ...prevLabels,
        ]);
      },
      onError: (error) =>
        toast.error(
          "data" in error && error.data === "LabelNameTaken"
            ? "Label name already taken"
            : "Error adding label",
        ),
    });

  const {
    mutateAsync: deleteWorkspaceLabel,
    isPending: isDeletingWorkspaceLabel,
  } = useMutation({
    mutationFn: ({ labelId }: { labelId: string }) =>
      deleteLabel(workspaceId, labelId),
    onSuccess: (_, { labelId }) => {
      toast.success("Label deleted successfully");

      queryClient.setQueryData(labelsKeys.list, (prevLabels: Label[]) =>
        prevLabels.filter(({ id }) => id !== labelId),
      );
      updateLeads((draftLead) => {
        draftLead.labels = draftLead.labels.filter(({ id }) => id !== labelId);
      });
    },
    onError: () => toast.error("Error deleting label"),
  });

  const {
    mutateAsync: updateWorkspaceLabel,
    isPending: isUpdatingWorkspaceLabel,
  } = useMutation({
    mutationFn: ({
      labelId,
      label,
    }: {
      labelId: string;
      label: LabelSchemaType;
    }) => updateLabel(workspaceId, labelId, label),
    onSuccess: (_, { labelId, label }) => {
      toast.success("Label updated successfully");

      queryClient.setQueryData(labelsKeys.list, (prevLabels: Label[]) =>
        produce(prevLabels, (draftState) => {
          for (let i = 0; i < draftState.length; i += 1) {
            if (draftState[i].id === labelId) {
              draftState[i] = { ...draftState[i], ...label };
            }
          }
        }),
      );
      updateLeads((draftLead) => {
        for (let i = 0; i < draftLead.labels.length; i += 1) {
          if (draftLead.labels[i].id === labelId) {
            draftLead.labels[i] = { ...draftLead.labels[i], ...label };
          }
        }
      });
    },
    onError: (error) =>
      toast.error(
        "data" in error && error.data === "LabelNameTaken"
          ? "Label name already taken"
          : "Error updating label",
      ),
  });
  return {
    addWorkspaceLabel,
    isAddingWorkspaceLabel,
    deleteWorkspaceLabel,
    isDeletingWorkspaceLabel,
    updateWorkspaceLabel,
    isUpdatingWorkspaceLabel,
  };
}
