import React from "react";
import { Trash } from "@phosphor-icons/react";

import ProfileImage from "common/components/ui/ProfileImage";
import { getProfileSubtitle } from "common/helpers/utils";
import RestrictedComponent from "common/components/RestrictedComponent";
import { Button } from "common/components/ui/Button";
import { LinkedInProfile } from "common/types";

interface BlacklistAccountItemProps {
  profile: LinkedInProfile;
  onRemove: () => void;
}

export default function BlacklistAccountItem({
  profile,
  onRemove,
}: BlacklistAccountItemProps) {
  return (
    <div className="flex flex-row items-center justify-between rounded-2xl border border-black-200 px-3 py-3.5">
      <div className="flex flex-row items-center gap-3">
        <ProfileImage size="lg" src={profile.picture_url} />

        <div className="flex flex-col gap-0.5">
          <span className="text-headline-lg-bold">{profile.full_name}</span>
          <span className="line-clamp-1 text-black-500">
            {getProfileSubtitle(profile)}
          </span>
        </div>
      </div>

      <RestrictedComponent disabledForRoles={[]} hiddenForRoles={["member"]}>
        <Button
          intent="iconOnly"
          variant="quaternary-danger"
          onClick={onRemove}
          data-cy="remove-from-blacklist-button"
        >
          <Trash />
        </Button>
      </RestrictedComponent>
    </div>
  );
}
