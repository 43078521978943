import { useInfiniteQuery } from "@tanstack/react-query";
import { useState } from "react";

import { get } from "common/helpers/HTTP";
import getQueryKeys from "common/datahooks/getQueryKeys";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";

import { Conversation } from "../types";

const itemsPerPage = 15;

async function getConversations(
  workspaceId: string,
  keywords?: string,
  selectedAccountIds?: string[],
  selectedCampaignIds?: string[],
  selectedLabelIds?: string[],
  timestamp?: number | "now",
  filteredBy?: "all" | "favorite" | "unread",
): Promise<Conversation[]> {
  const searchParams = new URLSearchParams();
  searchParams.append("count", itemsPerPage.toString());
  searchParams.append(
    "before",
    (timestamp === "now" ? Date.now() : timestamp).toString(),
  );

  if (keywords) {
    searchParams.append("keywords", keywords);
  }

  if (selectedAccountIds?.length) {
    searchParams.append("account_ids", JSON.stringify(selectedAccountIds));
  }

  if (selectedCampaignIds?.length) {
    searchParams.append("campaigns", JSON.stringify(selectedCampaignIds));
  }

  if (selectedLabelIds?.length) {
    searchParams.append("labels", JSON.stringify(selectedLabelIds));
  }

  if (filteredBy === "favorite") {
    searchParams.append("favorite", "true");
  }

  if (filteredBy === "unread") {
    searchParams.append("unread", "true");
  }

  return (
    await get(
      `workspaces/${workspaceId}/conversations?${searchParams.toString()}`,
    )
  ).conversations;
}
export default function useConversations({
  keywords,
  selectedAccountIds = [],
  selectedCampaignIds = [],
  selectedLabelIds = [],
  filteredBy = "all",
}: {
  keywords?: string;
  selectedAccountIds?: string[];
  selectedCampaignIds?: string[];
  selectedLabelIds?: string[];
  filteredBy?: "all" | "favorite" | "unread";
} = {}) {
  const { id: workspaceId, accounts } = useSelectedWorkspaceContext();
  const { conversationsKeys } = getQueryKeys(workspaceId);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const {
    isLoading: isLoadingConversations,
    data: conversations,
    fetchNextPage: fetchNextConversations,
    hasNextPage: hasMoreConversations,
    error: conversationsError,
    refetch: refetchConversations,
  } = useInfiniteQuery<Conversation[], number | "now">({
    queryKey: [
      ...conversationsKeys.list(),
      {
        keywords,
        selectedAccountIds,
        selectedCampaignIds,
        selectedLabelIds,
        filteredBy,
      },
    ],
    queryFn: ({ pageParam }) =>
      getConversations(
        workspaceId,
        keywords,
        selectedAccountIds,
        selectedCampaignIds,
        selectedLabelIds,
        pageParam as number | "now",
        filteredBy,
      ),
    refetchOnMount: false,
    initialPageParam: "now",
    enabled: !!accounts.length,
    getNextPageParam: (lastPage) => {
      if (lastPage.length) {
        return lastPage[lastPage.length - 1].last_message.created_at;
      }
      return undefined;
    },
  });

  const loadNextConversations = async () => {
    if (isLoadingMore) return;

    try {
      setIsLoadingMore(true);
      await fetchNextConversations();
    } finally {
      setIsLoadingMore(false);
    }
  };

  return {
    conversations: conversations?.pages.flat() as Conversation[],
    isLoadingConversations,
    loadNextConversations,
    hasMoreConversations,
    conversationsError,
    refetchConversations,
  };
}
