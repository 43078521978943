import React from "react";

import { Button } from "common/components/ui/Button";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import useWorkspaceLabelsMutations from "common/datahooks/useWorkspaceLabelsMutations";

export default function DeleteLabel({
  labelId,
  onBack,
}: {
  labelId: string;
  onBack: () => void;
}) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const { deleteWorkspaceLabel, isDeletingWorkspaceLabel } =
    useWorkspaceLabelsMutations();

  function onDelete() {
    deleteWorkspaceLabel({ labelId }).then(() => {
      onBack();
    });
  }

  return (
    <>
      <h2 className="mb-2 text-center text-headline-2xl-bold md:text-headline-xl-bold">
        Delete label
      </h2>
      <p className="mb-12 text-center text-body-14-regular text-black-500">
        Deleting this label will remove it from all contacts it is currently
        applied to. This cannot be changed later. Are you sure you want to
        proceed?
      </p>
      <div className="flex flex-col max-md:gap-y-2 md:flex-row-reverse md:gap-x-4">
        <Button
          onClick={onDelete}
          variant="tertiary-danger"
          className="flex-1"
          isLoading={isDeletingWorkspaceLabel}
          size={isTabletOrDesktop ? "md" : "lg"}
          data-cy="delete-label-button"
        >
          Delete
        </Button>
        <Button
          onClick={onBack}
          variant="secondary-black"
          className="flex-1"
          disabled={isDeletingWorkspaceLabel}
          size={isTabletOrDesktop ? "md" : "lg"}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}
