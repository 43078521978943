import React from "react";
import { clsx } from "clsx";

import { Dialog, DialogContent } from "common/components/ui/Dialog";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import { typedObjectEntries } from "common/helpers/utils";
import { labelColors } from "common/constants";
import { LabelColor } from "common/types";

interface LabelColorSelectionProps {
  labelName: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onColorSelect: (color: LabelColor) => void;
}
export default function LabelColorSelection({
  labelName,
  isOpen,
  setIsOpen,
  onColorSelect,
}: LabelColorSelectionProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const Component = isTabletOrDesktop ? Dialog : Drawer;
  const Content = isTabletOrDesktop ? DialogContent : DrawerContent;

  return (
    <Component open={isOpen} onOpenChange={setIsOpen}>
      <Content className="md:w-[448px]">
        <h3 className="mb-8 break-words text-center text-headline-2xl-bold md:text-headline-xl-bold">
          Pick a color for ‘{labelName}’
        </h3>
        <div className="-mx-4 flex flex-col gap-y-2">
          {typedObjectEntries(labelColors).map(
            ([key, { bgClassName, name }]) => (
              <button
                key={key}
                aria-label={name}
                type="button"
                className="flex items-center gap-x-2 px-6 py-2 text-button-14 capitalize hover:bg-black-50"
                onClick={() => {
                  onColorSelect(key);
                }}
              >
                <div className={clsx(bgClassName, "size-3.5 rounded")} />
                {name}
              </button>
            ),
          )}
        </div>
      </Content>
    </Component>
  );
}
