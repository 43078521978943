import React, { useMemo } from "react";
import { AxisBottom, AxisLeft, type AxisScale } from "@visx/axis";

import { formatTime, getMonthName, tailwindTheme } from "common/helpers/utils";
import { Metrics } from "common/types";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

export default function Axes({
  metrics,
  height,
  xScale,
  yScale,
  isHourly,
}: {
  metrics: (Metrics & { timestamp: number })[];
  height: number;
  xScale: AxisScale;
  yScale: AxisScale;
  isHourly: boolean;
}) {
  const isTablet = useTwBreakpoint("md");
  const isDesktop = useTwBreakpoint("lg");

  const xTickValues = useMemo(() => {
    let maxTicks = 8;
    if (isTablet) {
      maxTicks = 16;
    }
    if (isDesktop) {
      maxTicks = 30;
    }
    const divideBy =
      metrics.length > maxTicks ? Math.ceil(metrics.length / maxTicks) : 1;
    const values = [];
    for (let i = 0; i < metrics.length; i += divideBy) {
      values.push(metrics[i].timestamp);
    }
    return values;
  }, [metrics, isTablet, isDesktop]);

  const domainRange = yScale.domain()[1] - yScale.domain()[0];
  const yTicksCount = domainRange > 10 ? null : domainRange;

  return (
    <>
      <AxisLeft
        tickFormat={(timestamp: number) => Math.round(timestamp).toString()}
        numTicks={yTicksCount}
        scale={yScale}
        hideTicks
        tickLabelProps={{
          fontFamily: tailwindTheme.fontFamily.manrope[0],
          className: "stroke-black-950",
          strokeWidth: 0.5,
          fontSize: 14,
        }}
        hideAxisLine
      />
      <AxisBottom
        scale={xScale}
        top={height}
        tickFormat={(timestamp: number) => {
          const date = new Date(timestamp);
          return isHourly
            ? formatTime(date.getTime())
            : `${getMonthName(date.getMonth(), true)} ${date.getDate()}`;
        }}
        hideTicks
        hideAxisLine
        tickLabelProps={{
          fontFamily: tailwindTheme.fontFamily.manrope[0],
          className: "stroke-black-400",
          fontSize: 10,
          dy: 6,
          strokeWidth: 0.5,
        }}
        tickValues={xTickValues}
      />
    </>
  );
}
