import React, { useState } from "react";

import SearchInput from "common/components/SearchInput";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import useWorkspaceLabels from "common/datahooks/useWorkspaceLabels";
import RenderIf from "common/components/RenderIf";
import ErrorPlaceholder from "common/components/ErrorPlaceholder";
import Loader from "common/components/Loader";
import EmptyPlaceholder from "common/components/EmptyPlaceholder";
import { Button } from "common/components/ui/Button";

import LabelListItem from "./LabelListItem";
import LabelDrawer from "./LabelDrawer";
import NewLabel from "./NewLabel";

import EmptyPlaceholderImage from "assets/images/empty-placeholders/new-template.png";
import NoResultsImage from "assets/images/empty-placeholders/result-not-found.png";

export default function Labels() {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const [searchValue, setSearchValue] = useState("");
  const [selectedLabelId, setSelectedLabelId] = useState<string>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const {
    workspaceLabels,
    isLoadingWorkspaceLabels,
    isWorkspaceLabelsError,
    refetchWorkspaceLabels,
  } = useWorkspaceLabels();

  if (isWorkspaceLabelsError)
    return (
      <ErrorPlaceholder
        errorMessage="Could not display Labels"
        onRetry={refetchWorkspaceLabels}
      />
    );

  if (isLoadingWorkspaceLabels) return <Loader />;

  if (!workspaceLabels.length)
    return (
      <EmptyPlaceholder
        imageSrc={EmptyPlaceholderImage}
        title="No labels found"
        subtitle="Create a label to start organizing your leads"
        actionComponent={<NewLabel />}
      />
    );

  const filteredLabel = workspaceLabels.filter(({ name }) =>
    searchValue ? name.toLowerCase().includes(searchValue.toLowerCase()) : true,
  );

  function clearSearch() {
    setSearchValue("");
  }

  const selectedLabel = workspaceLabels.find(
    ({ id }) => id === selectedLabelId,
  );

  return (
    <>
      {/* Heading */}
      <div className="mb-4 flex justify-between gap-x-4">
        <SearchInput
          className="w-full md:w-80"
          placeholder="Search labels"
          value={searchValue}
          onChange={({ target: { value } }) => setSearchValue(value)}
          onClear={clearSearch}
        />

        <NewLabel />
      </div>

      {/* List of labels */}
      {filteredLabel.length ? (
        <div className="grid grid-cols-1 gap-y-2.5 md:grid-cols-2 md:gap-x-4">
          {filteredLabel.map((label) => (
            <LabelListItem
              key={label.id}
              label={label}
              isEditing={selectedLabel && selectedLabel.id === label.id}
              onCancelEdit={() => setSelectedLabelId(null)}
              onStartEdit={() => {
                setSelectedLabelId(label.id);
                if (!isTabletOrDesktop) {
                  setIsDrawerOpen(true);
                }
              }}
            />
          ))}
        </div>
      ) : (
        <EmptyPlaceholder
          imageSrc={NoResultsImage}
          title="No labels found"
          subtitle={`No results found for "${searchValue}"`}
          actionComponent={<Button onClick={clearSearch}>Clear search</Button>}
        />
      )}

      <RenderIf condition={!!selectedLabel && !isTabletOrDesktop}>
        <LabelDrawer
          isOpen={isDrawerOpen}
          setIsOpen={setIsDrawerOpen}
          label={selectedLabel}
        />
      </RenderIf>
    </>
  );
}
