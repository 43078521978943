import React, { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { clsx } from "clsx";

import { Button } from "common/components/ui/Button";
import { Input } from "common/components/ui/Input";
import Label from "common/components/ui/Label";
import { WorkspaceNameSchema } from "common/schemas";
import useWorkspaceMutations from "common/datahooks/useWorkspaceMutations";
import useWorkspaceNavigate from "common/hooks/useWorkspaceNavigate";
import useWorkspaces from "common/datahooks/useWorkspaces";
import useGTM from "common/hooks/useGTM";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";

import CreateWorkspaceImage from "assets/images/create-workspace.png";

interface CreateWorkspaceProps {
  closeButton?: ReactNode;
}

export default function CreateWorkspace({
  closeButton = null,
}: CreateWorkspaceProps) {
  const isTabletOrDesktop = useTwBreakpoint("md");
  const { sendEvent } = useGTM();
  const { goToWorkspace } = useWorkspaceNavigate();
  const { createWorkspace, isCreatingWorkspace } = useWorkspaceMutations();
  const { workspaces } = useWorkspaces();

  const isFirstWorkspace = !workspaces.length;

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
    },
    resolver: valibotResolver(WorkspaceNameSchema),
  });

  function onSubmit({ name }: { name: string }) {
    if (workspaces.find((workspace) => workspace.name === name.trim())) {
      setError("name", { message: "Workspace with this name already exists" });
    } else {
      createWorkspace({ name }).then((workspace) => {
        sendEvent("workspace_created");
        goToWorkspace(workspace.id);
      });
    }
  }

  return (
    <>
      <img
        src={CreateWorkspaceImage}
        alt="Workspace Creation"
        className={clsx(
          closeButton ? "w-[343px] max-md:mb-6 md:w-[280px]" : "mb-6 w-[364px]",
          "mx-auto",
        )}
      />

      <h2
        className={clsx(
          closeButton
            ? "text-headline-2xl-bold md:text-headline-xl-bold"
            : "text-headline-2xl-bold",
          "mb-2 text-center",
        )}
      >
        {isFirstWorkspace
          ? "Create your first workspace"
          : "Create a new workspace"}
      </h2>

      <p
        className={clsx(
          closeButton ? "mb-6 md:mb-8" : "mb-12",
          "text-center text-black-500",
        )}
      >
        This will be the main place to manage your Aimfox campaigns and
        collaborate with your team
      </p>

      <Label htmlFor="workspaceName" className="mb-1 w-full text-left">
        Workspace name
      </Label>

      <Input
        variant="lg"
        id="workspaceName"
        placeholder="Name your workspace"
        className={clsx(closeButton ? "mb-10 md:mb-8" : "mb-6", "w-full")}
        error={errors.name?.message}
        {...register("name")}
      />

      <div className="flex w-full flex-col-reverse gap-2 md:flex-row md:gap-4">
        {closeButton}

        <Button
          size={isTabletOrDesktop ? "md" : "lg"}
          className="flex-1"
          onClick={handleSubmit(onSubmit)}
          isLoading={isCreatingWorkspace}
        >
          Create
        </Button>
      </div>
    </>
  );
}
