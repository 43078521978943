import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";

import { Button } from "common/components/ui/Button";
import Label from "common/components/ui/Label";
import { Input } from "common/components/ui/Input";

import { ApiToken } from "../../types";
import { formatBillingDate } from "../../utils";
import useApiTokens from "../../datahooks/useApiTokens";
import { ApiKeyNameSchema } from "../../schema";

interface TokenDetailsProps {
  selectedToken: ApiToken;
  onDelete: () => void;
  onClose: () => void;
}

function Separator() {
  return <div className="h-px w-full bg-black-200" />;
}

export default function TokenDetails({
  selectedToken,
  onDelete,
  onClose,
}: TokenDetailsProps) {
  const {
    name,
    id,
    token: tokenId,
    created_at: createdAt,
    write: canWrite,
    read: canRead,
  } = selectedToken;

  const { updateApiToken, isUpdatingToken } = useApiTokens({
    disableQuery: true,
  });

  const {
    register,
    reset: resetForm,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    name: string;
  }>({
    defaultValues: { name },
    resolver: valibotResolver(ApiKeyNameSchema),
  });

  useEffect(() => {
    resetForm({ name });
  }, [selectedToken, resetForm]);

  const permissions = canWrite && canRead ? "All" : "Read only";

  function onSubmit(data: { name: string }): void {
    updateApiToken({
      tokenId: id,
      updates: { name: data.name },
    }).then(() => {
      onClose();
    });
  }

  return (
    <>
      <h2 className="mb-8 line-clamp-1 break-words text-headline-2xl-bold">
        {name}
      </h2>

      <form className="flex flex-col gap-y-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-y-2">
          <Label htmlFor="key-name">Key name</Label>

          <Input
            {...register("name")}
            error={errors.name?.message}
            placeholder="Key name"
            id="key-name"
          />
        </div>

        <Separator />

        <div className="grid grid-cols-2">
          <span className="text-black-400">Permissions</span>

          <span className="text-body-14-bold capitalize">{permissions}</span>
        </div>

        <Separator />

        <div className="grid grid-cols-2">
          <span className="text-black-400">Key</span>

          <span className="text-body-14-bold">
            {`${tokenId.slice(0, 13)}...${tokenId.slice(-2)}`}
          </span>
        </div>

        <Separator />

        <div className="grid grid-cols-2">
          <span className="text-black-400">Created at</span>

          <span className="text-body-14-bold">
            {formatBillingDate(createdAt)}
          </span>
        </div>

        <div className="mt-4 flex flex-col gap-2">
          <Button isLoading={isUpdatingToken} size="lg" type="submit">
            Save
          </Button>

          <Button
            variant="tertiary-danger"
            size="lg"
            disabled={isUpdatingToken}
            type="button"
            onClick={onDelete}
          >
            Delete
          </Button>
        </div>
      </form>
    </>
  );
}
