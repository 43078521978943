import React, { ReactNode, useState } from "react";
import { Info } from "@phosphor-icons/react";

import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import { formatPrice, isLifetimeDeal } from "common/helpers/utils";
import { Tag } from "common/components/ui/Tag";
import RestrictedComponent from "common/components/RestrictedComponent";
import RenderIf from "common/components/RenderIf";
import { Button } from "common/components/ui/Button";
import useEstimateRenewal from "common/datahooks/useEstimateRenewal";
import Skeleton from "common/components/ui/Skeleton";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";
import Licenses from "common/components/Licenses";

import RemoveChangeDialog from "./RemoveChangeDialog";
import PromotionsTag from "./PromotionsTag";
import { formatBillingDate } from "../../../utils";
import PlanDetails from "./PlanDetails";

interface InfoBlockProps {
  title: string;
  value: ReactNode;
  subText?: string;
}

function InfoBlock({ title, value, subText = null }: InfoBlockProps) {
  return (
    <div className="flex w-full flex-1 flex-row items-center justify-between gap-5 border-b border-black-200 px-2 py-4 max-xl:last:border-b-0 xl:w-52 xl:flex-col xl:items-start xl:justify-start xl:border-b-0 xl:border-r xl:px-8 xl:pt-8 xl:last:border-r-0">
      <span className="text-body-14-bold text-black-500 max-xl:flex-1">
        {title}
      </span>
      <div className="flex flex-col items-end max-xl:flex-1 lg:items-start">
        <span className="text-headline-xl-bold">{value}</span>

        {subText && (
          <p className="text-caption-12-regular text-black-500">{subText}</p>
        )}
      </div>
    </div>
  );
}

export default function WorkspacePlan() {
  const [isOpen, setIsOpen] = useState(false);
  const {
    subscription,
    licenses,
    license_count: licenseCount,
  } = useSelectedWorkspaceContext();

  const seatsUsed = licenses.filter((license) => license.account_id).length;
  const { estimateRenewal, isLoadingEstimateRenewal } = useEstimateRenewal();
  const { amountDue, nextBillingAt } = estimateRenewal || {};

  const hasNonRenewingLicense = licenses.some((license) => license.expires_at);
  const isSubscriptionCancelled = subscription?.status === "cancelled";
  const isSubscriptionNonRenewing = subscription?.status === "non_renewing";

  const isOutreachPlan =
    isLifetimeDeal(licenses) || (subscription && !isSubscriptionCancelled);

  let priceSubtext = null;
  if (subscription && !isSubscriptionCancelled && !isSubscriptionNonRenewing) {
    const isYearlySubscription = subscription.item_price.period_unit === "year";

    priceSubtext = isYearlySubscription ? "Yearly" : "Monthly";
  }

  let priceValue;
  if (isLoadingEstimateRenewal) {
    priceValue = <Skeleton className="h-7 w-28" />;
  } else if (
    amountDue === 0 ||
    !subscription ||
    isSubscriptionCancelled ||
    isSubscriptionNonRenewing
  ) {
    priceValue = "Free";
  } else {
    priceValue = formatPrice(amountDue / 100);
  }

  let buttonComponent;
  if (!subscription && !isLifetimeDeal(licenses)) {
    buttonComponent = (
      <Button variant="gradient" onClick={() => setIsOpen(true)}>
        Start free trial
      </Button>
    );
  } else if (isSubscriptionCancelled) {
    buttonComponent = (
      <Button variant="gradient" onClick={() => setIsOpen(true)}>
        Reactivate subscription
      </Button>
    );
  } else if (hasNonRenewingLicense || isSubscriptionNonRenewing) {
    buttonComponent = <RemoveChangeDialog />;
  } else {
    buttonComponent = (
      <Button onClick={() => setIsOpen(true)}>Manage subscription</Button>
    );
  }

  let billingDateComponent;
  if (isSubscriptionNonRenewing) {
    billingDateComponent = (
      <div className="flex flex-col items-start gap-y-1.5">
        <span className="text-headline-xl-bold">-</span>

        <Tag
          size="lg"
          variant="yellow"
          rightIcon={
            <Tooltip>
              <TooltipTrigger>
                <Info weight="fill" className="fill-yellow-500" />
              </TooltipTrigger>

              <TooltipContent className="text-wrap">
                This subscription is scheduled for cancellation
              </TooltipContent>
            </Tooltip>
          }
        >
          Scheduled change
        </Tag>
      </div>
    );
  } else if (subscription && !isSubscriptionCancelled) {
    const activeLicenseCount = licenses.filter(
      ({ status }) => status === "active",
    ).length;

    billingDateComponent = (
      <div className="flex flex-col items-start gap-y-1.5">
        <span className="text-headline-xl-bold">
          {isLoadingEstimateRenewal ? (
            <Skeleton className="h-7 w-28" />
          ) : (
            formatBillingDate(nextBillingAt * 1000)
          )}
        </span>

        <RenderIf condition={hasNonRenewingLicense}>
          <Tag
            size="lg"
            variant="yellow"
            rightIcon={
              <Tooltip>
                <TooltipTrigger>
                  <Info size={18} weight="fill" className="fill-yellow-500" />
                </TooltipTrigger>

                <TooltipContent>
                  <p className="mb-4 text-wrap">
                    This subscription has a scheduled change which will be
                    effective starting from your next billing date
                  </p>

                  <div className="flex flex-col">
                    <span>Current seats: {licenseCount}</span>
                    <span>New seats: {activeLicenseCount}</span>
                  </div>
                </TooltipContent>
              </Tooltip>
            }
          >
            Scheduled change
          </Tag>
        </RenderIf>

        <RenderIf condition={!subscription}>
          <Tag size="lg" className="mt-1.5 w-fit" variant="gradient">
            14 day free trial
          </Tag>
        </RenderIf>
      </div>
    );
  } else {
    billingDateComponent = <span className="text-headline-xl-bold">-</span>;
  }

  return (
    <>
      <div className="flex w-full flex-col items-stretch rounded-3xl border border-black-200 bg-whiteGray p-3 xl:flex-row">
        <PlanDetails
          isOutreachPlan={isOutreachPlan}
          isSubscriptionNonRenewing={isSubscriptionNonRenewing}
        />

        <div className="my-4 flex flex-col xl:flex-1 xl:flex-row">
          <InfoBlock
            title="Seats"
            value={
              <div className="flex items-center gap-x-2">
                {licenseCount}&nbsp;
                <PromotionsTag />
              </div>
            }
            subText={`${seatsUsed} used seats`}
          />
          <InfoBlock title="Price" value={priceValue} subText={priceSubtext} />
          <InfoBlock title="Next billing date" value={billingDateComponent} />
        </div>
        <RestrictedComponent disabledForRoles={[]} hiddenForRoles={["member"]}>
          <div className="ml-auto mt-4 flex w-fit flex-col justify-end lg:pb-4 lg:pr-3">
            {buttonComponent}
          </div>
        </RestrictedComponent>
      </div>

      <Licenses isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}
