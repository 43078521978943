import React, { useEffect, useState } from "react";
import clsx from "clsx";

import RenderIf from "common/components/RenderIf";
import SearchInput from "common/components/SearchInput";

import { Conversation } from "../types";
import ConversationContainer from "./ConversationContainer/ConversationContainer";
import ConversationsList from "./ConversationsList/ConversationsList";
import FilterConversations from "./FilterConversations";
import ConversationFilters from "./ConversationFilters";

interface MobileInboxProps {
  activeConversation: Conversation;
  setActiveConversationUrn: (
    conversationUrn: string,
    accountId: string,
  ) => void;
  conversations: Conversation[];
  loadMoreConversations: () => void;
  keywordsInputValue: string;
  setKeywordsInputValue: (keywords: string) => void;
  clearKeywords: () => void;
  isLoadingConversations: boolean;
  hasMoreConversations: boolean;
  selectedAccountIds: string[];
  setSelectedAccountIds: (accountId: string[]) => void;
  selectedCampaignIds: string[];
  setSelectedCampaignIds: (campaignId: string[]) => void;
  selectedLabelIds: string[];
  setSelectedLabelIds: (labelId: string[]) => void;
  filteredBy: "all" | "favorite" | "unread";
  setFilteredBy: (filter: "all" | "favorite" | "unread") => void;
}

export default function MobileInbox({
  activeConversation,
  setActiveConversationUrn,
  conversations,
  loadMoreConversations,
  keywordsInputValue,
  setKeywordsInputValue,
  clearKeywords,
  isLoadingConversations,
  hasMoreConversations,
  selectedAccountIds,
  setSelectedAccountIds,
  selectedCampaignIds,
  setSelectedCampaignIds,
  selectedLabelIds,
  setSelectedLabelIds,
  filteredBy,
  setFilteredBy,
}: MobileInboxProps) {
  const [isConversationOpen, setIsConversationOpen] = useState(false);

  useEffect(() => {
    if (activeConversation) {
      setIsConversationOpen(!!activeConversation);
    }
  }, [activeConversation]);

  useEffect(() => {
    if (isConversationOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    // Cleanup function to reset overflow
    return () => {
      document.body.style.overflow = "";
    };
  }, [isConversationOpen]);

  function resetActiveConversation(): void {
    setActiveConversationUrn(null, null);
    setIsConversationOpen(false);
  }

  const clearAllFilters = () => {
    setSelectedAccountIds([]);
    setSelectedCampaignIds([]);
    setSelectedLabelIds([]);
  };

  return (
    <div className="mt-2 md:mb-0">
      {/* Selected conversation details */}
      <RenderIf condition={activeConversation && isConversationOpen}>
        <div
          className={clsx([
            "fixed left-0 top-20 z-10 flex w-full flex-col overflow-hidden",
            isLoadingConversations
              ? "border border-dashed border-black-300 bg-white"
              : "bg-black-50",
          ])}
        >
          <ConversationContainer
            activeConversation={activeConversation}
            isLoadingConversations={isLoadingConversations}
            resetActiveConversation={resetActiveConversation}
          />
        </div>
      </RenderIf>

      {/* Input search for filtering conversations list */}
      <div className="mb-4 flex flex-row items-center gap-2">
        <SearchInput
          placeholder="Search"
          className="w-full"
          variant="lg"
          onClear={() => setKeywordsInputValue("")}
          value={keywordsInputValue}
          onChange={(e) => setKeywordsInputValue(e.target.value)}
        />

        <FilterConversations
          selectedAccountIds={selectedAccountIds}
          selectedCampaignIds={selectedCampaignIds}
          selectedLabelIds={selectedLabelIds}
          onApplyFilters={({ accountIds, campaignIds, labelIds }) => {
            setSelectedAccountIds(accountIds);
            setSelectedCampaignIds(campaignIds);
            setSelectedLabelIds(labelIds);
          }}
        />
      </div>

      <ConversationFilters
        filteredBy={filteredBy}
        setFilteredBy={setFilteredBy}
      />

      <div className="mt-2 flex h-[calc(100vh-15.625rem)] flex-col overflow-y-auto scrollbar-thin [mask-image:_linear-gradient(to_bottom,_black_128px,_black_calc(100%-100px),transparent_100%)]">
        <ConversationsList
          isLoadingConversations={isLoadingConversations}
          conversations={conversations}
          activeConversation={activeConversation}
          hasMoreConversations={hasMoreConversations}
          keywords={keywordsInputValue}
          setActiveConversationUrn={(urn, accountId) => {
            setActiveConversationUrn(urn, accountId);
            setIsConversationOpen(true);
          }}
          loadMoreConversations={loadMoreConversations}
          clearKeywords={clearKeywords}
          selectedFilters={selectedAccountIds}
          clearFilters={clearAllFilters}
        />
      </div>
    </div>
  );
}
