import React from "react";
import { Tag as TagIcon } from "@phosphor-icons/react";

import { Tag } from "common/components/ui/Tag";
import { templateVariables } from "common/constants";

interface VariablesProps {
  addLabel: (text: string) => void;
  isDisabled: boolean;
}

export default function Variables({ addLabel, isDisabled }: VariablesProps) {
  return (
    <div className="flex w-full items-start gap-1 pt-3 max-lg:border-t max-lg:border-t-black-200">
      <TagIcon weight="fill" size={16} className="mt-1" />

      <div className="flex flex-wrap gap-1">
        {templateVariables.map(({ label, string, key }) => (
          <Tag key={key} variant="tertiary" data-cy={`variable-${key}`} asChild>
            <button
              type="button"
              className="transition-colors hover:bg-black-50 disabled:cursor-not-allowed"
              onClick={() => addLabel(string)}
              disabled={isDisabled}
            >
              {label}
            </button>
          </Tag>
        ))}
      </div>
    </div>
  );
}
