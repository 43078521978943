import { useMutation } from "@tanstack/react-query";

import { post, del } from "common/helpers/HTTP";
import { useSelectedWorkspaceContext } from "common/helpers/SelectedWorkspaceContext";
import useUpdateLead from "common/datahooks/useUpdateLead";

async function addFavoriteRequest(workspaceId: string, leadId: string) {
  await post(`workspaces/${workspaceId}/leads/${leadId}/favorite`);
}

async function removeFavoriteRequest(workspaceId: string, leadId: string) {
  await del(`workspaces/${workspaceId}/leads/${leadId}/favorite`);
}

export default function useFavorite() {
  const { id: workspaceId } = useSelectedWorkspaceContext();
  const updateLead = useUpdateLead();

  const { mutateAsync: addFavorite } = useMutation(
    {
      mutationFn: ({ leadId }: { leadId: string }) =>
        addFavoriteRequest(workspaceId, leadId),
      onMutate: async ({ leadId }) => {
        await updateLead(leadId, (draftLead) => {
          draftLead.favorite = true;
        });
      },
      onError: async (_, { leadId }) => {
        await updateLead(leadId, (draftLead) => {
          draftLead.favorite = false;
        });
      },
    },
  );

  const { mutateAsync: removeFavorite } =
    useMutation({
      mutationFn: ({ leadId }: { leadId: string }) =>
        removeFavoriteRequest(workspaceId, leadId),
      onMutate: async ({ leadId }) => {
        await updateLead(leadId, (draftLead) => {
          draftLead.favorite = false;
        });
      },
      onError: async (_, { leadId }) => {
        await updateLead(leadId, (draftLead) => {
          draftLead.favorite = true;
        });
      },
    });

  return {
    addFavorite,
    removeFavorite,
  };
}
