import React from "react";
import { useForm } from "react-hook-form";
import { valibotResolver } from "@hookform/resolvers/valibot";

import useCustomer from "common/datahooks/useCustomer";
import { FullNameSchema } from "common/schemas";
import { Input } from "common/components/ui/Input";
import Label from "common/components/ui/Label";

import EditContainer from "../EditContainer";

export default function UpdateNameEdit({
  isEditing,
  onClose,
}: {
  isEditing: boolean;
  onClose: () => void;
}) {
  const { customerInfo, updateCustomer, isCustomerUpdating } = useCustomer();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    firstName: string;
    lastName: string;
  }>({
    resolver: valibotResolver(FullNameSchema),
    defaultValues: {
      firstName: customerInfo.first_name,
      lastName: customerInfo.last_name,
    },
  });

  async function onSubmit({
    firstName,
    lastName,
  }: {
    firstName: string;
    lastName: string;
  }) {
    if (
      firstName !== customerInfo.first_name ||
      lastName !== customerInfo.last_name
    ) {
      await updateCustomer({ firstName, lastName });
    }
    onClose();
  }

  return (
    <EditContainer
      isEditing={isEditing}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isCustomerUpdating}
      onClose={onClose}
    >
      <div className="flex w-full flex-col gap-4">
        <div className="flex flex-col gap-1">
          <Label htmlFor="firstName" className="text-caption-12-bold" size="md">
            First name
          </Label>

          <Input
            placeholder="First name"
            variant="md"
            id="firstName"
            error={errors.firstName?.message}
            {...register("firstName")}
          />
        </div>

        <div className="flex flex-col gap-1">
          <Label htmlFor="lastName" className="text-caption-12-bold" size="md">
            Last name
          </Label>

          <Input
            placeholder="Last name"
            variant="md"
            id="lastName"
            error={errors.lastName?.message}
            {...register("lastName")}
          />
        </div>
      </div>
    </EditContainer>
  );
}
