import React, { useEffect } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useTranslation } from "react-i18next";
import { Info } from "@phosphor-icons/react";
import clsx from "clsx";

import Switch from "common/components/ui/Switch";
import RenderIf from "common/components/RenderIf";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "common/components/ui/Tooltip";
import { Tag } from "common/components/ui/Tag";
import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import Skeleton from "common/components/ui/Skeleton";
import { useCampaignContext } from "common/helpers/CampaignContext";

import useValidateFlow from "../../hooks/useValidateFlow";
import CampaignFlow from "../CampaignFlow";
import { CampaignFlow as CampaignFlowType } from "../../types";
import FlowTypeLabel from "../FlowTypeLabel";
import useCampaignMutations from "../../datahooks/useCampaignMutations";

interface InMailOptimizationProps {
  flow: CampaignFlowType;
  canToggle: boolean;
  isEnabled: boolean;
  targetCount: number;
  isLoadingAudienceStats: boolean;
}

export default function InMailOptimization({
  flow,
  canToggle,
  isEnabled,
  targetCount,
  isLoadingAudienceStats,
}: InMailOptimizationProps) {
  const { campaignId } = useCampaignContext();
  const { updateCampaign } = useCampaignMutations();

  const isDesktop = useTwBreakpoint("lg");
  const [parent] = useAutoAnimate({ duration: 400 });
  const { isFlowError, resetFlowError } = useValidateFlow(flow.id);

  function updateOptimization(newValue: boolean) {
    updateCampaign({
      campaignId,
      updates: {
        inmail_optimization: newValue,
      },
    });
  }

  useEffect(() => {
    if (!isLoadingAudienceStats && isEnabled && targetCount === 0) {
      updateOptimization(false);
    }
  }, [isLoadingAudienceStats, targetCount]);

  const { t } = useTranslation("campaigns", {
    keyPrefix: "flowTypes",
  });

  const isDisabled = !targetCount;

  return (
    <article
      className={clsx(
        "flex flex-col gap-4 rounded-2xl border px-3 py-4 lg:px-4",
        isFlowError ? "border-red-500" : "border-black-200",
      )}
      ref={parent}
    >
      {/* Heading */}
      <section className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <FlowTypeLabel type={flow.type} isDisabled={isDisabled} />

          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-1">
              <h3
                className={clsx(
                  "text-headline-lg-bold",
                  isDisabled && "text-black-400",
                )}
              >
                {t(`${flow.type}_Title`)}
              </h3>
              {isLoadingAudienceStats ? (
                <Skeleton className="h-6 w-10" />
              ) : (
                <Tag variant={isDisabled ? "disabled" : "secondary"}>
                  {targetCount}
                </Tag>
              )}

              <RenderIf condition={isDesktop}>
                <Tooltip>
                  <TooltipTrigger>
                    <Info size={20} weight="fill" className="fill-black-300" />
                  </TooltipTrigger>
                  <TooltipContent>
                    {isDisabled
                      ? "InMails are disabled because there are no open profiles in this campaign"
                      : t(`${flow.type}_InfoText`)}
                  </TooltipContent>
                </Tooltip>
              </RenderIf>
            </div>

            <p
              className={clsx(
                "max-lg:hidden",
                isDisabled ? "text-black-400" : "text-black-500",
              )}
            >
              {t(`${flow.type}_Description`)}
            </p>
          </div>
        </div>

        <RenderIf condition={canToggle}>
          <Switch
            disabled={isDisabled}
            color="purple"
            checked={isEnabled}
            onCheckedChange={(checked) => {
              updateOptimization(checked);
              resetFlowError();
            }}
          />
        </RenderIf>
      </section>

      {/* Flow */}
      <RenderIf condition={isEnabled}>
        <>
          <div className="-mx-3 h-px bg-black-200 px-3 lg:-mx-4 lg:px-4" />
          <CampaignFlow
            flow={flow}
            isFlowError={isFlowError}
            resetFlowError={resetFlowError}
          />
        </>
      </RenderIf>
    </article>
  );
}
