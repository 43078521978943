import React from "react";

import useTwBreakpoint from "common/hooks/useTwBreakpoint";
import Skeleton from "common/components/ui/Skeleton";

export default function CampaignInfoLoader() {
  const isTabletOrDesktop = useTwBreakpoint("md");

  const stateTag = <Skeleton className="h-6 w-16" />;
  const accountTag = <Skeleton className="h-6 w-40 rounded-full" />;
  const actionsDropdown = <Skeleton className="size-10 rounded-full" />;
  const campaignName = <Skeleton className="h-[22px] w-40" />;
  const campaignTypeIcon = <Skeleton className="size-12 rounded-2xl" />;
  const typeAndSubtype = (
    <div className="flex items-center gap-x-1 text-black-500">
      <Skeleton className="size-4" />
      <Skeleton className="h-4 w-24" />
    </div>
  );

  let mainContent;
  if (isTabletOrDesktop) {
    mainContent = (
      <>
        {campaignTypeIcon}
        <div className="flex flex-col gap-y-1 md:mx-3">
          {typeAndSubtype}
          {campaignName}
        </div>
        <div className="flex flex-1 items-center justify-end gap-x-4">
          <div className="flex items-center gap-x-2">
            <span className="text-caption-12-regular text-black-500">
              Run by:
            </span>
            {accountTag}
          </div>
          {stateTag}
          <span className="h-10 w-px bg-black-200" />
          {actionsDropdown}
        </div>
      </>
    );
  } else {
    mainContent = (
      <>
        <div className="flex w-full items-center gap-x-3">
          {campaignTypeIcon}

          <div className="flex grow flex-col">
            <div className="mb-0.5 flex items-center gap-x-2">
              {typeAndSubtype}
              {stateTag}
            </div>
            {campaignName}
          </div>

          <span className="h-10 w-px bg-black-200" />
          {actionsDropdown}
        </div>

        <span className="my-2 h-px w-full bg-black-200" />
        {accountTag}
      </>
    );
  }

  return (
    <section className="mb-4 flex flex-col rounded-2xl border border-black-200 px-3 py-4 md:flex-row md:items-center md:px-4">
      {mainContent}
    </section>
  );
}
