import React, { useState } from "react";
import { CaretRight } from "@phosphor-icons/react";
import clsx from "clsx";

import { Member } from "common/types";
import { Button } from "common/components/ui/Button";
import { Tag } from "common/components/ui/Tag";
import { Drawer, DrawerContent } from "common/components/ui/Drawer";
import Avatar from "common/components/ui/Avatar";
import { userRoles } from "common/constants";
import useCustomer from "common/datahooks/useCustomer";

import MobileDrawerContent from "./MobileDrawerContent";

export default function MobileList({ members }: { members: Member[] }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState<Member>(null);

  const handleRowClick = (member: Member) => {
    setSelectedMember(member);
    setIsDrawerOpen(true);
  };

  const { customerInfo } = useCustomer();
  const { email: customerEmail } = customerInfo;

  const matchingMember = members.find(
    (member) => member.email === customerEmail,
  );
  const customerRole = matchingMember
    ? userRoles[matchingMember.role]?.label
    : null;

  const hasMemberActions =
    (customerRole !== "Member" || customerEmail === selectedMember?.email) &&
    selectedMember?.role !== "owner";

  return (
    <>
      <div className="mt-4 flex justify-between rounded-xl bg-black-100 px-4 py-2">
        <span className="text-caption-12-regular">Account</span>
        <span className="pr-20 text-caption-12-regular">Role</span>
      </div>

      <div className="mt-2 flex flex-col gap-2">
        {members.map((member) => {
          const {
            token,
            first_name: firstName,
            last_name: lastName,
            role,
            customer_id: customerId,
            email,
          } = member;

          const roleData = userRoles[role];
          const { label: roleLabel, tagVariant: roleTagVariant } = roleData;

          const getFullName = () =>
            customerId ? `${firstName} ${lastName}` : "New Member";

          return (
            <button
              key={token}
              type="button"
              className="flex items-center justify-between gap-4 rounded-2xl border border-black-200 p-3"
              onClick={() => handleRowClick(member)}
            >
              <div className="flex flex-1 flex-row items-center gap-2">
                <Avatar variant={customerId ? "primary" : "quaternary"}>
                  {customerId ? `${firstName[0]}${lastName[0]}` : "N/A"}
                </Avatar>

                <div className="flex flex-col justify-center gap-0.5">
                  <span
                    className={clsx(
                      "line-clamp-1 break-all text-start text-body-14-bold",
                      !customerId && "text-black-300",
                    )}
                  >
                    {getFullName()}
                  </span>

                  <span className="line-clamp-1 break-all text-start text-caption-12-regular text-black-500">
                    {email}
                  </span>
                </div>
              </div>

              <Tag variant={roleTagVariant} className="capitalize">
                {roleLabel}
              </Tag>

              <Button intent="labelIcon" asChild variant="quaternary-black">
                <CaretRight />
              </Button>
            </button>
          );
        })}
      </div>

      <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen}>
        <DrawerContent>
          <MobileDrawerContent
            member={selectedMember}
            hasMemberActions={hasMemberActions}
          />
        </DrawerContent>
      </Drawer>
    </>
  );
}
